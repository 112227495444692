import {AITextService, TEXT_AI_OPTION_LIST} from '../../../../../services/ai-text.service';
import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  computed,
  ElementRef,
  Injector,
  OnInit,
  ViewChild
} from '@angular/core';
import {toSignal} from '@angular/core/rxjs-interop';
import {AbstractContainerComponent} from '../../../shared/abstract-container-component';
import {MatDialog} from '@angular/material/dialog';
import {Constants, ILanguageParams} from '../../../../../core/constants';
import {MatTabGroup} from '@angular/material/tabs';
import {BehaviorSubject, combineLatest, filter, firstValueFrom, map, pairwise, startWith, Subject, Subscription, take} from 'rxjs';
import {
  AnswerQuestion,
  AnswerQuestionMatching,
  EventQuestion,
  TableRowAnswerQuestion,
  TableRowAnswerQuestionMatching
} from '../../../../../model/EventQuestion';
import {EventsDataService} from '../../../../../services/events-data.service';
import {QuizQuestionSheetEditorDialogComponent} from '../quiz-question-sheet-editor-dialog/quiz-question-sheet-editor-dialog.component';
import {CommonService} from '../../../../../core/common.service';
import {
  acceptAnswersChange,
  afterViewChecked,
  afterViewInit,
  checkAnswersFeedbackChange,
  checkUserShowResultParam,
  finalizeDirectFeedback,
  isQuizUsedFeedback,
  resetSingleQuestionQuizFeedbackStatusIcon,
  setCheckResult,
  setQuestionResults,
  showCorrectAnswersChange,
  showDirectFeedback,
  showResultChange,
  updateQuestionKeyList,
  updateQuestionsFeedback
} from './quiz-question-sheet-lib';
import {cloneDeep, isEmpty, max, merge} from 'lodash';
import {
  IQuestionDirectFeedback,
  IQuestionDirectFeedbackResult,
  IQuizContentAnswersExtended,
  IQuizQuestionMap,
  IQuizQuestionsSummary,
  Quiz,
  QUIZ_EDITOR_ACTIONS,
  TOOLBAR_BEHAVIOR
} from '../quiz-model/quiz';
import {TranslateApiService} from '../../../../../services/translate-api.service';
import {
  QuizQuestionSheetParticipantsDetailComponent
} from '../quiz-question-sheet-participants-detail/quiz-question-sheet-participants-detail.component';
import {QuizFinalizeServiceService} from '../quiz-finalize-service.service';
import {questionUseCheckingCorrectnessOfAnswer} from '../quiz-components/shared/lib/quiz-question-common-lib';
import {QuizTemplatesListDialogComponent} from '../quiz-templates-list-dialog/quiz-templates-list-dialog.component';
import {
  IDisabledAiAction,
  IExtendedPoolAction,
  IQuizTemplate,
  QUESTION_TYPES_COMPONENTS
} from '../quiz-components/shared/quiz-quiestion-types';
import {QuizService} from '../quiz.service';
import {getCurrentSubjectId} from '../quiz-components/shared/lib/quiz-question-ai-lib';
import {SLIDE_ACTION} from '../../../shared/container-interface';

interface ISubscription {
  [id: string]: Subscription;
}

@Component({
  selector: 'app-quiz-question-sheet',
  templateUrl: './quiz-question-sheet.component.html',
  styleUrls: ['./quiz-question-sheet.component.scss']
})
export class QuizQuestionSheetComponent extends AbstractContainerComponent implements OnInit, AfterViewInit, AfterViewChecked {

  readonly Constants = Constants;
  readonly QUESTION_TYPES_COMPONENTS = QUESTION_TYPES_COMPONENTS;
  readonly TOOLBAR_BEHAVIOR = TOOLBAR_BEHAVIOR;

  @ViewChild('tabGroup') tgroup: MatTabGroup;
  @ViewChild('buttonCustom') buttonCustom;
  @ViewChild('questionHeader') questionHeader;

  qContent: Quiz;
  currentQIndex = 0;
  currentQKey$ = new BehaviorSubject<string>(null);
  answers$ = new BehaviorSubject<IQuizContentAnswersExtended>(null);
  answers = toSignal(this.answers$);
  hasCurrentUserAnyAnswers = computed(() => !this.isPresenter &&
    this.questionList$.getValue()?.some(q => !isEmpty(this.answers()?.answers?.[q.id]?.[this.currentUserId])));
  answersSummary$ = new BehaviorSubject<IQuizQuestionsSummary>(null);
  answersSummary = toSignal(this.answersSummary$);
  // list of all questions
  questionList: IQuizQuestionMap = {};
  quizUserListLength = 0;
  questionsDirectFeedback: IQuestionDirectFeedback = {};
  directFeedbackAnswersChanged = false;
  // list of available(apply questions dependency) questions
  questionList$ = new BehaviorSubject<EventQuestion[]>([]);
  localQuestionCount  = 0;
  questionReceived = false;
  singleMode = false;
  headerOffset = 45;
  isInSelfLearningSection = false;
  paginatorBefore;
  paginatorAfter;
  tabHeader;
  isFullscreen = false;
  isPresenter = false;
  presenter$ = new BehaviorSubject<boolean>(null);
  answersLoaded = false;
  loadAnswers$ = new Subject();
  showDetails = false;
  subscriptions: ISubscription = {};
  languageParams$ = new BehaviorSubject<ILanguageParams>(null);
  userShowResult: boolean;
  hasAnswersFeedback: boolean;
  tryAgain: boolean;
  disableCheckAnswersOnSubmitting: boolean;
  // called by the user when he starts answering again and resets all answers.
  // used if enabled question options tryAgain and directFeedback
  // subject param is questionId.
  resetQuestionAnswers$ = new Subject<string>();
  currentQuestionUseCorrectAnswers$ = new BehaviorSubject<boolean>(false);
  currentQKeySignal = toSignal(this.currentQKey$);
  tabsToShow = computed(() => {
    const questions = this.questionList$.getValue();
    const questionIdx = questions.findIndex(q => q.id === this.currentQKeySignal());
    let idxToShow = [];


    if ([0, 1, 2].includes(questionIdx) || questions.length <= 5) {
      idxToShow = [0, 1, 2, 3, 4];
    } else if (questionIdx + 3 >= questions.length) {
      idxToShow = [questions.length - 5, questions.length - 4, questions.length - 3, questions.length - 2, questions.length - 1];
    } else {
      idxToShow = [questionIdx - 2, questionIdx - 1, questionIdx, questionIdx + 1, questionIdx + 2];
    }

    return {
      show: questions.map((q, idx) => idxToShow.includes(idx)),
      first: idxToShow[0],
      last: idxToShow[idxToShow.length - 1]
    };
  });
  responses$ = new BehaviorSubject<any[]>([]);

  disabledAiActions = computed<IDisabledAiAction>(() => {
    const usedAi = !!QUESTION_TYPES_COMPONENTS[this.questionList[this.currentQKeySignal()]?.storypoint]?.aiAction;
    const checkMethod = QUESTION_TYPES_COMPONENTS[this.questionList[this.currentQKeySignal()]?.storypoint]?.checkDisabledAiActions;
    if (usedAi && checkMethod) {
      return checkMethod(this.answersSummary()?.questions?.[this.currentQKeySignal()]);
    }
    return null;
  });

  private elementObserver = new MutationObserver(() => {
    const element: Element = this.elementRef.nativeElement;
    const toolbar = element.children.namedItem('toolbar');
    if (toolbar) {
      this.tgroup._elementRef.nativeElement.firstElementChild.appendChild(toolbar);
      this.elementObserver.disconnect();
    }
  });

  quizPresenterWrapperStyle = computed(() => {
    return this.containerItemOptions()?.backgroundColor && this.isPresenter ?
      `border-color: ${this.containerItemOptions()?.backgroundColor}` : '';
  });

  constructor(protected injector: Injector,
              public dataService: EventsDataService,
              public common: CommonService,
              public cdr: ChangeDetectorRef,
              private dialog: MatDialog,
              public elementRef: ElementRef,
              public translateApiService: TranslateApiService,
              public quizService: QuizService,
              public quizFinalizeServiceService: QuizFinalizeServiceService,
              public aiTextService: AITextService) {
    super(injector);
    this.elementObserver.observe(this.elementRef.nativeElement, {childList: true});
  }

  ngOnInit(): void {
    combineLatest([
      this.loadAnswers$.pipe(filter(value => value && !this.answersLoaded)),
      this.presenter$.pipe(startWith(null), pairwise())
    ])
      .pipe(this.takeUntilAlive())
      .subscribe(([loaded, [prevIsPresenter, nextIsPresenter]]) => {
        this.answersLoaded = true;
        if (prevIsPresenter !== nextIsPresenter) {
          this.prepareQuizQuestionsAnswers(nextIsPresenter);
        }
      });
    combineLatest([this.isEventManager$, this.managerUseParticipantMode$.pipe(startWith(null), pairwise())])
      .pipe(this.takeUntilAlive())
      .subscribe(([isPresenter, [useParticipantModePrev, useParticipantMode]]) => {
        if (!this.editorMode) {
          this.presenter$.next(isPresenter && !useParticipantMode);
          if (isPresenter && useParticipantModePrev !== useParticipantMode) {
            this.outputEmmitSwitchBackgroundColor(!useParticipantMode);
          }
        } else {
          this.isPresenter = isPresenter;
        }
      });
    combineLatest([this.defaultLanguage$, this.currentLanguage$, this.usedMultilingualContent$])
      .pipe(this.takeUntilAlive())
      .subscribe(([defaultLanguage, currentLanguage, usedMultilingualContent]) => {
        this.languageParams$.next({
          defaultLanguage: defaultLanguage,
          currentLanguage: currentLanguage,
          usedMultilingualContent: usedMultilingualContent.multilingual,
          usedLanguages: usedMultilingualContent.usedLanguages
        });
        this.emmitCurrentLanguageTranslatedState();
      });
    this.data$.pipe(this.takeUntilAlive())
      .pipe(this.takeUntilAlive())
      .subscribe(value => {
        checkUserShowResultParam(value, this);
        this.qContent = new Quiz(cloneDeep(value));
        updateQuestionKeyList(this);
        isQuizUsedFeedback(this);
        this.loadAnswers$.next(!this.editorMode);
        this.emmitCurrentLanguageTranslatedState();
      });
    this.incomingAction$.pipe(this.takeUntilAlive())
      .pipe(this.takeUntilAlive())
      .subscribe(action => {
        switch (action) {
          case QUIZ_EDITOR_ACTIONS.SHOW_USER_MODE:
            if (!this.isPresenter) {
              this.elementObserver.observe(this.elementRef.nativeElement, {childList: true});
            }
            this.isPresenter = !this.isPresenter;
            this.outputEmmitSwitchBackgroundColor(this.isPresenter);
          break;
        }
      });
    this.currentQKey$.pipe(this.takeUntilAlive())
      .pipe(this.takeUntilAlive())
      .subscribe(qId => {
        this.currentQuestionUseCorrectAnswers$.next(questionUseCheckingCorrectnessOfAnswer(this.questionList[qId]));
        this.setCurrentQuestionViewMode(qId);
      });
    if (!this.editorMode && this.learnMode) {
      this.quizService.checkAnswer$
        .pipe(this.takeUntilAlive())
        .subscribe(data => {
          if (data.questionId !== null && this.questionList[data.questionId] != null) {
            checkAnswersFeedbackChange(this);
            showCorrectAnswersChange(data.showCorrectAnswers, this);
            setCheckResult(this, data.questionId);
          }
        });
    }

    this.quizService.aiAction$
      .pipe(
        map(actionObject => actionObject?.[getCurrentSubjectId(this)]),
        filter(action => !isEmpty(action)),
        this.takeUntilAlive())
      .subscribe(action => {
        this.executeAIAction(action);
      });

    combineLatest([
      this.answersSummary$,
      this.currentQKey$
    ])
      .pipe(this.takeUntilAlive())
      .subscribe(([value, currentKey]: any) => {
        if (QUESTION_TYPES_COMPONENTS[this.questionList[currentKey]?.storypoint]?.showToolbarOfResponsesAsUsersAvatarList) {
          try {
            if (isEmpty(value)) {
              this.responses$.next([]);
            } else if (Array.isArray(value)) {
              this.responses$.next(value.map(o => JSON.parse(o)));
            } else if (value.questions[currentKey]) {
              this.responses$.next(value.questions[currentKey].map(o => JSON.parse(o)));
            } else {
              this.responses$.next([]);
            }
          } catch (e) {
            this.responses$.next([]);
          }
        } else {
          this.responses$.next([]);
        }
      });
  }

  onDestroy() {
    super.onDestroy();
    this.elementObserver.disconnect();
    finalizeDirectFeedback(this);
    this.subscriptionsOff();
  }

  trackByKey(index, value: EventQuestion) {
    return value.orderIndex;
  }

  protected inputFollowMeData(value) {
  }

  protected editQuiz(quiz: Quiz, isCreateFromTemplate?: boolean, editTemplate?: IQuizTemplate): Promise<boolean> {
    let dialogWidth = '80vw';
    let panelClass;
    if (window.innerWidth < 767) {
      dialogWidth = '100%';
      panelClass = 'create-event-dialog';
    } else {
      panelClass = 'create-event-dialog-big';
    }
    const dialogRef = this.dialog.open(QuizQuestionSheetEditorDialogComponent, {
      panelClass: panelClass,
      width: dialogWidth,
      minWidth: '370px',
      data: {
        documentPathParams: this.documentPathParams,
        quiz: quiz ?? new Quiz(),
        languageParams$: this.languageParams$,
        dirty: this.dirty,
        isCreateFromTemplate: isCreateFromTemplate,
        editTemplate: editTemplate
      }
    });
    return firstValueFrom(dialogRef.afterClosed())
      .then(result => {
        if (result) {
          this.data = result.content;
        }
        return !!result;
      });
  }

  protected createQuiz() {
    return firstValueFrom(this.dialog.open(QuizTemplatesListDialogComponent, {
      data: {
        languageParams$: this.languageParams$,
        documentPathParams: this.documentPathParams
      }}).afterClosed())
      .then(result => {
        if (result) {
          // if template not null this is template editor mode.
          // if template is null create new quiz from template.
          return this.editQuiz(result.quiz, !result.template, result.template);
        } else {
          return false;
        }
      });
  }

  onEdit(): Promise<boolean> {
    return this.qContent ? this.editQuiz(this.qContent) : this.createQuiz();
  }

  onNext(ev = null): boolean {
    if (ev) {
      ev.preventDefault();
      ev.stopPropagation();
    }
    if (!this.tgroup  || (this.tgroup.selectedIndex + 1 > this.tgroup._allTabs.length - 1)) {
      return false;
    }
    this.tgroup.selectedIndex = this.tgroup.selectedIndex + 1;
    return true;
  }

  onPrev(ev = null): boolean {
    if (ev) {
      ev.preventDefault();
      ev.stopPropagation();
    }
    if (!this.tgroup || this.tgroup.selectedIndex - 1 < 0) {
      return false;
    }
    this.tgroup.selectedIndex = this.tgroup.selectedIndex - 1;
    return true;
  }

  onTabChange(event) {
    if (this.currentQIndex > -1) {
      showDirectFeedback(this, this.currentQIndex);
    }
    if (event.index > -1) {
      this.currentQIndex = event.index;
      this.currentQKey$.next(this.questionList$.getValue()[event.index].id);
    }
  }

  media() {
    return document.body.clientWidth <= Constants.MEDIA_MAX_WIDTH;
  }

  ngAfterViewInit() {
    afterViewInit(this);
  }

  ngAfterViewChecked() {
    afterViewChecked(this);
  }

  private subscriptionsOff() {
    Object.values(this.subscriptions).forEach(s => s.unsubscribe());
    this.subscriptions = {};
    this.answers$.next(null);
  }

  private manager(snapshot): IQuizContentAnswersExtended {
    const answers = {};
    const users = {};
    const lastChange = {};
    let userCount = 0;
    if (snapshot) {
      for (const uAnswers of snapshot) {
        for (const qKey of Object.keys(uAnswers)) {
          if (qKey !== 'userId') {
            if (!answers[qKey]) {
              answers[qKey] = {};
            }
            answers[qKey][uAnswers.userId] = uAnswers[qKey].answers;
            if (!lastChange[qKey]) {
              lastChange[qKey] = {};
            }
            lastChange[qKey][uAnswers.userId] = uAnswers[qKey].lastchange;
            if (!users[qKey]) {
              users[qKey] = {};
            }
            users[qKey][uAnswers.userId] = uAnswers[qKey].user;
            userCount = max([userCount, Object.keys(users[qKey]).length]);
          }
        }
      }
    }
    this.quizUserListLength = userCount;
    return {answers, users, lastChange};
  }

  prepareQuizQuestionsAnswers(isPresenter) {
    const attendee = (snapshot): IQuizContentAnswersExtended => {
      const answers = {};
      const lastChange = {};
      const sendTime = {};
      const properties = {};
      if (snapshot) {
        for (const qKey of Object.keys(snapshot)) {
          if (qKey !== 'userId') {
            if (!answers[qKey]) {
              answers[qKey] = {};
            }
            if (!lastChange[qKey]) {
              lastChange[qKey] = {};
            }
            if (!sendTime[qKey]) {
              sendTime[qKey] = {};
            }
            if (!properties[qKey]) {
              properties[qKey] = {};
            }
            const userKey = this.qContent.isAnonymousAnswers() ? this.currentUserKey : this.currentUserId;
            answers[qKey][userKey] = snapshot[qKey].answers;
            lastChange[qKey][userKey] = snapshot[qKey].lastchange;
            sendTime[qKey][userKey] = snapshot[qKey].sendTime;
            properties[qKey][userKey] = snapshot[qKey].properties;
          }
        }
      }
      return {answers, lastChange, sendTime, properties};
    };
    this.subscriptionsOff();
    this.isPresenter = isPresenter;
    if (isPresenter) {
      this.subscriptions[3] = combineLatest([
        this.dataService.getQuizSummary(this.documentPathParams),
        this.dataService.getQuizTextSummary(this.documentPathParams)])
        .pipe(this.takeUntilAlive())
        .subscribe(([value, text]) => {
          this.answersSummary$.next(merge(cloneDeep(value), text));
        });
    } else {
      const userKey = this.qContent.isAnonymousAnswers() ? this.currentUserKey : this.currentUserId;
      this.subscriptions[1] = this.answers$.pipe(
        filter(v => !isEmpty(v)),
        pairwise(),
        this.takeUntilAlive())
        .subscribe(([prev, next]) => {
          if (!this.directFeedbackAnswersChanged) {
            this.directFeedbackAnswersChanged = JSON.stringify(prev) !== JSON.stringify(next);
          }
        });
      this.subscriptions[2] = this.dataService.getQuizQuestionsAnswersByUser(this.documentPathParams, userKey)
        .pipe(this.takeUntilAlive())
        .subscribe(value => {
          resetSingleQuestionQuizFeedbackStatusIcon(this);
          const attendeeAnswers = attendee(value);
          this.answers$.next(attendeeAnswers);
          updateQuestionsFeedback(this);
          setQuestionResults(this, userKey, attendeeAnswers?.properties || {});
        });
      this.subscriptions[3] = combineLatest([
        this.dataService.getQuizSummary(this.documentPathParams),
        this.dataService.getQuizTextSummary(this.documentPathParams)])
        .pipe(this.takeUntilAlive())
        .subscribe(([value, text]) => {
          this.answersSummary$.next(merge(cloneDeep(value), text));
        });
    }
  }

  acceptAnswersChange(event) {
    this.common.showProgress.next(true);
    (event ? showResultChange(false, this) : Promise.resolve()).then(() =>
      acceptAnswersChange(event, this)
        .catch(e => this.common.log.error(e))
        .finally(() => this.common.showProgress.next(false)))
      .catch(e => this.common.log.error(e))
      .finally(() => this.common.showProgress.next(false));
  }

  showCorrectAnswersChange(event) {
    showCorrectAnswersChange(event, this);
  }

  showResultChange(event) {
    this.common.showProgress.next(true);
    (event ? acceptAnswersChange(false, this) : Promise.resolve()).then(() =>
      showResultChange(event, this)
        .catch(e => this.common.log.error(e))
        .finally(() => this.common.showProgress.next(false)))
      .catch(e => this.common.log.error(e))
      .finally(() => this.common.showProgress.next(false));
  }

  userShowResultChange(event) {
    this.userShowResult = event;
  }

  async showDetailsChange(event) {
    this.showDetails = event;
    const dialogRef = this.dialog.open(QuizQuestionSheetParticipantsDetailComponent, { maxWidth: 'initial'});
    dialogRef.componentInstance.documentPathParams = this.documentPathParams;
    dialogRef.componentInstance.languageParams = this.languageParams$.getValue();
    dialogRef.componentInstance.qContent = this.qContent;
    this.common.showProgress.next(true);
    dialogRef.componentInstance.answersByQuestions =
      await firstValueFrom(this.dataService.getQuizQuestionsAnswers(this.documentPathParams))
        .then(value => this.manager(value))
        .finally(() => this.common.showProgress.next(false));
    dialogRef.afterClosed().pipe(take(1)).subscribe(() => this.showDetails = false);
  }

  beforeDeleteConstraint(): Promise<boolean> {
    return this.dataService.getQuizConstraint(this.documentPathParams)
      .then(constraint => {
        if (!isEmpty(constraint)) {
          if (!Object.keys(constraint[this.documentPathParams.containerId] || {})
            .every(qId => isEmpty(constraint[this.documentPathParams.containerId][qId]?.contents))) {
            this.common.showPopupError(this.common.i18n('edit_dialog.can_not_remove_questionnaire_dialog.body'));
            return false;
          }
        }
        return true;
      });
  }

  async translate(): Promise<any> {
    const currLParams = this.languageParams$.getValue();
    const defLParams: ILanguageParams = {
      defaultLanguage: currLParams.defaultLanguage,
      currentLanguage: currLParams.defaultLanguage,
      usedMultilingualContent: true,
      usedLanguages: []
    };
    const defContent: Quiz = cloneDeep(this.qContent);
    for (const question of Object.values(defContent.questions || {})) {
      let caption = question.getCaptionByLanguage(defLParams);
      caption = await this.translateApiService.translateSimpleString(caption, currLParams.defaultLanguage, currLParams.currentLanguage);
      question.setCaptionByLanguage(caption, currLParams);

      const answerData: TableRowAnswerQuestion[] = [];
      (question.items || []).forEach(item => answerData.push(new TableRowAnswerQuestion(item, defLParams)));
      for (const row of answerData) {
        let text = row.rowCaption;
        text = await this.translateApiService.translateSimpleString(text, currLParams.defaultLanguage, currLParams.currentLanguage);
        row.languageParams = currLParams;
        row.rowCaption = text;
      }
      question.items = [];
      for (const row of answerData) {
        const answer = new AnswerQuestion(row);
        question.items.push(answer);
      }

      const matchingData: TableRowAnswerQuestionMatching[] = [];
      (question.matchingItems || []).forEach(item => matchingData.push(new TableRowAnswerQuestionMatching(item, defLParams)));
      for (const row of matchingData) {
        let text = row.rowCaption;
        text = await this.translateApiService.translateSimpleString(text, currLParams.defaultLanguage, currLParams.currentLanguage);
        row.languageParams = currLParams;
        row.rowCaption = text;
      }
      question.matchingItems = [];
      for (const row of matchingData) {
        const matching = new AnswerQuestionMatching(row);
        question.matchingItems.push(matching);
      }
    }
    this.data = defContent;
    return Promise.resolve();
  }

  checkAnswersFeedbackChange() {
    checkAnswersFeedbackChange(this, true);
  }

  resetFeedbackCheckResults(questionId: string) {
    this.resetQuestionAnswers$.next(questionId);
    this.questionsDirectFeedback[questionId] = {} as IQuestionDirectFeedbackResult;
  }

  executeAIAction(aiTextOption: TEXT_AI_OPTION_LIST) {
    const aiAction = QUESTION_TYPES_COMPONENTS[this.questionList[this.currentQKey$.getValue()]?.storypoint]?.aiAction;
    if (aiAction && aiTextOption) {
      aiAction(aiTextOption, this);
    }
  }

  extendedQuestionTypeAction(action) {
    const actionList = QUESTION_TYPES_COMPONENTS[this.questionList[this.currentQKey$.getValue()].storypoint].extendedQuestionTypeActions;
    const questionAction: IExtendedPoolAction = actionList.find(a => a.action === action);
    if (questionAction && questionAction.actionMethod) {
      questionAction.actionMethod(this);
    }
  }

  // notifies everyone that some response has been sent.
  submittingAnswers(event) {
    this.quizService.submittingAnswers$.next(event);
    this.disableCheckAnswersOnSubmitting = event;
  }

  outputEmmitSwitchBackgroundColor(isPresenter) {
    this.outgoingAction$.next({
      action: SLIDE_ACTION.SWITCH_BACKGROUND_COLOR,
      data: {
        isPresenter: isPresenter ? null : false
      }
    });
  }

  setCurrentQuestionViewMode(questionId: string) {
    if (!this.editorMode || !questionId) {
      return;
    }
    const presenterMode = QUESTION_TYPES_COMPONENTS[this.questionList[questionId].storypoint].questionEditorDefaultModePresenter ?? false;
    if (this.isPresenter !== presenterMode) {
      this.incomingAction$.next(QUIZ_EDITOR_ACTIONS.SHOW_USER_MODE);
    }
  }
}
