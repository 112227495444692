import {Injectable} from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  delay,
  distinctUntilChanged,
  filter,
  firstValueFrom,
  interval,
  map,
  Observable,
  of,
  pairwise,
  sample,
  skip,
  startWith,
  Subject,
  Subscription,
  switchMap,
  take,
  takeUntil
} from 'rxjs';
import {
  AFTER_RUNNING,
  ASSIGN_FREE_SLOT_MODE,
  BEFORE_RUNNING,
  Constants,
  CONTENT_FROM_AUDIENCE_MODE,
  EVENT_DATE_MODE,
  EVENT_REGISTRATION_MODE,
  ILanguageParams,
  ILoadData,
  LOAD_STATE,
  MANAGE_TIME_ROW_TYPE,
  SECTION_EVENT_PHASE,
  TIMELINE_MODE
} from '../core/constants';
import {SectionTimeline} from '../model/content/SectionTimeline';
import {UtilsService} from '../core/utils.service';
import {AppUser} from '../model/AppUser';
import {IPlaneContentMap, PlaneContent} from '../model/content/PlaneContent';
import {ISectionSubject, SectionContent, SectionUser} from '../model/content/SectionContent';
import {InstantSettings, SlotModeSettings, VirtualConferenceSettings} from '../model/event-mode/InstantSettings';
import {ConferenceUser} from '../model/event-mode/ConferenceUser';
import {CurrentContentMessage} from '../model/event-mode/CurrentContentMessage';
import {Event, IManagerMap} from '../model/Event';
import {cloneDeep, difference, isEmpty, isEqual, union, unionWith} from 'lodash';
import {ShiftEventParams} from '../create-event/create-event.service';
import {LevelTimePoints, RowTask, TimePoint, TimePointMap} from '../model/event-mode/RowDatesObject';
import {ManageTimeService} from './manage-time.service';
import {ISectionComplete, ISectionSelfLearningSetting, SelfLearningService} from './self-learning.service';
import {AbstractContent} from '../model/content/AbstractContent';
import {QuestionnaireContent} from '../model/content/QuestionnaireContent';
import {AutopilotService} from './autopilot.service';
import {RegistrationSettings} from '../model/event-mode/RegistrationSettings';
import {EventsDataService, ISectionChildContents} from './events-data.service';
import {Store} from '@ngrx/store';
import * as fromRoot from '../reducers';
import {APP_MODE, LoginService} from '../login/login.service';
import {UsersDataService} from './users-data.service';
import {IDependencyAnswers, IDependencyQuestionnaire} from '../model/content/DependencyQuestionnaire';
import {ModularContent} from '../model/content/ModularContent';
import {FeedbackService} from './feedback.service';
import {TextMacrosParserService} from './text-macros-parser.service';
import {IRegistrationUserCardBySection, RegistrationUserCard} from '../model/event-mode/RegistrationUserCard';
import {WelcomeScreen} from '../model/content/WelcomeScreen';
import {ActivatedRoute, NavigationStart, Router, RouterEvent} from '@angular/router';
import {Module, ModulesService} from '@ninescopesoft/core';
import {FollowMeService, IFollowMeAction} from './follow-me.service';
import {ContentContainer, ContentContainerItem} from '../model/content/ContentContainer';
import {LANGUAGE} from '../core/language-constants';
import {TagsService} from '../modules/tags-reference/tags-service/tags.service';
import {FeaturesService} from '../core/features.service';
import {TimelineFilterService} from './timeline-filter.service';
import {EventSectionType} from '../model/EventSectionType';
import {DebugService} from './debug.service';

export interface IAddContentSubject {
   contentType: string;
   orderIndex: number;
   contentForCancel?: AbstractContent;
   parentSection?: SectionContent;
   secondParentId?: string;
}

export interface IShowPresenterQuestionnaireTask {
  questionnaire: QuestionnaireContent;
  emailAsUserId: string;
}

export interface IJoinSectionSubject {
  section: SectionContent;
  join: boolean;
}

export interface IInitEventOptions {
  registrationMode?: boolean;
  initManageTime?: boolean;
}

export interface IUserJoinedSection {[containerId: string]: SectionContent; }
export interface IUserContainerSection {[childId: string]: SectionContent; }

export interface IUsersSectionsData {
  userId: string; sections: string[];
}

export interface IUserSectionsData {
  sections: string[];
}

export interface IUsersOnlineShort {
  userId: string;
  pingTime: number;
  createTime: number;
}

export interface IFollowMeCurrentAction {
  action: IFollowMeAction;
  service: FollowMeService;
}

interface ICurrentTimelineState {
  section?: SectionContent | SectionTimeline;
  content?: AbstractContent;
}

enum LOAD_DATA {
  EVENT = 'loadEvent',
  CONFERENCE_USER = 'loadConferenceUser',
  USER_REGISTRATION = 'loadUserRegistration',
  SECTIONS = 'loadSections',
  INSTANT_SETTINGS = 'loadInstantSettings',
  REGISTRATION_SETTINGS = 'loadRegistrationSettings',
  WELCOME_SCREEN = 'loadWelcomeScreen',
  USER_DATA = 'loadUserData',
  USER_COUNT = 'loadUserCount',
  USERS_ONLINE_SHORT = 'loadUsersOnlineShort',
  REGISTRATION_COUNTERS = 'loadRegistrationCounters',
  SECTION_TYPES_LIST = 'loadSectionTypesList'
}

export enum NAVIGATION_TO {
  EVENT = 'event',
  EVENT_SETTINGS = 'settings',
  EVENT_REGISTRATION = 'registration',
  MODULE = 'module',
  DASHBOARD = 'dashboard'
}

export interface IExpandContentContainer {
  content: ContentContainer;
  itemsOrderedByTop: ContentContainerItem[];
}

export interface IConferenceUserManagerMode {
  isFollowMeManager: boolean;
  managerUsedParticipantMode: boolean;
}

interface IContentsShort {
  [sectionId: string]: {
    [contentId: string]: string
  };
}

@Injectable({
    providedIn: 'root'
  }
)
export class TimeLineService {
  protected _unsubscribeAll: Subject<any> = new Subject();
  private _calcOrderIndexContentId: string;
  public _featureLineContentId: string;
  private _featureLineContentIdPrev: string;
  featureLineContentRange = {};
  featureLineContentId$ = new BehaviorSubject<string>(null);

  _rootSection$$ = new BehaviorSubject<ILoadData<SectionContent>>({loaded: LOAD_STATE.NOT_LOADED, value: null});
  private _planeListContent: IPlaneContentMap = {}; // for build display tree base on user rights and access
  private _planeFullListContent: IPlaneContentMap = {}; // for build orderIndex contains all linked contents
  private _sectionBeforeRunningStatus: {[sectionId: string]: string} = {};
  private _availableSectionIdList = {};
  instantContents$ = new BehaviorSubject<SectionTimeline[]>([]);
  private _planeListContentSorted: PlaneContent[] = [];
  private _planeListContentSortedWithoutFixed: PlaneContent[] = [];
  private _planeListContentSortedWithoutFixedAsSectionArray: SectionTimeline[] = [];
  private _planeListContentAsArray: PlaneContent[] = [];
  private _planeListContentAsSectionArray: SectionTimeline[] = [];
  private _planeFullListContentAsSortedArray: PlaneContent[] = [];
  private _timelineSpeakers = {};

  private _selectedSection$: BehaviorSubject<SectionContent> = new BehaviorSubject<SectionContent>(null);
  private _currentStateEducation: ICurrentTimelineState = {};
  private _currentStateTimeline: ICurrentTimelineState = {};
  private _changeSectionSubject = new BehaviorSubject<SectionContent>(null);
  private _refreshSectionSubject = new Subject<SectionContent>();
  selectedLineContentRange = {};

  private _planeList = new BehaviorSubject<IPlaneContentMap>({});
  private _planeListSubject = new Subject<number>();

  private _presentationMode = true;

  private _childTreeInLineListCache: {[sectionId: string]: PlaneContent[]} = {};
  private _sectionsAutoCounterValues = {};

  private _currentContent = new BehaviorSubject<CurrentContentMessage>({});

  private _selectedContent: AbstractContent;

  private _addContentSubject = new Subject<IAddContentSubject>();

  private _openPreviewGallery = new Subject<boolean>();
  private _eventNoTimingSections: boolean;
  private _companyName: string;
  public _everybody_at_with_the_link_i18: {editDialog: string, eventFilterStatus: string, viewEventVisibility};
  public _on_dashboard_i18: {editDialog: string, eventFilterStatus: string, viewEventVisibility};
  private _fixedSections: {[key: string]: SectionContent} = {};
  private _showTooltipOnMobile = true;
  private _selectedSectionUserRegistered = true;

  private _manageTimeMap: TimePointMap;
  private _refreshMangeTimeMap = new Subject<boolean>();
  private _calculateManageTime = new BehaviorSubject<number>(null);
  private _timelineUpdateCounter = 0;

  private _firstLevelTimePoints = new LevelTimePoints();
  completeUserSectionRegistration$ = new BehaviorSubject<string[]>([]);
  private _notCompleteUserSectionRegistration: string[] = [];
  private _refreshDisplayTimeline = new Subject<boolean>();
  private _updateMangeTimeObjectsBlocked = false;
  private _calculateMangeTimeObjectsBlocked = false;
  private _pushRefreshUserEventSubject = new BehaviorSubject<number>(null);
  private _eventWithoutSelfLearning: boolean;
  private _currentQuestionnaireOpenLastQuestion = new Subject<boolean>();
  private _currentQuestionnaireOpenTextQuestion = new Subject<boolean>();
  private _currentQuestionnaireChangeQuestion = new Subject<boolean>();
  private _showRegistrationQuestionnaireForPresenter = new Subject<IShowPresenterQuestionnaireTask>();
  private _permitRocheVideo: boolean;
  private _currentTimeInDB = false;
  private _openSectionRegistrationDialogFromContainer = false;
  private _sectionsByExclusivityGroup: {[groupId: string]: string[]} = {};
  private _joinSectionSubject = new BehaviorSubject<IJoinSectionSubject>(null);
  private _showPrintDialog = new Subject<boolean>();
  private _hideMainToolbar = new Subject<boolean>();
  timelineLoaded = new BehaviorSubject<boolean | null>(null);
  private _unsubscribeSectionContents = new Subject<boolean>();
  private _sectionChildContentsListSubject = new BehaviorSubject<ISectionChildContents>(null);
  private _showSocialPanel = new BehaviorSubject<boolean>(false);
  private _mobileModeSubject = new Subject();
  private _conferenceUserJoinedSections: IUserJoinedSection = {};
  private _conferenceUserContainerSections: IUserContainerSection = {};
  currentUser$ = new BehaviorSubject<AppUser>(null);
  private _userInitiatorEventMeeting: AppUser;
  private _onlyRoot = true;
  private _conferenceUsers = new BehaviorSubject<any[]>([]);
  private _isSpeakerMode = false;
  private _mainSpeakerSection: SectionContent;
  private _mainSpeakerSection$ = new BehaviorSubject<SectionContent>(null);
  private _dependencyAnswers$ = new BehaviorSubject<IDependencyAnswers>(null);
  private _planeFullListContentWithoutFixed: IPlaneContentMap = {};
  private _planeFullListContentWithoutFixedAsSortedArray: PlaneContent[] = [];
  private _timelineChanged$ = new BehaviorSubject<number>(null);
  private _refreshDisplayTimeline$ = new BehaviorSubject<number>(null);
  featureLineSectionExpand$ = new BehaviorSubject<string>(null);
  timelineTreeChildChanged$ = new BehaviorSubject<string>(null);
  private _showQADialog = new BehaviorSubject<boolean>(null);
  readonly showQADialog$ = this._showQADialog.asObservable();
  private _qaSecondParentId: string;
  readonly qaSecondParentIdChange$ = new Subject();
  private _sectionsWithSpecificQAList: {id: string, title: string}[] = [];
  private _sectionTypesList$  = new BehaviorSubject<ILoadData<EventSectionType[]>>({loaded: LOAD_STATE.NOT_LOADED, value: null});
  sectionTypeIconList: {[id: string]: string} = {};

  private _followMeEventPGSParticipant = new BehaviorSubject<boolean>(false);
  private _followMeEventAsPresenter = new BehaviorSubject<boolean>(false);
  private _followMeSectionPGSParticipant = new BehaviorSubject<{[sectionId: string]: boolean}>(null);
  private _followMeSectionAsPresenter = new BehaviorSubject<{[sectionId: string]: boolean}>(null);
  private _sectionContentsLoaded = new BehaviorSubject<boolean>(false);
  private _selectedQASection = new BehaviorSubject<SectionContent>(null);
  private _qaContent = new BehaviorSubject<AbstractContent>(null);
  private _timelineSectionContentsList = new BehaviorSubject<AbstractContent[]>(null);
  private _followMePresenterUser: AppUser;
  private _followMePresenterUser$ = new BehaviorSubject<AppUser>(null);
  private _followMeSpeakerUser: AppUser;
  private _prevContent: AbstractContent;

  public module$: BehaviorSubject<Module> = new BehaviorSubject<Module>(null);

  private _eventDataLoaded$ = new BehaviorSubject<boolean>(false);
  public _event$ = new BehaviorSubject<ILoadData<Event>>({loaded: LOAD_STATE.NOT_LOADED, value: null});
  private _eventSpeakers$ = new BehaviorSubject<ILoadData<IManagerMap>>({loaded: LOAD_STATE.NOT_LOADED, value: null});
  private _eventManagers$ = new BehaviorSubject<ILoadData<IManagerMap>>({loaded: LOAD_STATE.NOT_LOADED, value: null});
  private _conferenceUser$ = new BehaviorSubject<ILoadData<ConferenceUser>>({loaded: LOAD_STATE.NOT_LOADED, value: null});
  private _userRegistration$ = new BehaviorSubject<ILoadData<IRegistrationUserCardBySection>>({loaded: LOAD_STATE.NOT_LOADED, value: null});
  // sorted section array by orderIndex
  public _sections$ = new BehaviorSubject<ILoadData<SectionContent[]>>({loaded: LOAD_STATE.NOT_LOADED, value: null});
  public timelineSections$ = new BehaviorSubject<SectionContent[]>([]);
  public educationSections$ = new BehaviorSubject<SectionContent[]>([]);
  private _instantSettings$ = new BehaviorSubject<ILoadData<InstantSettings>>({loaded: LOAD_STATE.NOT_LOADED, value: null});
  private _registrationSettings$ = new BehaviorSubject<ILoadData<RegistrationSettings[]>>({
    loaded: LOAD_STATE.NOT_LOADED,
    value: null
  });
  private _registrationCounters$ = new BehaviorSubject<ILoadData<any[]>>({
    loaded: LOAD_STATE.NOT_LOADED,
    value: null
  });
  private _welcomeScreen$ = new BehaviorSubject<ILoadData<WelcomeScreen>>({loaded: LOAD_STATE.NOT_LOADED, value: null});
  private _userData$ = new BehaviorSubject<ILoadData<IUserSectionsData>>({loaded: LOAD_STATE.NOT_LOADED, value: null});
  public _userCount$ = new BehaviorSubject<ILoadData<number>>({loaded: LOAD_STATE.NOT_LOADED, value: 0});
  private _usersOnlineShort$ = new BehaviorSubject<ILoadData<IUsersOnlineShort[]>>({loaded: LOAD_STATE.NOT_LOADED, value: []});
  public timelineSlotMode = new BehaviorSubject<boolean>(false);
  private _timelineSlotMode = false;
  private _followMeCurrentAction: IFollowMeCurrentAction;
  selectedSectionChanged$ = new Subject();

  expandContentContainer$ = new BehaviorSubject<IExpandContentContainer>(null);
  expandContentContainerItem$ = new BehaviorSubject<ContentContainerItem>(null);
  expandFollowMeContentContainerItem$ = new BehaviorSubject<string>(null);
  previewVirtualConferenceSettings: VirtualConferenceSettings;
  conferenceUserManagerMode$ = new BehaviorSubject<IConferenceUserManagerMode>({
    isFollowMeManager: false,
    managerUsedParticipantMode: false
  });

  private otherSubscriptions: {[key: string]: Subscription} = {};
  private subscriptions: {
    [key: string]: {
      subscription?: Subscription,
      subject: BehaviorSubject<ILoadData<any>>
    }
  } = {
    [LOAD_DATA.EVENT]: {subject: this._event$},
    [LOAD_DATA.CONFERENCE_USER]: {subject: this._conferenceUser$},
    [LOAD_DATA.USER_REGISTRATION]: {subject: this._userRegistration$},
    [LOAD_DATA.SECTIONS]: {subject: this._sections$},
    [LOAD_DATA.INSTANT_SETTINGS]: {subject: this._instantSettings$},
    [LOAD_DATA.REGISTRATION_SETTINGS]: {subject: this._registrationSettings$},
    [LOAD_DATA.WELCOME_SCREEN]: {subject: this._welcomeScreen$},
    [LOAD_DATA.USER_DATA]: {subject: this._userData$},
    [LOAD_DATA.USER_COUNT]: {subject: this._userCount$},
    [LOAD_DATA.USERS_ONLINE_SHORT]: {subject: this._usersOnlineShort$},
    [LOAD_DATA.REGISTRATION_COUNTERS]: {subject: this._registrationCounters$},
    [LOAD_DATA.SECTION_TYPES_LIST]: {subject: this._sectionTypesList$}
  };

  private _sectionsQuestionnaireDependency$ = new BehaviorSubject<IDependencyQuestionnaire[]>([]);
  private _sectionsDependencyAnswers$ = new BehaviorSubject<IDependencyAnswers>(null);

  timelineShort$ = new BehaviorSubject<any>(null);

  readonly userInterfaceLanguage$ = new BehaviorSubject<string>(null);

  /** NavigationStart to ... */
  destination: NAVIGATION_TO = null;

  educationViewDraftMode$ = new BehaviorSubject<boolean>(false);

  constructor(public utils: UtilsService,
              private store: Store<fromRoot.State>,
              private dataService: EventsDataService,
              private usersDataService: UsersDataService,
              private manageTimeService: ManageTimeService,
              public selfLearningService: SelfLearningService,
              public loginService: LoginService,
              private autopilotService: AutopilotService,
              private feedbackService: FeedbackService,
              private modulesService: ModulesService,
              private tagsService: TagsService,
              public textMacrosParserService: TextMacrosParserService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private featuresService: FeaturesService,
              private timelineFilterService: TimelineFilterService,
              private debugService: DebugService) {
    this.router.events
      .pipe(filter(e => e instanceof NavigationStart))
      .subscribe((event: NavigationStart) => {
        let url = (<RouterEvent>event).url;
        if (url && url.includes('?')) {
          url = url.split('?')[0];
        }
        const isEvent = url.startsWith('/event/') && !url.startsWith('/event/new/');
        const isDashboard = url.startsWith('/dashboard');
        const isModule = url.startsWith('/module/');
        const isSettings = url.endsWith('/settings');
        const isRegistration = url.endsWith('/register');
        this.destination = null;

        if (isEvent) {
          if (!isSettings && !isRegistration) {
            this.destination = NAVIGATION_TO.EVENT;
          }
          if (isSettings) {
            this.destination = NAVIGATION_TO.EVENT_SETTINGS;
          }
          if (isRegistration) {
            this.destination = NAVIGATION_TO.EVENT_REGISTRATION;
          }
        }
        if (isModule) {
          this.destination = NAVIGATION_TO.MODULE;
        }
        if (isDashboard) {
          this.destination = NAVIGATION_TO.DASHBOARD;
        }
        if (!isEvent && !isModule) {
          this.unsubscribeAll();
        }
      });
  }

  private static exceedOnlineInterval(pingTime) {
    if (!pingTime) {return true; }
    if ((new Date()).getTime() - pingTime > Constants.ONLINE_INTERVAL) {
      return true;
    } else {
      return false;
    }
  }

  get eventDataLoaded$(): BehaviorSubject<boolean> {
    return this._eventDataLoaded$;
  }

  get userInterfaceLanguage(): LANGUAGE {
    return this.userInterfaceLanguage$.getValue() as LANGUAGE;
  }

  getEventLanguageParams(): ILanguageParams {
    const ml = this.event?.getMultilingual();
    return {
      defaultLanguage: this.DEFAULT_LANGUAGE,
      currentLanguage: this.userInterfaceLanguage,
      usedMultilingualContent: ml?.multilingual,
      usedLanguages: ml?.usedLanguages
    };
  }

  unsubscribeAll() {
    this.eventDataLoaded$.next(false);
    this.resetServiceValues();
    Object.keys(this.subscriptions)
      .forEach(it => {
        this.setSubscription(<LOAD_DATA> it, null);
      });
    this.utils.unsubscribeAll(this.otherSubscriptions);
    this._calculateManageTime.next(null);
    this.timelineLoaded.next(null);
    this.tagsService.unsubscribeAll();
  }

  setSubscription(name: LOAD_DATA, sub: Subscription) {
    if (this.subscriptions[name]) {
      if (this.subscriptions[name].subscription) {
        this.subscriptions[name].subscription.unsubscribe();
      }
      this.subscriptions[name].subscription = sub;
      if (sub) {
        this.subscriptions[name].subject.next({loaded: LOAD_STATE.LOADING, value: null});
      } else if (this.subscriptions[name].subject.getValue().loaded !== LOAD_STATE.NOT_LOADED) {
        this.subscriptions[name].subject.next({loaded: LOAD_STATE.NOT_LOADED, value: null});
      }
    }
  }

  /*
  * Load Event
  * */
  loadEvent(eventId: string, sectionId?: string) {
    const event = this._event$.getValue().value;
    if (this._event$.getValue().loaded === LOAD_STATE.LOADED && (eventId === event.eventId || eventId === event.shortLink)) {
      return;
    }
    let sub;
    this.timelineLoaded.next(this.destination === NAVIGATION_TO.EVENT || this.destination === NAVIGATION_TO.MODULE ? false : null);
    if (!this.loginService.educationMode) {
      sub = this.dataService.getEvent(eventId)
        .pipe(
          startWith(null),
          pairwise())
        .subscribe(async ([oldValue, value]: [Event, Event]) => {
          if (sectionId && oldValue?.eventId !== value?.eventId && value) {
            await firstValueFrom(this.dataService.getSection(value.eventId, sectionId))
              .then(section => {
                if (section) {
                  this._timelineSlotMode = section.education;
                  this.timelineSlotMode.next(section.education);
                  this.setCurrentTimelineState(section, 'section');
                }
              });
          }
          this._event$.next({loaded: LOAD_STATE.LOADED, value: value});
          this.selfLearningService.event = this._event$.getValue().value;
          const sDiff = !isEmpty(difference(Object.keys(oldValue?.speakers || {}), Object.keys(value?.speakers || {}))) ||
            !isEmpty(difference(Object.keys(value?.speakers || {}), Object.keys(oldValue?.speakers || {})));
          const mDiff = !isEmpty(difference(Object.keys(oldValue?.managers || {}), Object.keys(value?.managers || {}))) ||
            !isEmpty(difference(Object.keys(value?.managers || {}), Object.keys(oldValue?.managers || {})));
          if (sDiff || this._eventSpeakers$.getValue().loaded === LOAD_STATE.NOT_LOADED) {
            this._eventSpeakers$.next({loaded: LOAD_STATE.LOADED, value: value?.speakers});
          }
          if (mDiff || this._eventManagers$.getValue().loaded === LOAD_STATE.NOT_LOADED) {
            this._eventManagers$.next({loaded: LOAD_STATE.LOADED, value: value?.managers});
          }
        });
    } else {
      sub = this.modulesService.get(eventId)
        .subscribe((value: Module) => {
          let _event: Event;
          const managers = (((<Module>value).owners || []).concat(((<Module>value).managers) || [])).reduce((acc, item) => {
            acc[item.id] = item;
            return acc;
          }, {});
          _event = new Event({
            ...value,
            eventId,
            managers,
            presenters: managers
          });
          this._event$.next({loaded: LOAD_STATE.LOADED, value: _event});
          this.module$.next(value);
        });
    }
    this.setSubscription(LOAD_DATA.EVENT, sub);
  }

  /*
  * Emit only after all event data will be loaded
  * */
  get event$(): Observable<Event> {
    return this.eventDataLoaded$
      .pipe(
        filter(it => it),
        switchMap(() => {
          return this._event$.pipe(filter(it => it.loaded === LOAD_STATE.LOADED), map(it => it.value));
        })
      );
  }

  /*
  * Emit after event will be loaded
  * */
  get event$$(): Observable<Event> {
    return this._event$.pipe(filter(it => it.loaded === LOAD_STATE.LOADED), map(it => it.value));
  }

  get eventManagers$(): Observable<IManagerMap> {
    return this._eventManagers$.pipe(filter(it => it.loaded === LOAD_STATE.LOADED), map(it => it.value));
  }

  get eventSpeakers$(): Observable<IManagerMap> {
    return this._eventSpeakers$.pipe(filter(it => it.loaded === LOAD_STATE.LOADED), map(it => it.value));
  }

  /*
  * @see Event
  * */
  get event(): Event {
    if (this._event$.getValue().loaded === LOAD_STATE.LOADED) {
      return this._event$.getValue().value;
    }
    return null;
  }

  /**
   * Load important to open event data
   * @param eventId
   */
  async loadEventDataAwait(eventId?: string) {
    if (eventId) {
      if (this.event?.eventId !== eventId) {
        this.unsubscribeAll();
      }
      this.loadEvent(eventId);
    } else if (this._event$.getValue().loaded === LOAD_STATE.NOT_LOADED) {
      return;
    }
    const isAnonymous = this.loginService.getAppUser().guest && !this.loginService.getAppUser().email;
    if (this._event$.getValue().loaded === LOAD_STATE.LOADING) {
      await firstValueFrom(this._event$.pipe(filter(it => it.loaded === LOAD_STATE.LOADED), map(it => it.value)));
    }
    const loadList = [];
    if (this._sections$.getValue().loaded === LOAD_STATE.NOT_LOADED) {
      this.loadSections();
      loadList.push(this.sections$$);
    }
    if (this._userRegistration$.getValue().loaded === LOAD_STATE.NOT_LOADED && !isAnonymous) {
      this.loadUserRegistration();
      loadList.push(this.userRegistration$);
    }
    if (this._registrationSettings$.getValue().loaded === LOAD_STATE.NOT_LOADED) {
      this.loadRegistrationSettings();
      loadList.push(this.registrationSettings$);
    }
    if (this._registrationCounters$.getValue().loaded === LOAD_STATE.NOT_LOADED) {
      this.loadRegistrationCounters();
      loadList.push(this.registrationCounters$);
    }
    if (this._conferenceUser$.getValue().loaded === LOAD_STATE.NOT_LOADED) {
      this.loadConferenceUser();
      loadList.push(this.conferenceUser$);
    }
    if (this._instantSettings$.getValue().loaded === LOAD_STATE.NOT_LOADED) {
      this.loadInstantSettings();
      loadList.push(this.instantSettings$);
    }
    if (this._userData$.getValue().loaded === LOAD_STATE.NOT_LOADED) {
      this.loadUserData();
      loadList.push(this.userData$);
    }
    if (this._sectionTypesList$.getValue().loaded === LOAD_STATE.NOT_LOADED) {
      this._sectionTypesList$.next({loaded: LOAD_STATE.LOADED, value: null});
      this.loadSectionTypesList();
      loadList.push(this.sectionTypesList$);
    }
    return (loadList.length ? firstValueFrom(combineLatest(loadList)) : Promise.resolve())
      .then(() => this.tagsService.loadData(this.event.eventId))
      .then(() => this.eventDataLoaded$.next(true));

  }

  /**
   * Load important to open event data in education mode
   * @param eventId
   */
  async loadEventEducationDataAwait(eventId?: string) {
    if (eventId) {
      if (this.event?.eventId !== eventId) {
        this.unsubscribeAll();
      }
      this.loadEvent(eventId);
    } else if (this._event$.getValue().loaded === LOAD_STATE.NOT_LOADED) {
      return;
    }

    if (this._event$.getValue().loaded === LOAD_STATE.LOADING) {
      await firstValueFrom(this._event$.pipe(filter(it => it.loaded === LOAD_STATE.LOADED), map(it => it.value)));
    }
    const loadList = [];
    if (this._sections$.getValue().loaded === LOAD_STATE.NOT_LOADED) {
      this.loadSections();
      loadList.push(this.sections$$);
    }
    if (this._conferenceUser$.getValue().loaded === LOAD_STATE.NOT_LOADED) {
      this._conferenceUser$.next({loaded: LOAD_STATE.LOADED, value: new ConferenceUser({
          userId: this.loginService.getAppUser().userId,
          email: this.loginService.getAppUser().email,
          fullName: this.loginService.getAppUser().fullName,
          picture: this.loginService.getAppUser().picture
        })});
      loadList.push(this.conferenceUser$);
    }
    if (this._instantSettings$.getValue().loaded === LOAD_STATE.NOT_LOADED) {
      this._instantSettings$.next({loaded: LOAD_STATE.LOADED, value: new InstantSettings()});
      loadList.push(this.instantSettings$);
    }
    if (this._userData$.getValue().loaded === LOAD_STATE.NOT_LOADED) {
      this._userData$.next({loaded: LOAD_STATE.LOADED, value: null});
      loadList.push(this.userData$);
    }
    if (this._sectionTypesList$.getValue().loaded === LOAD_STATE.NOT_LOADED) {
      this._sectionTypesList$.next({loaded: LOAD_STATE.LOADED, value: null});
      this.loadSectionTypesList();
      loadList.push(this.sectionTypesList$);
    }
    if (loadList.length) {
      await combineLatest(loadList).pipe(take(1)).toPromise();
      this.eventDataLoaded$.next(true);
    }
  }

  /*
  * Load additional event data without awaiting
  * */
  loadAdditionalData() {
    this.loadUserCount();
    this.loadUsersOnlineShort();
    this.loadWelcomeScreen();
  }

  /*
  * Load conference user
  * */
  loadConferenceUser() {
    const eventId = this._event$.getValue().value.eventId;
    const sub = this.dataService.loadUserInformation(eventId, this.loginService.getAppUser().userId)
      .pipe(distinctUntilChanged((p, c) => UtilsService.isObjectsEqual(p, c)))
      .subscribe((value: ConferenceUser) => {
        this.setJoinedUserSections(value);
        this._conferenceUser$.next({loaded: LOAD_STATE.LOADED, value: value});
      });
    this.setSubscription(LOAD_DATA.CONFERENCE_USER, sub);
  }

  get conferenceUser$(): Observable<ConferenceUser> {
    return this._conferenceUser$.pipe(filter(it => it.loaded === LOAD_STATE.LOADED), map(it => it.value));
  }

  get conferenceUser(): ConferenceUser {
    return this._conferenceUser$.getValue().value;
  }

  /*
  * Request user registration information from database
  * @see userRegistration$
  * */
  loadUserRegistration() {
    const userCode = this.utils.emailToId(this.loginService.getAppUser().email);
    const eventId = this._event$.getValue().value.eventId;
    const sub = this.dataService.getUserRegistration(eventId, userCode)
      .subscribe(value => {
        if (value && value.sections) {
          const data: IRegistrationUserCardBySection = Object.keys(value.sections).reduce((accum, item) => {
            accum[item] = <RegistrationUserCard>value.sections[item];
            return accum;
          }, {});
          this._userRegistration$.next({loaded:  LOAD_STATE.LOADED, value: data});
        } else {
          this._userRegistration$.next({loaded: LOAD_STATE.LOADED, value: {}});
        }
      });
    this.setSubscription(LOAD_DATA.USER_REGISTRATION, sub);
  }

  /*
  * User registration by section.
  * @returns
  * { [sectionId: string]: {@link RegistrationUserCard} }
  * */
  get userRegistration$() {
    return this._userRegistration$.pipe(filter(it => it.loaded === LOAD_STATE.LOADED), map(it => it.value));
  }

  get userRegistration(): IRegistrationUserCardBySection {
    return this._userRegistration$.getValue().value;
  }

  loadRootSection(value: SectionContent[]) {
    const root = value.find(s => !s.parentId);
    if (root) {
      if (!isEqual(root, this.rootSection)) {
        this._rootSection$$.next({loaded: LOAD_STATE.LOADED, value: root});
      }
    } else {
      this._rootSection$$.next({loaded: LOAD_STATE.NOT_LOADED, value: null});
    }
  }

  isTimelineContentsLoaded() {
    return !!this.otherSubscriptions[TIMELINE_MODE.TIMELINE];
  }

  isAvailableContentsLoaded() {
    return !!this.otherSubscriptions[TIMELINE_MODE.AVAILABLE_CONTENTS];
  }

  loadSections() {
    const eventId = this._event$.getValue().value.eventId;
    const sub = this.timelineSlotMode.pipe(switchMap(availabelContentsMode => {
      if (!availabelContentsMode) {
        if (!this.otherSubscriptions[TIMELINE_MODE.TIMELINE]) {
          this.otherSubscriptions[TIMELINE_MODE.TIMELINE] = this.dataService.getSectionsByMode(eventId, false)
            .subscribe(value => this.timelineSections$.next((value || []).sort(this.utils.comparator(Constants.ORDERINDEX))));
        }
        return this.timelineSections$.asObservable();
      } else {
        if (!this.otherSubscriptions[TIMELINE_MODE.AVAILABLE_CONTENTS]) {
          this.otherSubscriptions[TIMELINE_MODE.AVAILABLE_CONTENTS] = this.dataService.getSectionsByMode(eventId, true)
            .subscribe(value => this.educationSections$.next((value || [])
              .map(s => !s.isRoot ? s : new SectionContent({...s, education: true})).sort(this.utils.comparator(Constants.ORDERINDEX))));
        }
        return this.educationSections$.asObservable();
      }
    }))
      .pipe(filter(v => !isEmpty(v)))
      .subscribe(list => {
        const maxOrderIndex = (list || []).filter(s => !s.fixedSectionType).reduce((acc, item) => {
          acc = item.orderIndex > acc ? item.orderIndex : acc;
          return acc;
        }, new Date().getTime());
        this.dataService.setMaxTimelineSectionsOrderIndex(maxOrderIndex + 1000000);
        this.loadRootSection(list);
        this._sections$.next({loaded: LOAD_STATE.LOADED, value: list});
        if (this.event.slotMode) {
          if (this._timelineSlotMode !== this.timelineSlotMode.getValue()) {
            if (this.timelineSlotMode.getValue()) {
              this.routerNavigateToEducation();
            } else {
              this.routerNavigateToTimeline();
            }
            this._timelineSlotMode = this.timelineSlotMode.getValue();
          }
        }
      });
    this.setSubscription(LOAD_DATA.SECTIONS, sub);
    this.sectionsAdditionsSubscriptions();
  }

  sectionsAdditionsSubscriptions() {
    if (!this.loginService.educationMode && !this.isPresenter) {
      this.otherSubscriptions['listSectionsQuestionnaireDependency'] = combineLatest([
        this.sections$$, this.dataService.getContentsShort(this.event.eventId)
      ]).subscribe(([sections, shortList]) => {
        this.timelineShort$.next(shortList);
        this._sectionsQuestionnaireDependency$.next(this.loadSectionsDependency(sections, shortList));
      });
      this._sectionsQuestionnaireDependency$.pipe(pairwise())
        .subscribe(([prevDependencyList, nextDependencyList]) => {
          const prev = (prevDependencyList || [])
            .map(it => `${it.sectionId}-${(it.contentId ?? it.questionnaireId)}-${it.questionnaireId}-${it.questionId}`);
          const next = (nextDependencyList || [])
            .map(it => `${it.sectionId}-${(it.contentId ?? it.questionnaireId)}-${it.questionnaireId}-${it.questionId}`);
          if (!isEmpty(difference(prev, next)) || !isEmpty(difference(next, prev))) {
            if (this.otherSubscriptions['sectionsQuestionnaireDependency']) {
              this.otherSubscriptions['sectionsQuestionnaireDependency'].unsubscribe();
            }
            const userId = this.loginService.getAppUser().userId;
            this.otherSubscriptions['sectionsQuestionnaireDependency'] =
              this.dataService.subscribeUserOnDependencyQuestionnaire(this.timelineEvent.eventId, userId, nextDependencyList)
                .subscribe(value => {
                  this._sectionsDependencyAnswers$.next(!isEmpty(value) ? value as IDependencyAnswers : {});
                });
          }
        });
    } else if (!this.loginService.educationMode && this.isPresenter) {
      this.otherSubscriptions['timelineShort'] = this.dataService.getContentsShort(this.event.eventId)
        .subscribe(shortList => {
          this.timelineShort$.next(shortList);
        });
    }
  }

  private routerNavigateToEducation() {
    const navigateToFirst = () => {
      const csl = this._educationSections.filter(s => !s.isRoot && s.parentId === this.rootSection.id)
        .sort(this.utils.comparator(Constants.ORDERINDEX));
      if (!isEmpty(csl)) {
        this.router.navigate([], {queryParams: {sid: csl[0].id}, relativeTo: this.activatedRoute});
      } else {
        const root = this._educationSections.find(s => s.isRoot);
        this.router.navigate(['event', this.event.shortLink]);
        this.router.navigate([], {queryParams: {sid: root.id}, relativeTo: this.activatedRoute});
        this.dataService.dispatchSetCurrentContent(null);
      }
    };
    if (this.selectedSection && this.selectedSection.education) {
      return;
    }
    const state = cloneDeep(this._currentStateEducation);
    this._planeList.pipe(takeUntil(this._unsubscribeAll), skip(1), take(1))
      .subscribe(() => {
        if (!isEmpty(state)) {
          if (state?.section && this._planeFullListContent[state.section.id]) {
            const sc = state.section;
            if (state.content) {
              const cnId = state.content.id;
              this._sectionContentsLoaded.pipe(takeUntil(this._unsubscribeAll), skip(1), filter(it => it), take(1), delay(1))
                .subscribe(() => {
                  this.router.navigate([], {queryParams: {sid: sc.id, cid: cnId}, relativeTo: this.activatedRoute});
                });
            }
            this.router.navigate([], {queryParams: {sid: sc.id}, relativeTo: this.activatedRoute});
          } else {
            navigateToFirst();
          }
        } else if (this.selectedSection?.education) {
          this.router.navigate([], {queryParams: {sid: this.selectedSection.id}, relativeTo: this.activatedRoute});
        } else {
          navigateToFirst();
        }
      });
    this._sections$.next({loaded: LOAD_STATE.LOADED, value: this._educationSections});
  }

  private routerNavigateToTimeline() {
    const navigateToFirst = () => {
      const csl = this._timelineSections.filter(s => !s.isRoot && s.parentId === this.rootSection.id && !s.fixedSectionType)
        .sort(this.utils.comparator(Constants.ORDERINDEX));
      if (!isEmpty(csl)) {
        this.router.navigate([], {queryParams: {sid: csl[0].id}, relativeTo: this.activatedRoute});
      } else {
        const root = this._timelineSections.find(s => s.isRoot);
        this.router.navigate([], {queryParams: {sid: root.id}, relativeTo: this.activatedRoute});
        this.dataService.dispatchSetCurrentContent(null);
      }
    };
    if (this.selectedSection && !this.selectedSection.education) {
      return;
    }
    const state = cloneDeep(this._currentStateTimeline);
    this._planeList.pipe(takeUntil(this._unsubscribeAll), skip(1), take(1))
      .subscribe(() => {
        if (!isEmpty(this._followMeCurrentAction)) {
          const scId = this._followMeCurrentAction.action.sectionId;
          if (this._planeFullListContent[scId]) {
            const action = cloneDeep(this._followMeCurrentAction.action);
            this._sectionContentsLoaded.pipe(takeUntil(this._unsubscribeAll), skip(1), filter(it => it), take(1), delay(1))
              .subscribe(() => {
                this._followMeCurrentAction.service.externalFollowMeUserSubject.next({force: true, ...action});
              });
            this.router.navigate([], {queryParams: {sid: scId}, relativeTo: this.activatedRoute});
          } else {
            navigateToFirst();
          }
        } else if (!isEmpty(state)) {
          if (state?.section && this._planeFullListContent[state.section.id]) {
            const sc = state.section;
            if (state.content) {
              const cnId = state.content.id;
              this._sectionContentsLoaded.pipe(takeUntil(this._unsubscribeAll), skip(1), filter(it => it), take(1), delay(1))
                .subscribe(() => {
                  this.router.navigate([], {queryParams: {sid: sc.id, cid: cnId}, relativeTo: this.activatedRoute});
                });
            }
            this.router.navigate([], {queryParams: {sid: sc.id}, relativeTo: this.activatedRoute});
          } else {
            navigateToFirst();
          }
        } else if (!this.selectedSection?.education) {
          this.router.navigate([], {queryParams: {sid: this.selectedSection.id}, relativeTo: this.activatedRoute});
        } else {
          navigateToFirst();
        }
      });
    this._sections$.next({loaded: LOAD_STATE.LOADED, value: this._timelineSections});
  }

  /*
  * Emit only after all event data will be loaded
  * */
  get sections$(): Observable<SectionContent[]> {
    return this.eventDataLoaded$
      .pipe(
        filter(it => it),
        switchMap(() => {
          return this._sections$.pipe(filter(it => it.loaded === LOAD_STATE.LOADED), map(it => it.value));
        })
      );
  }

  /*
  * Emit after sections will be loaded
  * */
  get sections$$(): Observable<SectionContent[]> {
    return this._sections$.pipe(filter(it => it.loaded === LOAD_STATE.LOADED), map(it => it.value));
  }

  /**
   * sorted section array by orderIndex
   */
  get sections(): SectionContent[] {
    const filterLostSections = (objects: SectionContent[]) => {
      const list = objects ?? [];
      return list.filter(s => !s.parentId || !!list.find(p => p.id === s.parentId));
    };
    const object = this._sections$.getValue();
    return object.loaded === LOAD_STATE.LOADED ? filterLostSections(object.value) : null;
  }

  get rootSection(): SectionContent {
    const object = this._rootSection$$.getValue();
    return object.loaded === LOAD_STATE.LOADED ? object.value : null;
  }

  get rootSection$(): Observable<SectionContent> {
    return this._rootSection$$.pipe(filter(it => it.loaded === LOAD_STATE.LOADED), map(it => it.value));
  }

  getSection$(sectionId: string): Observable<SectionContent> {
    return this._sections$.pipe(
      filter(it => it.loaded === LOAD_STATE.LOADED),
      map(it => it.value.find(s => s.id === sectionId)),
      distinctUntilChanged((prev, curr) => prev && curr && prev.toJSONString() === curr.toJSONString())
    );
  }

  getSection(sectionId: string): SectionContent {
    if (this._sections$.getValue().loaded === LOAD_STATE.LOADED) {
      return this._sections$.getValue().value.find(s => s.id === sectionId);
    }
    return null;
  }

  getSourceSection(sectionId: string): SectionContent {
    if (!this._timelineSlotMode) {
      const tsList = this._planeListContentSortedWithoutFixedAsSectionArray || [];
      return this.timelineFilterService.getFilteredSectionsByDate(tsList, this.event,
          this._firstLevelTimePoints, this._manageTimeMap, false, this._childTreeInLineListCache, this.refreshDisplayTimeline, sectionId)
        .find(s => s.id === sectionId) || (this._educationSections || []).find(s => s.id === sectionId);
    } else {
      return (this._educationSections || []).find(s => s.id === sectionId) || (this._timelineSections || []).find(s => s.id === sectionId);
    }
  }

  get sectionsDependencyAnswers$(): Observable<IDependencyAnswers> {
    return this.eventDataLoaded$
      .pipe(
        filter(it => it),
        switchMap(() => {
          return this._sectionsDependencyAnswers$;
        })
      );
  }

  loadInstantSettings() {
    const event = this._event$.getValue().value;
    const eventId = this._event$.getValue().value.eventId;
    const sub = this.dataService.getInstantSettings(eventId)
      .subscribe((value: InstantSettings) => {
        this.autopilotService.autopilotSettings = value ? value.autopilot : null;
        this.autopilotService.feedbackPopUp = value ? value.feedbackRating.popUpAutomaticallyOneMinBeforeSectionEnd : null;
        const defaultSettings =
          new InstantSettings(event.slotMode && !value ?
            {slotModeSettings: new SlotModeSettings({assignFreeSlotMode: ASSIGN_FREE_SLOT_MODE.REFERENCE})} : null);
        this._instantSettings$.next({loaded: LOAD_STATE.LOADED, value: value || defaultSettings});
      });
    this.setSubscription(LOAD_DATA.INSTANT_SETTINGS, sub);
  }

  get instantSettings$(): Observable<InstantSettings> {
    return this._instantSettings$.pipe(filter(it => it.loaded === LOAD_STATE.LOADED), map(it => it.value));
  }

  get instantSettings(): InstantSettings {
    return this._instantSettings$.getValue().value;
  }

  loadRegistrationSettings() {
    const eventId = this._event$.getValue().value.eventId;
    const sub = this.dataService.getRegistrationSettingsCollection(eventId)
      .subscribe((value: RegistrationSettings[]) => {
        this._registrationSettings$.next({loaded: LOAD_STATE.LOADED, value: value || []});
      });
    this.setSubscription(LOAD_DATA.REGISTRATION_SETTINGS, sub);
  }

  get registrationSettings$(): Observable<RegistrationSettings[]> {
    return this._registrationSettings$.pipe(filter(it => it.loaded === LOAD_STATE.LOADED), map(it => it.value));
  }

  get registrationSettings(): RegistrationSettings[] {
    return this._registrationSettings$.getValue().value;
  }

  loadRegistrationCounters() {
    const eventId = this._event$.getValue().value.eventId;
    const sub = this.dataService.getRegistrationCounters(eventId)
      .subscribe((value: RegistrationSettings[]) => {
        this._registrationCounters$.next({loaded: LOAD_STATE.LOADED, value: value || []});
      });
    this.setSubscription(LOAD_DATA.REGISTRATION_COUNTERS, sub);
  }

  get registrationCounters$(): Observable<any[]> {
    return this._registrationCounters$.pipe(filter(it => it.loaded === LOAD_STATE.LOADED), map(it => it.value));
  }

  get registrationCounters(): any[] {
    return this._registrationCounters$.getValue().value;
  }

  get sectionMandatoryIds$() {
    return this._registrationSettings$.pipe(
      filter(it => it.loaded === LOAD_STATE.LOADED),
      map(it => it.value),
      distinctUntilChanged((prev, curr) => {
        const prevIds = (prev || []).filter(it => it.mandatoryForEventRegistration).map(it => it.id);
        const currIds = (curr || []).filter(it => it.mandatoryForEventRegistration).map(it => it.id);
        const first = currIds.some(id => !prevIds.includes(id));
        const second = prevIds.some(id => !currIds.includes(id));
        const third = (prev || []).some(p => {
          return (curr || []).find(c => c.id === p.id && c.mandatoryForEventRegistration !== p.mandatoryForEventRegistration);
        });
        return first || second || third;
      }),
      map(it => it.filter(v => v.mandatoryForEventRegistration).map(v => v.id))
    );
  }

  getRegistrationSettings(sectionId: string) {
    if (this._registrationSettings$.getValue().loaded === LOAD_STATE.LOADED) {
      return this._registrationSettings$.getValue().value.find(s => s.id === sectionId) ?? new RegistrationSettings();
    }
    return null;
  }

  loadWelcomeScreen() {
    const eventId = this._event$.getValue().value.eventId;
    const sub = this.dataService.getWelcomeScreen(eventId)
      .subscribe((value: WelcomeScreen) => {
        this._welcomeScreen$.next({loaded: LOAD_STATE.LOADED, value: value});
      });
    this.setSubscription(LOAD_DATA.WELCOME_SCREEN, sub);
  }

  get welcomeScreen$(): Observable<WelcomeScreen> {
    return this._welcomeScreen$.pipe(filter(it => it.loaded === LOAD_STATE.LOADED), map(it => it.value));
  }

  loadSectionTypesList() {
    const eventId = this._event$.getValue().value.eventId;
    const sub = this.dataService.getSectionTypes(eventId)
      .subscribe(list => {
        if (this.loginService.educationMode && isEmpty(list)) {
          let id = 0;
          list = Constants.EVENT_SECTION_TYPE_DEFAULT.map(t => new EventSectionType({
            id: (id += 10).toString(),
            name: this.utils.i18n('section.type.' + t.name),
            icon: t.icon}));
        }
        this.sectionTypeIconList = {};
        if (list) {
          list.forEach(item => {
            this.sectionTypeIconList[item['id']] = item['icon'];
          });
        }
        this._sectionTypesList$.next({loaded: LOAD_STATE.LOADED, value: list});
      });
    this.setSubscription(LOAD_DATA.SECTION_TYPES_LIST, sub);
  }

  get sectionTypesList$(): Observable<EventSectionType[]> {
    return this._sectionTypesList$.pipe(filter(it => it.loaded === LOAD_STATE.LOADED), map(it => it.value));
  }

  get sectionTypesList(): EventSectionType[] {
    return this._sectionTypesList$.getValue().value;
  }

  /*
  * User access to sections (private sections)
  * */
  loadUserData() {
    const eventId = this._event$.getValue().value.eventId;
    const sub = this.dataService.getUserData(eventId)
      .subscribe((value: IUserSectionsData) => {
        this._userData$.next({loaded: LOAD_STATE.LOADED, value: value});
      });
    this.setSubscription(LOAD_DATA.USER_DATA, sub);
  }

  get userData$(): Observable<IUserSectionsData> {
    return this._userData$.pipe(filter(it => it.loaded === LOAD_STATE.LOADED), map(it => it.value));
  }

  get userData(): IUserSectionsData {
    return this._userData$.getValue().value;
  }

  loadUserCount() {
    if (!this.isSuperUser(this.currentUser)) {
      return;
    }
    const eventId = this._event$.getValue().value.eventId;
    const sub = this.dataService.getUsersOnlineCount(eventId)
      .subscribe((value: number) => {
        this._userCount$.next({loaded: LOAD_STATE.LOADED, value: value});
      });
    this.setSubscription(LOAD_DATA.USER_COUNT, sub);
  }

  get userCount$(): Observable<number> {
    return this._userCount$.pipe(filter(it => it.loaded === LOAD_STATE.LOADED), map(it => it.value));
  }

  loadUsersOnlineShort() {
    if (this.otherSubscriptions['loadUsersOnlineShort']) {
      this.otherSubscriptions['loadUsersOnlineShort'].unsubscribe();
      this.otherSubscriptions['loadUsersOnlineShort'] = null;
    }
    this.otherSubscriptions['loadUsersOnlineShort'] =
    this.event$
      .pipe(
        filter(it => !!it),
        distinctUntilChanged((prev, curr) => {
          return prev.listUsersDialogMode === curr.listUsersDialogMode ||
            prev.isManager(this.currentUser.userId) === curr.isManager(this.currentUser.userId);
        })
      )
      .subscribe(event => {
        if (event.listUsersDialogMode === Constants.LIST_USERS_MODE_NOCLICK ||
          (event.listUsersDialogMode === Constants.LIST_USERS_MODE_SPEAKERS && !event.isManager(this.currentUser.userId))) {
          this.setSubscription(LOAD_DATA.USERS_ONLINE_SHORT, null);
          return;
        }
        if (this._usersOnlineShort$.getValue().loaded !== LOAD_STATE.NOT_LOADED) {
          return;
        }
        const sub = this.store.select(fromRoot.getUsersOnlineShort)
          .pipe(
            sample(interval(5000)),
            map(usersShort => {
              let usersShortListFiltered = [];
              if (!isEmpty(usersShort)) {
                const usersOnline = this.utils.objectValues(usersShort);
                usersShortListFiltered = usersOnline.filter(user => !TimeLineService.exceedOnlineInterval(user['pingTime']));
              }
              return usersShortListFiltered;
            })
          )
          .subscribe((value: IUsersOnlineShort[]) => {
            this._usersOnlineShort$.next({loaded: LOAD_STATE.LOADED, value: value});
          });
        this.setSubscription(LOAD_DATA.USERS_ONLINE_SHORT, sub);
      });
  }

  get usersOnlineShort$(): Observable<IUsersOnlineShort[]> {
    return this._usersOnlineShort$.pipe(filter(it => it.loaded === LOAD_STATE.LOADED), map(it => it.value));
  }

  get usersOnlineShort(): IUsersOnlineShort[] {
    return this._usersOnlineShort$.getValue().value;
  }

  getManageTimeService() {
    return this.manageTimeService;
  }

  get isSlotMode() {
    return this.timelineSlotMode.getValue();
  }

  get childTreeCache() {
    return this._childTreeInLineListCache;
  }

  get calcOrderIndexContentId(): string {
    return this._calcOrderIndexContentId;
  }

  set calcOrderIndexContentId(value: string) {
    this._calcOrderIndexContentId = value;
  }

  get planeList(): BehaviorSubject<IPlaneContentMap> {
    return this._planeList;
  }

  get planeListSubject(): Subject<number> {
    return this._planeListSubject;
  }

  get eventInstantSettings(): InstantSettings {
    return this.instantSettings;
  }

  get DEFAULT_LANGUAGE() {
    return (this.event?.defaultLanguage ?? Constants.TIMELINE_DEFAULT_LANGUAGE) as LANGUAGE;
  }

  get DEFAULT_LANGUAGE_PARAMS() {
    return {defaultLanguage: this.DEFAULT_LANGUAGE, currentLanguage: this.DEFAULT_LANGUAGE};
  }

  get featureLineContentId(): string {
    return this._featureLineContentId;
  }

  set featureLineContentId(value: string) {
    if ((this._featureLineContentId ?? null) !== (value ?? null)) {
      this._featureLineContentIdPrev = this._featureLineContentId;
      this._featureLineContentId = value;
      this._isSpeakerMode = !!this.getSectionWithSpeakersSelfOrParent(value);
      this.buildSectionRange(value, 'feature');
      this.featureLineContentId$.next(value);
    }
  }

  get featureLineContentIdPrev(): string {
    return this._featureLineContentIdPrev;
  }

  // used only in create wizard component.
  quietSetFeatureLineContentId(value: string) {
    this._featureLineContentId = value;
  }

  get selectedSection$() {
    return this._selectedSection$.asObservable();
  }

  get selectedSection(): SectionContent {
    return this._selectedSection$.getValue();
  }

  set selectedSection(value: SectionContent) {
    const isInSelfLearningSection = value && !value.isRoot &&
      !isEmpty(this.isSectionHierarchicalParentSelfLearning(value));
    const isSectionInSelfLearningClose = isInSelfLearningSection && value && !value.isRoot &&
      this.selfLearningService.isLearningClosed(value as SectionTimeline, this.hasPresenterOrSpeakerAccessRightsToSection(value), this);
    if (isInSelfLearningSection && isSectionInSelfLearningClose) {
      return;
    }
    this._selectedSection$.next(value);
    this.setCurrentTimelineState(value, 'section');
    this.buildSectionRange(value ? value.id : null, 'selected');
    this.loadSectionContents(value);
    this.selectedSectionChanged$.next(null);
  }

  setCurrentTimelineState(value: SectionContent | SectionTimeline | AbstractContent, nullType: 'section' | 'content') {
    if (value && value.isTypeSection) {
      if (!(value as SectionContent).education) {
        this._currentStateTimeline.section = value as SectionContent;
        this._currentStateTimeline.content = null;
      } else {
        this._currentStateEducation.section = value as SectionContent;
        this._currentStateEducation.content = null;
      }
    } else if (value && !value.isTypeSection) {
      if (this.selectedSection) {
        if (!this.selectedSection.education) {
          this._currentStateTimeline.content = value as AbstractContent;
        } else {
          this._currentStateEducation.content = value as AbstractContent;
        }
      } else {
        this._currentStateTimeline.content = null;
        this._currentStateEducation.content = null;
      }
    } else if (!value) {
      if (!this.timelineSlotMode.getValue()) {
        if (nullType === 'section') {
          this._currentStateTimeline = {};
        } else if (nullType === 'content') {
          this._currentStateTimeline.content = null;
        }
      } else {
        if (nullType === 'section') {
          this._currentStateEducation = {};
        } else if (nullType === 'content') {
          this._currentStateEducation.content = null;
        }
      }
    }
  }

  resetCurrentTimelineStateEducation() {
    this._currentStateEducation.section = null;
    this._currentStateEducation.content = null;
  }

  get changeSectionSubject(): BehaviorSubject<SectionContent> {
    return this._changeSectionSubject;
  }

  get selectedContent(): AbstractContent {
    return this._selectedContent;
  }

  set selectedContent(value: AbstractContent) {
    this._selectedContent = value;
    this.setCurrentTimelineState(value, 'content');
  }

  get refreshSectionSubject(): Subject<SectionContent> {
    return this._refreshSectionSubject;
  }

  get planeListContent(): IPlaneContentMap {
    return this._planeListContent;
  }

  set planeListContent(value: IPlaneContentMap) {
    this._planeListContent = value;
  }

  getPlaneListContentSorted(): PlaneContent[] {
    return this._planeListContentSorted;
  }

  getPlaneListContentSortedWithoutFixed(): PlaneContent[] {
    return this._planeListContentSortedWithoutFixed;
  }

  getPlaneListContentAsArray(): PlaneContent[] {
    return this._planeListContentAsArray;
  }

  getSectionContent(sectionId: string) {
    return this.planeListContent[sectionId] ? this.planeListContent[sectionId].sectionContent : null;
  }

  /**
   * find section in timeline sections and education sections
   * @param sectionId
   */
  findSection(sectionId: string) {
    let section = this._timelineSections.find(s => s.id === sectionId);
    if (!section) {
      section = this._educationSections.find(s => s.id === sectionId);
    }
    return section;
  }

  findSectionHierarchicalParents(section: SectionContent): string[] {
    const list = !section.education ? this._timelineSections : this._educationSections;
    const parents: string[] = [];
    let currentSection = section;
    while (currentSection.parentId) {
      parents.push(currentSection.parentId);
      currentSection = list.find(s => s.id === currentSection.parentId);
    }
    return parents;
  }

  /**
   * Check speaker access to content.
   * @param currentUser
   * @param {AbstractContent} content
   * @returns {boolean}
   */
  isSpeakerContent(currentUser: {userId: string}, content: AbstractContent | PlaneContent) {
    if (!content || !currentUser) {return false; }
    let localSection = this.planeFullListContent[!content.isTypeSection ? content.parentId : (content.id ? content.id : content.parentId)];
    if (!localSection && !content.isTypeSection) {
      const section = this.sections?.filter(s => s.isAttached()).find(s => s.getId() === content.parentId);
      if (section) {
        localSection = this.planeFullListContent[section.id];
      }
    }
    if (!localSection) {return false; }
    let isSectionPresenter = localSection.sectionContent.userAccessSpeaker(currentUser.userId);
    let parentId = localSection.trueParentItem ? localSection.trueParentItem.id : null;
    while (!isSectionPresenter && parentId && localSection) {
      localSection = this.planeFullListContent[parentId];
      if (localSection) {
        isSectionPresenter = localSection.sectionContent.userAccessSpeaker(currentUser.userId);
        parentId = localSection.sectionContent.parentId;
      }
    }
    return isSectionPresenter;
  }

  /**
   * Check speaker access to content.
   * @param userId
   * @param sectionId
   * @returns {boolean}
   */
  isSectionSpeaker(userId, sectionId: string): boolean {
    if (!sectionId) {
      return false;
    }
    const content = this.getSectionContent(sectionId);
    if (!content || !userId) {return false; }

    let localSection = this.planeListContent[!content.isTypeSection ? content.parentId : (content.id ? content.id : content.parentId)];
    if (!localSection) {return false; }
    let isSectionPresenter = localSection.sectionContent.userAccessSpeaker(userId);
    let parentId = localSection.trueParentItem ? localSection.trueParentItem.id : null;
    while (!isSectionPresenter && parentId && localSection) {
      localSection = this.planeListContent[parentId];
      if (localSection) {
        isSectionPresenter = localSection.sectionContent.userAccessSpeaker(userId);
        parentId = localSection.sectionContent.parentId;
      }
    }
    return isSectionPresenter;
  }

  /**
   * Check user speaker to section excluding this section from the access search.
   * @param {AppUser} currentUser
   * @param {SectionTimeline} section
   * @returns {boolean}
   */
  isSpeakerSectionExcludeItself(currentUser: ConferenceUser | AppUser, section: SectionTimeline) {
    if (!section) {return false; }
    let localSection = this.planeFullListContent[section.parentId];
    if (!localSection) {return false; }
    let isSectionPresenter = localSection.sectionContent.userAccessSpeaker(currentUser.userId);
    let parentId = localSection.trueParentItem ? localSection.trueParentItem.id : null;
    while (!isSectionPresenter && parentId) {
      localSection = this.planeFullListContent[parentId];
      isSectionPresenter = localSection.sectionContent.userAccessSpeaker(currentUser.userId);
      parentId = localSection.sectionContent.parentId;
    }
    return isSectionPresenter;
  }

  /**
   * get speaker access section up from this section.
   * @param userId
   * @param {SectionTimeline} section
   * @returns {boolean}
   */
  getUpAccessSpeakerSectionIdFromItself(userId: string, section: SectionTimeline) {
    if (!section) {return false; }
    let localSection = this.planeFullListContent[section.id];
    if (!localSection) {return false; }
    let isSectionPresenter = localSection.sectionContent.userAccessSpeaker(userId);
    let parentId = localSection.trueParentItem ? localSection.trueParentItem.id : null;
    while (!isSectionPresenter && parentId) {
      localSection = this.planeFullListContent[parentId];
      isSectionPresenter = localSection.sectionContent.userAccessSpeaker(userId);
      parentId = localSection.sectionContent.parentId;
    }
    return isSectionPresenter ? localSection.id : null;
  }

  /**
   * Check user attendees to section excluding this section from the access search.
   * @param {AppUser} currentUser
   * @param {SectionTimeline} section
   * @returns {boolean}
   */
  isAttendeeSectionExcludeItself(
    currentUser: ConferenceUser, section: SectionTimeline, sectionUserData: string[] = []
  ) {
    if (!section) {return false; }
    let localSection = this.planeFullListContent[section.parentId];
    if (!localSection) {return false; }
    let isSectionAttendee = sectionUserData.includes(localSection.id);
    let parentId = localSection.trueParentItem ? localSection.trueParentItem.id : null;
    while (!isSectionAttendee && parentId) {
      localSection = this.planeFullListContent[parentId];
      isSectionAttendee = sectionUserData.includes(localSection.id);
      parentId = localSection.sectionContent.parentId;
    }
    return isSectionAttendee;
  }

  /**
   * Check has access to section.
   * @param {AppUser} currentUser
   * @param {AbstractContent} content
   * @returns {boolean}
   */
  // todo we can't check access by .sectionContent.hasUserAccess, we should to use user_data
  hasAccessContent(currentUser: AppUser | ConferenceUser, content: AbstractContent): boolean {
    if (!content) {return null; }
    let localSection = this.planeFullListContent[
      content.type !== Constants.CONTENT_TYPE_TIMELINE && content.type !== Constants.CONTENT_TYPE_SECTION ?
        content.parentId : content.id];
    let hasAccess = localSection.sectionContent.hasUserAccess(currentUser.userId);
    let parentId = localSection.trueParentItem ? localSection.trueParentItem.id : null;
    while (hasAccess && parentId) {
      localSection = this.planeFullListContent[parentId];
      hasAccess = localSection.sectionContent.hasUserAccess(currentUser.userId);
      parentId = localSection.sectionContent.parentId;
    }
    return hasAccess;
  }

  /**
   * Check attendee access to section.
   * @param {AppUser} currentUser
   * @param {AbstractContent} content
   * @param sectionUserData
   * @returns {boolean}
   */
  isAttendeeContent(
    currentUser: ConferenceUser | AppUser, content: AbstractContent, sectionUserData: string[] = []
  ) {
    if (!content) {return false; }
    let localSection = this.planeFullListContent[!content.isTypeSection ? content.parentId : (content.id ? content.id : content.parentId)];
    if (!localSection) {return false; }
    let isSectionAttendee = sectionUserData.includes(localSection.id);
    let parentId = localSection.trueParentItem ? localSection.trueParentItem.id : null;
    while (!isSectionAttendee && parentId) {
      localSection = this.planeFullListContent[parentId];
      isSectionAttendee = localSection ? sectionUserData.includes(localSection.id) : null;
      parentId = localSection ? localSection.sectionContent.parentId : null;
    }
    return isSectionAttendee;
  }

  /**
   * check curreent user access to any content
   * @param content
   */
  hasAccess(content: AbstractContent) {
    if (this.conferenceUser && this.conferenceUser.isViewer) {return false; }
    if (this.isPresenter) {return true; }
    return this.isSpeakerContent(this.currentUser, content);
  }

  /**
   * get sorted SectionTimeline array
   */
  getPlaneListContentAsSectionArray(): SectionTimeline[] {
    return this._planeListContentAsSectionArray;
  }

  /**
   * get sorted SectionTimeline array without fixed section
   */
  planeListContentSortedWithoutFixedAsSectionArray(): SectionTimeline[] {
    return this._planeListContentSortedWithoutFixedAsSectionArray;
  }

  /*
  * Full list of sections with hierarchy
  * */
  get planeFullListContent(): IPlaneContentMap {
    return this._planeFullListContent;
  }

  /**
   * all linked contents without fixed section
   */
  get planeFullListContentWithoutFixed(): IPlaneContentMap {
    return this._planeFullListContentWithoutFixed;
  }

  getPlaneFullListContentAsSortedArray(): PlaneContent[] {
    return this._planeFullListContentAsSortedArray;
  }

  getPlaneFullListContentWithoutFixedAsSortedArray(): PlaneContent[] {
    return this._planeFullListContentWithoutFixedAsSortedArray;
  }

  get availableSectionIdList(): {} {
    return this._availableSectionIdList;
  }

  set availableSectionIdList(value: {}) {
    this._availableSectionIdList = value;
  }

  get instantContents(): SectionTimeline[] {
    return this.instantContents$.getValue();
  }

  set instantContents(value: SectionTimeline[]) {
    this.instantContents$.next(value);
  }

  get presentationMode(): boolean {
    return this._presentationMode;
  }

  set presentationMode(value: boolean) {
    this._presentationMode = !!value;
  }

  get currentContent(): BehaviorSubject<CurrentContentMessage> {
    return this._currentContent;
  }

  get addContentSubject(): Subject<IAddContentSubject> {
    return this._addContentSubject;
  }

  get openPreviewGallery(): Subject<boolean> {
    return this._openPreviewGallery;
  }

  get timelineEvent(): Event {
    return this.event;
  }

  get eventNoTimingSections(): boolean {
    return this._eventNoTimingSections;
  }

  get fixedSections(): { [id: string]: SectionContent } {
    return this._fixedSections;
  }

  set fixedSections(value: { [id: string]: SectionContent }) {
    this._fixedSections = value;
  }

  get showTooltipOnMobile(): boolean {
    return this._showTooltipOnMobile;
  }

  set showTooltipOnMobile(value: boolean) {
    this._showTooltipOnMobile = value;
  }

  get selectedSectionUserRegistered(): boolean {
    return (this.selectedSection && !this.selectedSection.requiredRegistration) || this._selectedSectionUserRegistered;
  }

  set selectedSectionUserRegistered(value: boolean) {
    this._selectedSectionUserRegistered = value;
  }

  get mangeTimeMap(): { [p: string]: TimePoint } {
    return this._manageTimeMap;
  }

  get refreshMangeTimeMap(): Subject<boolean> {
    return this._refreshMangeTimeMap;
  }

  get firstLevelTimePoints(): LevelTimePoints {
    return this._firstLevelTimePoints;
  }

  get calculateManageTime(): BehaviorSubject<number> {
    return this._calculateManageTime;
  }

  get completeUserSectionRegistration(): string[] {
    return isEmpty(this.completeUserSectionRegistration$.getValue()) ? [] : this.completeUserSectionRegistration$.getValue();
  }

  set completeUserSectionRegistration(value: string[]) {
    this.completeUserSectionRegistration$.next(value);
  }

  get notCompleteUserSectionRegistration(): string[] {
    return isEmpty(this._notCompleteUserSectionRegistration) ? [] : this._notCompleteUserSectionRegistration;
  }

  set notCompleteUserSectionRegistration(value: string[]) {
    this._notCompleteUserSectionRegistration = value;
  }

  get refreshDisplayTimeline(): Subject<boolean> {
    return this._refreshDisplayTimeline;
  }

  get updateMangeTimeObjectsBlocked(): boolean {
    return this._updateMangeTimeObjectsBlocked;
  }

  set updateMangeTimeObjectsBlocked(value: boolean) {
    this._updateMangeTimeObjectsBlocked = value;
    if (!value) {
      this.calculateManageTime.next(new Date().getTime());
    }
  }

  get calculateMangeTimeObjectsBlocked(): boolean {
    return this._calculateMangeTimeObjectsBlocked;
  }

  set calculateMangeTimeObjectsBlocked(value: boolean) {
    this._calculateMangeTimeObjectsBlocked = value;
    if (!value) {
      this.calculateManageTime.next(new Date().getTime());
    }
  }

  setCompanyName(value: string) {
    this._companyName = value;
  }

  i18DomainCaptions(domain) {
    this._everybody_at_with_the_link_i18 = {editDialog: '', viewEventVisibility: '', eventFilterStatus: ''};
    this._everybody_at_with_the_link_i18.editDialog = this.utils.i18n('edit_dialog.checkbox.event.public.by.link', {domain: domain});
    this._everybody_at_with_the_link_i18.eventFilterStatus = this.utils.i18n('event.filter.status.public.by.link', {domain: domain});
    this._everybody_at_with_the_link_i18.viewEventVisibility = this.utils.i18n('view_event.visibility.public.by.link', {domain: domain});
    this._on_dashboard_i18 = {editDialog: '', viewEventVisibility: '', eventFilterStatus: ''};
    this._on_dashboard_i18.editDialog = this.utils.i18n('edit_dialog.checkbox.event.public.on.dashboard', {domain: domain});
    this._on_dashboard_i18.eventFilterStatus = this.utils.i18n('event.filter.status.public', {domain: domain});
    this._on_dashboard_i18.viewEventVisibility = this.utils.i18n('view_event.visibility.public', {domain: domain});
  }

  get everybody_at_with_the_link_i18() {
    return this._everybody_at_with_the_link_i18;
  }

  get on_dashboard_i18() {
    return this._on_dashboard_i18;
  }

  get companyName(): string {
    return this._companyName;
  }

  get pushRefreshUserEventSubject(): BehaviorSubject<number> {
    return this._pushRefreshUserEventSubject;
  }

  get eventWithoutSelfLearning(): boolean {
    return this._eventWithoutSelfLearning;
  }

  get currentQuestionnaireOpenLastQuestion(): Subject<boolean> {
    return this._currentQuestionnaireOpenLastQuestion;
  }

  get currentQuestionnaireOpenTextQuestion(): Subject<boolean> {
    return this._currentQuestionnaireOpenTextQuestion;
  }

  get showRegistrationQuestionnaireForPresenter(): Subject<IShowPresenterQuestionnaireTask> {
    return this._showRegistrationQuestionnaireForPresenter;
  }

  get permitRocheVideo(): boolean {
    return this._permitRocheVideo;
  }

  // todo required rename to isManager
  get isPresenter(): boolean {
    const appUser = this.loginService.getAppUser();
    return this.event && this.event.isManager(appUser?.userId);
  }

  get isConcierge(): boolean {
    const appUser = this.loginService.getAppUser();
    return this.event && this.event.isConcierge(appUser?.userId);
  }

  get isOwner(): boolean {
    const appUser = this.loginService.getAppUser();
    return this.event && this.event.isOwner(appUser?.userId);
  }

  get currentTimeInDB(): boolean {
    return this._currentTimeInDB;
  }

  set currentTimeInDB(value: boolean) {
    this._currentTimeInDB = value;
  }

  setPermitRocheVideo(value: boolean) {
    this._permitRocheVideo = value;
  }

  get currentQuestionnaireChangeQuestion(): Subject<boolean> {
    return this._currentQuestionnaireChangeQuestion;
  }

  get openSectionRegistrationDialogFromContainer(): boolean {
    return this._openSectionRegistrationDialogFromContainer;
  }

  set openSectionRegistrationDialogFromContainer(value: boolean) {
    this._openSectionRegistrationDialogFromContainer = value;
  }

  get sectionsByExclusivityGroup(): { [p: string]: string[] } {
    return this._sectionsByExclusivityGroup;
  }

  set sectionsByExclusivityGroup(value: { [p: string]: string[] }) {
    this._sectionsByExclusivityGroup = value;
  }

  get joinSectionSubject(): BehaviorSubject<IJoinSectionSubject> {
    return this._joinSectionSubject;
  }

  get sectionChildContentsListSubject(): BehaviorSubject<ISectionChildContents> {
    return this._sectionChildContentsListSubject;
  }

  get sectionContentsObjects(): ISectionChildContents {
    return this._sectionChildContentsListSubject.getValue();
  }

  get mobileModeSubject(): Subject<any> {
    return this._mobileModeSubject;
  }

  get conferenceUserJoinedSections(): IUserJoinedSection {
    return this._conferenceUserJoinedSections;
  }

  get conferenceUserContainerSections(): IUserContainerSection {
    return this._conferenceUserContainerSections;
  }

  get currentUser(): AppUser {
    return this.currentUser$.getValue();
  }

  set currentUser(value: AppUser) {
    this.currentUser$.next(value);
    this.textMacrosParserService.initUserParserDictionary(value);
  }

  get userInitiatorEventMeeting(): AppUser {
    return this._userInitiatorEventMeeting;
  }

  set userInitiatorEventMeeting(value: AppUser) {
    this._userInitiatorEventMeeting = value;
  }

  get onlyRoot(): boolean {
    return this._onlyRoot;
  }

  get isSpeakerMode(): boolean {
    return this._isSpeakerMode;
  }

  set isSpeakerMode(value: boolean) {
    this._isSpeakerMode = value;
  }

  get mainSpeakerSection(): SectionContent {
    return this._mainSpeakerSection;
  }

  set mainSpeakerSection(value: SectionContent) {
    this._mainSpeakerSection = value;
    this._mainSpeakerSection$.next(value);
  }

  get mainSpeakerSection$(): BehaviorSubject<SectionContent> {
    return this._mainSpeakerSection$;
  }

  get dependencyAnswers$(): BehaviorSubject<IDependencyAnswers> {
    return this._dependencyAnswers$;
  }

  get timelineChanged$(): BehaviorSubject<number> {
    return this._timelineChanged$;
  }

  get refreshDisplayTimeline$(): BehaviorSubject<number> {
    return this._refreshDisplayTimeline$;
  }

  get sectionBeforeRunningStatus(): { [p: string]: string } {
    return this._sectionBeforeRunningStatus;
  }

  set sectionBeforeRunningStatus(value: { [p: string]: string }) {
    this._sectionBeforeRunningStatus = value;
  }

  get followMeEventPGSParticipant(): BehaviorSubject<boolean> {
    return this._followMeEventPGSParticipant;
  }

  get followMeSectionPGSParticipant(): BehaviorSubject<{[sectionId: string]: boolean}> {
    return this._followMeSectionPGSParticipant;
  }

  get followMeEventAsPresenter(): BehaviorSubject<boolean> {
    return this._followMeEventAsPresenter;
  }

  get followMeSectionAsPresenter(): BehaviorSubject<{ [p: string]: boolean }> {
    return this._followMeSectionAsPresenter;
  }

  get sectionContentsLoaded(): BehaviorSubject<boolean> {
    return this._sectionContentsLoaded;
  }

  get timelineSpeakers(): {} {
    return this._timelineSpeakers;
  }

  get selectedQASection(): BehaviorSubject<SectionContent> {
    return this._selectedQASection;
  }

  get qaContent(): BehaviorSubject<AbstractContent> {
    return this._qaContent;
  }

  get timelineSectionContentsList(): BehaviorSubject<AbstractContent[]> {
    return this._timelineSectionContentsList;
  }

  getConferenceUserPromise(userId: string): Promise<AppUser> {
    const value = this._conferenceUsers.getValue();
    const find = value.find(it => it.userId === userId);
    if (!find) {
      return this.dataService.getUserCard(this.timelineEvent.eventId, userId)
        .then(user => {
          value.push(user);
          this._conferenceUsers.next(value);
          return user;
        });
    }
    return Promise.resolve(find);
  }

  get showQADialog(): BehaviorSubject<boolean> {
    return this._showQADialog;
  }

  get qaSecondParentId(): string {
    return this._qaSecondParentId;
  }

  set qaSecondParentId(value: string) {
    this._qaSecondParentId = value;
    this.qaSecondParentIdChange$.next(value);
  }

  get sectionsWithSpecificQAList(): { id: string; title: string }[] {
    return this._sectionsWithSpecificQAList;
  }

  set sectionsWithSpecificQAList(value: { id: string; title: string }[]) {
    this._sectionsWithSpecificQAList = value;
  }


  get followMePresenterUser(): AppUser {
    return this._followMePresenterUser;
  }

  set followMePresenterUser(value: AppUser) {
    this._followMePresenterUser = value;
    this._followMePresenterUser$.next(value);
  }

  get followMePresenterUser$(): BehaviorSubject<AppUser> {
    return this._followMePresenterUser$;
  }

  get followMeSpeakerUser(): AppUser {
    return this._followMeSpeakerUser;
  }

  set followMeSpeakerUser(value: AppUser) {
    this._followMeSpeakerUser = value;
  }

  get prevContent(): AbstractContent {
    return this._prevContent;
  }

  set prevContent(value: AbstractContent) {
    this._prevContent = value;
  }

  get timelineSections(): SectionContent[] {
    return (this._timelineSections || []).filter(s => !s.fixedSectionType);
  }

  get educationSections(): SectionContent[] {
    return (this.educationSections$.getValue() || []).filter(s => !s.fixedSectionType);
  }

  private get _educationSections() {
    return this.educationSections$.getValue();
  }

  private get _timelineSections() {
    return this.timelineSections$.getValue();
  }

  get followMeCurrentAction(): IFollowMeCurrentAction {
    return this._followMeCurrentAction;
  }

  set followMeCurrentAction(value: IFollowMeCurrentAction) {
    this._followMeCurrentAction = value;
  }

  isSocialShow() {
    return this.utils.getEnv()['enableSocialPresentation'] &&
      this.eventInstantSettings.socialSettings && this.eventInstantSettings.socialSettings.show;
  }

  private deepFreeze(obj) {
    const vm = this;
    const propNames = Object.getOwnPropertyNames(obj);
    propNames.forEach(function(name) {
      const prop = obj[name];
      if (typeof prop === 'object' && prop !== null) {
        vm.deepFreeze(prop);
      }
    });
    return Object.freeze(obj);
  }

  init(eventId: string, currentUser: AppUser, options: IInitEventOptions = {} ) {
    if (!this.currentUser) {
      this.currentUser = currentUser;
    }
    this.resetServiceValues();
    const o = eventId.length > 4 ? this.dataService.getEvent(eventId) : this.dataService.getEventByLink(eventId);
    o.pipe(
      switchMap(event => {
        if (options.initManageTime) {
          this.initManageTimeDBUpdate(currentUser, event.isManager(currentUser.userId));
        }
        const regRequired = !(this.doNotCheckRegistrationForCurrentUser(currentUser) || currentUser.guest);
        const eventRegRequired = event.registrationRequiredMode === EVENT_REGISTRATION_MODE.REGISTRATION;
        return combineLatest([
          of(event),
          this.sections$.pipe(map(obj => ({counter: (new Date()).getTime(), sections: obj}))),
          regRequired ? this.userRegistration$ : of({}),
          regRequired && eventRegRequired ? this.sectionMandatoryIds$ : of([])
        ]).pipe(takeUntil(this._unsubscribeAll));
      }),
      takeUntil(this._unsubscribeAll)
    ).subscribe(([event, vSectionsObject, vRegisteredSections, vMandatoryList]) => {
      const registeredSections = [];
      Object.keys(vRegisteredSections).forEach(key => {
        registeredSections.push({sectionId: key, status: vRegisteredSections[key].status});
      });
      if (vSectionsObject.counter !== this._timelineUpdateCounter || isEmpty(this.planeListContent)) {
        this._timelineUpdateCounter = vSectionsObject.counter;
        this.refreshTimeline(options.registrationMode);
        if (!options.initManageTime) {
          this.planeList.next(this.planeListContent);
          this.planeListSubject.next((new Date()).getTime());
        }
      }
      if (!isEmpty(this.planeListContent)) {
        this.initUserRegistration(registeredSections);
      }
    });
  }

  private initManageTimeDBUpdate(currentUser, isPresenter) {
    if (!this.timelineEvent) {
      this.calculateManageTime.pipe(takeUntil(this._unsubscribeAll), debounceTime(800)).subscribe(value => {
        if (!isEmpty(value) && currentUser) {
          this.calcEventMangeTimeMap(currentUser, isPresenter);
        }
      });
    }
  }

  private initUserRegistration(vRegisteredSections) {
    this.completeUserSectionRegistration = (vRegisteredSections ?? [])
      .filter(obj => Constants.REGISTER_STATUS[obj.status])
      .map(fobj => fobj.sectionId);
    this.initSectionsWithSpecificQAList();
  }

  public resetServiceValues() {
    this._unsubscribeAll.next(true);
    this._unsubscribeAll.complete();
    this._unsubscribeAll = new Subject();
    if (this._unsubscribeSectionContents) {
      this._unsubscribeSectionContents.next(true);
      this._unsubscribeSectionContents.complete();
      this._unsubscribeSectionContents = new Subject();
    }
    this.debugService.resetServiceValues();
    this.autopilotService.resetServiceValues();
    this.currentUser$ = new BehaviorSubject<AppUser>(null);
    this._currentStateEducation = {};
    this._currentStateTimeline = {};
    this._followMeCurrentAction = null;
    this._calcOrderIndexContentId = undefined;
    this._featureLineContentId = undefined;
    this.featureLineContentId$ = new BehaviorSubject<string>(null);
    this._planeListContent = {}; // for build display tree base on user rights and access
    this._planeFullListContent = {}; // for build orderIndex contains all linked contents
    this._availableSectionIdList = {};
    this.instantContents$ = new BehaviorSubject<SectionTimeline[]>([]);
    this.featureLineContentRange = {};
    this.selectedLineContentRange = {};
    this.selectedSection = null;
    this._planeFullListContentWithoutFixed = {};
    this._planeFullListContentWithoutFixedAsSortedArray = [];
    this._planeList.next({});
    this._presentationMode = false;
    this._planeListContentSorted = [];
    this._planeListContentSortedWithoutFixed = [];
    this._planeListContentSortedWithoutFixedAsSectionArray = [];
    this._planeListContentAsArray = [];
    this._planeListContentAsSectionArray = [];
    this._planeFullListContentAsSortedArray = [];

    this._currentContent.next({});
    this._sectionsAutoCounterValues = {};
    this._childTreeInLineListCache = {};
    this._eventNoTimingSections = false;
    this._fixedSections = {};
    this._showTooltipOnMobile = true;
    this._selectedSectionUserRegistered = true;
    this._manageTimeMap = {};
    this._firstLevelTimePoints = new LevelTimePoints();
    this.completeUserSectionRegistration$ = new BehaviorSubject<string[]>([]);
    this._updateMangeTimeObjectsBlocked = false;
    this._pushRefreshUserEventSubject = new BehaviorSubject<number>(null);
    this._eventWithoutSelfLearning = false;
    this._openSectionRegistrationDialogFromContainer = false;
    this._sectionsByExclusivityGroup = {};
    this._joinSectionSubject.next(null);
    this._timelineUpdateCounter = 0;
    this._changeSectionSubject.next(null);
    this._sectionChildContentsListSubject = new BehaviorSubject<ISectionChildContents>(null);
    this._onlyRoot = true;
    this._isSpeakerMode = false;
    this._dependencyAnswers$ = new BehaviorSubject<IDependencyAnswers>(null);
    this._timelineChanged$ = new BehaviorSubject<number>(null);
    this.featureLineSectionExpand$ = new BehaviorSubject<string>(null);
    this.timelineTreeChildChanged$ = new BehaviorSubject<string>(null);
    this._refreshDisplayTimeline$ = new BehaviorSubject<number>(null);
    this._sectionBeforeRunningStatus = {};
    this._showQADialog.next(null);
    this._followMeEventPGSParticipant.next(false);
    this._followMeSectionPGSParticipant.next(null);
    this._selectedQASection.next(null);
    this._qaContent.next(null);
    this._timelineSectionContentsList = new BehaviorSubject<AbstractContent[]>(null);
    this._userInitiatorEventMeeting = null;
    this._prevContent = null;
    this._timelineSlotMode = false;
    this.timelineSlotMode.next(false);
    this.module$.next(null);
    this.expandContentContainer$.next(null);
    this.expandContentContainerItem$.next(null);
    this.expandFollowMeContentContainerItem$.next(null);
    this.conferenceUserManagerMode$.next({isFollowMeManager: false, managerUsedParticipantMode: false});
    this.sectionTypeIconList = {};
    this._sectionsDependencyAnswers$ = new BehaviorSubject<IDependencyAnswers>(null);
    this._sectionsQuestionnaireDependency$ = new BehaviorSubject<IDependencyQuestionnaire[]>([]);
    this.timelineShort$ = new BehaviorSubject<any>(null);
    this.timelineFilterService.resetServiceValues();
  }

  /**
   * Refresh timeline lists without emit events
   * @param registrationMode
   */
  refreshTimeline(registrationMode = false) {
    const currentUser = this.loginService.getAppUser();
    const isPresenter = this.isSuperUser(currentUser);
    const list = this.sections;
    this._childTreeInLineListCache = {};
    this._onlyRoot = true;
    if (list) {
      this._planeListContent = {};
      this._planeFullListContent = {};
      this._availableSectionIdList = {};
      let contents = [];
      const srcContents = [];
      if (list.length > 0) {
        for (const item of list) {
          if (item.fixedSectionType) {
            this.fixedSections[item.id] = new SectionTimeline(item);
          }
          if (item.fixedSectionType && !this.instantSettings.modeEasy()) {
            continue;
          }
          contents.push(new SectionTimeline(item));
          srcContents.push(new SectionTimeline(item));
        }

        // Fill not filtered planeFullListContent
        srcContents.forEach((content) => {
          this.linkContentToParent(content, srcContents);
        });

        srcContents.forEach((content) => {
          this.linkFullPlaneContent(content, srcContents, this._planeFullListContent);
        });
        // --------------------------------------

        contents = this.filterContents(contents, currentUser, isPresenter,
          this._planeFullListContent, this._availableSectionIdList);

        contents.forEach((content) => {
          this.linkContentToParent(content, contents);
        });

        contents.forEach((content) => {
          this.linkPlaneContent(content, contents, this._planeListContent);
        });
      }
      this.updateAdditionalFields();
      this._planeListContentSorted = this.utils.objectValues(this.planeListContent).sort(this.utils.comparator(Constants.ORDERINDEX));
      this._planeListContentSortedWithoutFixed = this._planeListContentSorted.filter(obj => !obj.sectionContent.fixedSectionType);
      this._planeListContentSortedWithoutFixedAsSectionArray = this._planeListContentSortedWithoutFixed.map(o => o.sectionContent);
      this._onlyRoot = this._planeListContentSortedWithoutFixedAsSectionArray.filter(s => !s.isRoot).length === 0;
      this._planeListContentAsArray = this.utils.objectValues(this.planeListContent);
      this._planeListContentAsSectionArray = this.utils.objectValues(this.planeListContent).map(obj => obj.sectionContent)
        .sort(this.utils.comparator(Constants.ORDERINDEX));
      this._planeFullListContentAsSortedArray = this.utils.objectValues(this.planeFullListContent)
        .sort(this.utils.comparator(Constants.ORDERINDEX));
      this._planeFullListContentWithoutFixedAsSortedArray =
        this._planeFullListContentAsSortedArray.filter(obj => !obj.sectionContent.fixedSectionType)
          .sort(this.utils.comparator(Constants.ORDERINDEX));
      this._sectionsByExclusivityGroup = this._planeListContentAsSectionArray.reduce((vAccum, vSection: SectionContent) => {
        if (vSection.exclusivityGroup) {
          if (!vAccum[vSection.exclusivityGroup]) {
            vAccum[vSection.exclusivityGroup] = [];
          }
          vAccum[vSection.exclusivityGroup].push(vSection.id);
        }
        return vAccum;
      }, {});
      this._planeFullListContentWithoutFixed = Object.keys(this.planeFullListContent).reduce((value, key) => {
        if (!this._planeFullListContent[key].sectionContent.fixedSectionType) {
          value[key] = this._planeFullListContent[key];
          return value;
        } else {
          return value;
        }
      }, {});
      if (!registrationMode && !this.loginService.educationMode) {
        this.buildSectionRange(this.featureLineContentId ? this.featureLineContentId : null, 'feature');
        this.buildSectionRange(this.selectedSection ? this.selectedSection.id : null, 'selected');
        this.calculateSectionsAutoCounterValues();
        this.createSectionsSubjectsList();
        if (!this.timelineSlotMode.getValue()) {
          this.calculateManageTime.next(new Date().getTime());
        }
        this.initFeedbackRatingServices();
        this.initSectionsWithSpecificQAList();
      }
      if (!this.loginService.educationMode) {
        this.fillTimeLineSpeakers();
        this.setJoinedUserSections(this.conferenceUser);
      }
      this.textMacrosParserService.initSectionParserDictionaryList(this._planeListContentSortedWithoutFixedAsSectionArray,
        this.getEventLanguageParams());
      this.initLearningSectionsOrder(this.event.isManager(currentUser.userId));
      if (this.loginService.educationMode || this.timelineSlotMode.getValue()) {
        this.planeList.next(this.planeFullListContent);
      }
    }
  }

  public linkPlaneContent(linkedContend, list: any[], planeListContent: IPlaneContentMap) {
    const parentItem = list.find( function (item) {
      if (linkedContend.hasOwnProperty('parentId') && item.id === linkedContend.parentId) {
        return true;
      }
    });
    planeListContent[linkedContend.id] = new PlaneContent(linkedContend, parentItem);
    if (planeListContent[linkedContend.id].headLevel) {
      const level0Items = [];
      list.forEach(function (item) {
        if (!item['parentId']) {
          level0Items.push(item);
        }
      });
      level0Items.sort(this.utils.comparator(Constants.ORDERINDEX));
    }
  }

  public linkFullPlaneContent(linkedContend, list: SectionTimeline[], planeFullListContent: IPlaneContentMap) {
    const parentItem = list.find( function (item) {
      if (linkedContend.hasOwnProperty('parentId') && item.id === linkedContend.parentId) {
        return true;
      }
    });
    planeFullListContent[linkedContend.id] = new PlaneContent(linkedContend, parentItem);
  }

  public linkContentToParent(content, list: any[]) {
    const parentId = content['parentId'];
    let parent = null;
    if (parentId) {
      parent = list.find(function(item) {
        if (item.id === parentId) {
          return true;
        }
      });
    }
    if (parentId && parent) {
      parent['items'].push(content);
      parent['items'].sort(this.utils.comparator(Constants.ORDERINDEX));
    } else if (content.type !== Constants.CONTENT_TYPE_TIMELINE) {
      const lastHeadContent = this.getActiveTimeLineSection(list);
      if (lastHeadContent) {
        content['parentId'] = lastHeadContent['id'];
        lastHeadContent['items'].push(content);
        lastHeadContent['items'].sort(this.utils.comparator(Constants.ORDERINDEX));
      }
    }
  }

  private getActiveTimeLineSection(list: any[]) {
    const vm = this;
    if (this._featureLineContentId) {
      const tlObj = list.find(function (item) {
        if (item.id === vm._featureLineContentId) {
          return true;
        }
      });
      if (tlObj) {
        if (tlObj['type'] === Constants.CONTENT_TYPE_TIMELINE) {
          return tlObj;
        } else {
          if (tlObj['parentId']) {
            const parent = list.find(function (item) {
              if (item.id === tlObj['parentId']) {
                return true;
              }
            });
            return parent;
          } else {
            return this.lastHeadSection(list);
          }
        }
      }
    } else {
      return this.lastHeadSection(list);
    }
    return null;
  }

  private lastHeadSection(list: any[]) {
    const headLines = list.filter(function (item) {
      if (!item['parentId'] && item['type'] === Constants.CONTENT_TYPE_TIMELINE) {
        return item;
      }
    });
    headLines.sort(this.utils.comparator(Constants.ORDERINDEX, 'desc'));
    if (headLines && headLines.length > 0) {
      return headLines[0];
    } else {
      return null;
    }
  }

  loadSectionsDependency(list: SectionContent[], listContentsShort: IContentsShort) {
    const dependencyList = this.dataService.createContentsDependencyList(list);
    if (!isEmpty(dependencyList)) {
      if (!isEmpty(listContentsShort)) {
        for (const sectionId of Object.keys(listContentsShort)) {
          for (const contentId of Object.keys(listContentsShort[sectionId])) {
            if (listContentsShort[sectionId][contentId] === Constants.CONTENT_TYPE_QUESTIONNAIRE ||
              listContentsShort[sectionId][contentId] === Constants.CONTENT_TYPE_CONTENT_CONTAINER) {
              const dep = dependencyList.find(dp => !dp.sectionId && (dp.questionnaireId === contentId || dp.contentId === contentId));
              if (dep) {
                dep.sectionId = sectionId;
              }
            }
          }
        }
      }
    }
    return dependencyList.filter(dp => !!dp.sectionId);
  }

  public filterContents(list: SectionTimeline[], currentUser: AppUser, isPresenter: boolean,
                        planeFullListContent: IPlaneContentMap,
                        availableSectionIdList: {}): SectionTimeline[] {

    const sectionWithChildren = (sectionId) => {
      const section = this.sections?.find(s => s.id === sectionId);
      return section ? this.utils.getSectionChildrenTree(section, this.sections) : [];
    };

    const returnItems: any[] = [];
    const sectionList = list.filter(item => item.type === Constants.CONTENT_TYPE_TIMELINE && !item.fixedSectionType);

    const _userData = this.userData;
    let sectionUserData = [];
    if (!isEmpty(_userData) && !isEmpty(_userData.sections)) {
      sectionUserData = _userData.sections;
    }

    for (const item of sectionList) {
      const isUserAttendee = this.isAttendeeContent(currentUser, item, sectionUserData);
      const isUserSpeaker = this.isSpeakerContent(currentUser, item);
      if (isPresenter) {
        continue;
      } else if (isUserSpeaker || this.isSectionVisibilityByDependency(item)) {
        if ((item.isStatusOpen || item.isStatusPlanned) && item.isPublic) {
          continue;
        } else
        if ((item.isStatusOpen  || item.isStatusPlanned) && !item.isPublic && (isUserSpeaker || isUserAttendee)) {
          continue;
        } else
        if (item.isStatusDraft && isUserSpeaker) {
          continue;
        }
      }
      const sectionChildList = sectionWithChildren(item.id);
      sectionChildList.forEach(child => {
        const childIndex = list.findIndex(el => el.id === child.id);
        if (childIndex > -1) {
          list[childIndex]['access_denied'] = true;
        }
      });
    }
    for (const item of list) {
      if (!item['access_denied']) {
        returnItems.push(item);
        availableSectionIdList[item.id] = true;
      }
    }
    return returnItems;
  }

  private buildSectionRange(sectionId: string, sectionType: 'feature'|'selected') {
    let range = {};
    const parentId = this._planeListContent[sectionId] ? this._planeListContent[sectionId].sectionContent.parentId : null;
    let parentIsContainer = false;
    if (parentId) {
      parentIsContainer = this._planeListContent[parentId] ? this._planeListContent[parentId].sectionContent.container : null;
    }
    if (this.rootSection && sectionId && sectionId !== this.rootSection.id && !parentIsContainer) {
      let lineId = sectionId;
      let headLineParent: PlaneContent;
      while ((headLineParent = this._planeListContent[lineId]) && headLineParent.parentId !== this.rootSection.id) {
        lineId = headLineParent.parentId;
      }
      if (headLineParent) {
        const childsTree = this.childTreeInLineList(headLineParent.id);
        range = childsTree.reduce(function (value, item) {
          if (item['id'] !== sectionId) {
            value[item['id']] = true;
          }
          return value;
        }, {});
        range['firstId'] = childsTree[0]?.id;
      }
    }
    if (sectionType === 'selected') {
      this.selectedLineContentRange = range;
    } else
    if (sectionType === 'feature') {
      this.featureLineContentRange = range;
    }
  }

  private updateAdditionalFields() {
    const addChilds = (parentId, pList: IPlaneContentMap, resultIdList: string[]) => {
      for (const it of pList[parentId].items) {
        resultIdList.push(it.id);
        addChilds(it.id, pList, resultIdList);
      }
    };
    const childIdList = (parentId, pList: IPlaneContentMap) => {
      const resultIdList: string[] = [];
      resultIdList.push(parentId);
      addChilds(parentId, pList, resultIdList);
      return resultIdList;
    };

    const lists = [this._planeListContent, this._planeFullListContent];
    for (const objects of lists) {
      const list = Object.values(objects);
      for (const plc of list) {
        if (plc.freeSlotType && plc.items?.length > 0) {
          const lst = childIdList(plc.id, objects);
          for (const id of lst) {
            objects[id].inSlot = id !== plc.id;
          }
        }
        if (plc.isSelfLearningSection) {
          const lst = childIdList(plc.id, objects);
          for (const id of lst) {
            objects[id].inSelfLearning = true;
          }
        }
      }
    }
  }

  updateDisplayItemsTreeProperties(root: SectionTimeline) {

    const excludeContainerChildren = (list: PlaneContent[]) => {
      const containerIds = list.filter(s => s.container).map(s => s.id);
      return list.filter(s => !containerIds.includes(s.parentId));
    };

    const sectionFilter = this.timelineFilterService.timelineFilter$.getValue()?.sectionsIds;
    const updateProperties = (s: SectionTimeline, lvl: number) => {
      const pl = this.planeListContent[s.id];
      let items = [];
      if (pl) {
        items = s.items.filter(it => !it.fixedSectionType && (sectionFilter ? sectionFilter.includes(it.id) : true));
        const isRegistered = this.checkRegistration(this.currentUser, s);
        items = (isRegistered || this.isSuperUser(this.currentUser) || this.isSuperAdmin(this.currentUser)) &&
          !pl.container ? items.sort(this.utils.comparator(Constants.ORDERINDEX)) : [];
        pl.treeItemsProperties.level = lvl;
        pl.isRegistered = isRegistered;
        const subTree = excludeContainerChildren(
          (isRegistered && !pl.container ? this.childTreeInLineList(s.id).filter(it => it.id !== s.id) : [])
          .filter(it => sectionFilter ? sectionFilter.includes(it.id) : true)
          .filter(it => !!this.planeListContent[it.id]));
        if (lvl <= 2 && (subTree.length > 0 || root.isRoot)) {
          pl.treeItemsProperties.hideBottomBadgeLineOnExpand = subTree.length > 0;
          const lastItem = this.planeListContent[items[items.length - 1]?.id];
          if (lastItem) {
            lastItem.treeItemsProperties.hideBottomBadgeLineOnMinimized = true;
          }
          pl.treeItemsProperties.inclinedLineOnExpand = true;
          const subFirst = this.planeListContent[subTree[0]?.id];
          if (subFirst) {
            subFirst.treeItemsProperties.hideTopBadgeLineAlways = true;
          }
          const subLast = this.planeListContent[subTree[subTree.length - 1]?.id];
          if (subLast) {
            subLast.treeItemsProperties.hideBottomBadgeLineAlways = true;
          }
          for (let i = 0; i < items.length - 1; i++) {
            const isItemRegistered = this.checkRegistration(this.currentUser, items[i]);
            if (isItemRegistered && !items[i].container && items[i].items.length > 0) {
              const npl = this.planeListContent[items[i + 1].id];
              if (npl) {
                npl.treeItemsProperties.hideTopBadgeLineOnPrevExpand = true;
                npl.prevSectionId = items[i].id;
              }
            }
          }
        } else if (lvl > 2 && (subTree.length > 0 || root.isRoot)) {
          const lastItem = this.planeListContent[items[items.length - 1]?.id];
          if (lastItem) {
            lastItem.treeItemsProperties.hideBottomBadgeLineOnMinimized = true;
          }
          const subLast = this.planeListContent[subTree[subTree.length - 1]?.id];
          if (subLast) {
            subLast.treeItemsProperties.hideBottomBadgeLineAlways = true;
          }
          for (let i = 0; i < items.length - 1; i++) {
            const isItemRegistered = this.checkRegistration(this.currentUser, items[i]);
            if (isItemRegistered && !items[i].container && items[i].items.length > 0) {
              const npl = this.planeListContent[items[i + 1].id];
              if (npl) {
                npl.treeItemsProperties.hideTopBadgeLineOnPrevExpand = true;
                npl.prevSectionId = items[i].id;
              }
            }
          }
        }
      }
      lvl = lvl + 1;
      for (const section of items) {
        updateProperties(section, lvl);
      }
    };
    if (root) {
      const level = 0;
      Object.values(this.planeListContent).forEach(o => o.restoreDefaultTreeItemsProperties());
      updateProperties(root, level);
    }
  }

  /**
   * Get section list for user in presentation mode.
   * Ignore draft section, prepare container section with or without user room.
   * @param conferenceUser
   */
  getUserPlaneListContentSorted(conferenceUser: ConferenceUser): PlaneContent[] {
    const resultList: PlaneContent[] = [];
    const userSectionList = conferenceUser && conferenceUser.userSections ? conferenceUser.userSections : [];
    const currentList = this.getPlaneListContentSortedWithoutFixed();
    let containerId;
    let skipSectionIdList = [];
    for (const obj of currentList) {
      if (obj.isRoot) {
        resultList.push(obj);
        continue;
      }
      if (!skipSectionIdList.includes(obj.id)) {
        skipSectionIdList = [];
      } else {
        continue;
      }
      if (obj.sectionContent.status === Constants.SECTION_STATUS_DRAFT) {
        skipSectionIdList = this.childTreeInLineList(obj.id).map(o => o.id);
        continue;
      }
      const parentObj = this.planeListContent[obj.parentId];
      if (parentObj && !parentObj.container && !obj.container) {
        resultList.push(obj);
        continue;
      }
      if (parentObj && !parentObj.container && obj.container && userSectionList.every(sId =>
            obj.items.findIndex(item => item.id === sId) === -1)) {
        // set add container, ignore container child sections
        resultList.push(obj);
        containerId = obj.id;
        continue;
      }
      if (containerId === obj.parentId) {
        continue;
      }
      if (containerId !== obj.parentId) {
        containerId = undefined;
      }
      if (parentObj && !parentObj.container && obj.container && userSectionList.some(sId =>
        obj.items.findIndex(item => item.id === sId) > -1)) {
        // user has enter container room
        let roomId;
        for (const id of userSectionList) {
          if (obj.items.findIndex(item => item.id === id) > -1) {
            roomId = id;
            break;
          }
        }
        if (obj.sectionContent.showTitle) {
          resultList.push(obj);
        }
        const rObj = this.planeListContent[roomId];
        resultList.push(rObj);
        containerId = obj.id;
      }
    }
    return resultList;
  }

  /**
   * Return tree from all child list in one line, work with caching, reset cache when refresh planList.
   */
  childTreeInLineList(sectionId: string): PlaneContent[] {
    const getContentIndex = (sId): number => {
      const sortedPlaneList = this.getPlaneFullListContentWithoutFixedAsSortedArray();
      return sortedPlaneList.findIndex(elem => sId === elem['id']);
    };

    const getNextContentId = (sId): string => {
      const parent = this._planeFullListContent[sId] && this._planeFullListContent[sId].trueParentItem ?
        this._planeFullListContent[this._planeFullListContent[sId].trueParentItem.id] : null;
      if (!parent) {
        return null;
      }
      const childList = parent.items;
      const myIndex = childList.findIndex(elem => sId === elem.id);
      if (parent.items.length === 1 || myIndex === parent.items.length - 1) {
        return sId;
      } else {
        return parent.items[myIndex + 1].id;
      }
    };

    const tree = (sId: string): PlaneContent[] => {
      const ret: any[] = [];
      const level = this.contentLevel(sId);
      const myIndex = getContentIndex(sId);
      if (myIndex < 0) {
        return ret;
      }
      const nextId = getNextContentId(sId);
      const sortedPlaneList = this.getPlaneFullListContentWithoutFixedAsSortedArray();
      for (let i = myIndex; i < sortedPlaneList.length; i++) {
        if (i === myIndex ||
          (this.contentLevel(sortedPlaneList[i].id) > level && sortedPlaneList[i].id !== nextId)) {
          ret.push(sortedPlaneList[i]);
        } else {
          break;
        }
      }
      return ret;
    };
    if (this._childTreeInLineListCache[sectionId]) {
      return this._childTreeInLineListCache[sectionId];
    }
    const line = tree(sectionId);
    this._childTreeInLineListCache[sectionId] = line;
    return line;
  }

  private calculateSectionsAutoCounterValues() {
    this._sectionsAutoCounterValues = {};
    const sectionList: SectionContent[] = this.getPlaneListContentSorted().map(v => v.sectionContent);
    for (const section of sectionList) {
      // get section childs
      const sectionChilds: SectionContent[] = sectionList.filter(child =>
        child.parentId === section.id && this.availableSectionIdList[child.id] && !!child.autocounter);
      for (let i = 0; i < sectionChilds.length; i++) {
        this._sectionsAutoCounterValues[sectionChilds[i].id] = {index: i + 1, count: sectionChilds.length};
      }
    }
  }

  getSectionCounterValue(sectionId: string) {
    return this._sectionsAutoCounterValues[sectionId];
  }

  private eventIsNoTimingAllSections() {
    return (this.timelineEvent.dateMode === EVENT_DATE_MODE.SPECIFIC_DATE_TIME && this.utils.objectValues(this._manageTimeMap)
      .filter(obj => !obj.sectionId.toString().includes('-') && obj.sectionId !== this.rootSection.id)
      .every(obj => !!this.planeFullListContent[obj.sectionId].sectionContent.sectionTimeIsNotActive)) ||
      (this.timelineEvent.dateMode !== EVENT_DATE_MODE.SPECIFIC_DATE_TIME && this.utils.objectValues(this._manageTimeMap)
        .filter(obj => !obj.sectionId.toString().includes('-') && obj.sectionId !== this.rootSection.id)
        .every(obj => !this._manageTimeMap[obj.sectionId].start.fixed &&
                                !this._manageTimeMap[obj.sectionId].duration.fixed &&
                                !this._manageTimeMap[obj.sectionId].end.fixed));
  }

  private eventWithoutSelfLearningSections() {
    return this._planeListContentSortedWithoutFixed.every(o => !o.sectionContent.isSelfLearningSection);
  }

  get isEasyMode() {
    return this.eventInstantSettings && this.eventInstantSettings.modeEasy();
  }

  public calcEventMangeTimeMap(currentUser, isPresenter, round5MinParam: boolean = null) {
    if (!this.rootSection || this.loginService.educationMode || this.timelineSlotMode.getValue()) {
      return;
    }
    let round5Min;
    if (round5MinParam === null) {
      round5Min = this.eventInstantSettings.manageTimeRound5Min;
    } else {
      round5Min = round5MinParam;
    }
    if (!this.calculateMangeTimeObjectsBlocked) {
      this._manageTimeMap = {};
      this.manageTimeService.calcMangeTimeMap(this._manageTimeMap, this.planeFullListContent,
        this.getPlaneFullListContentAsSortedArray(), this.timelineEvent, MANAGE_TIME_ROW_TYPE.PREP, round5Min, this.rootSection.id);
      this.manageTimeService.calcMangeTimeMap(this._manageTimeMap, this.planeFullListContent,
        this.getPlaneFullListContentAsSortedArray(), this.timelineEvent, MANAGE_TIME_ROW_TYPE.START, round5Min, this.rootSection.id);
      this.manageTimeService.calcMangeTimeMap(this._manageTimeMap, this.planeFullListContent,
        this.getPlaneFullListContentAsSortedArray(), this.timelineEvent, MANAGE_TIME_ROW_TYPE.WRAP_UP, round5Min, this.rootSection.id);
      this._eventNoTimingSections = this.eventIsNoTimingAllSections();
      this._eventWithoutSelfLearning = this.eventWithoutSelfLearningSections();
      this.calculateTimelineFirstLevel();
      this.refreshAutopilotData();
    }
    this.planeList.next(this.planeListContent);
    this.planeListSubject.next((new Date()).getTime());
    this.updateMangeTimeObjects(currentUser, isPresenter);
  }

  refreshAutopilotData() {
    if (this.eventInstantSettings) {
      if (this.isPresenter && this.eventInstantSettings.autopilot && this.eventInstantSettings.autopilot.statusControl) {
        this.autopilotService.rebuildAutopilotMap(this._planeFullListContentWithoutFixedAsSortedArray, this.planeFullListContent,
          this._manageTimeMap, this.timelineEvent.eventId, this.isPresenter, this.instantSettings.autopilot.statusControl, this);
      } else {
        this.autopilotService.rebuildAutopilotMap(this._planeListContentSortedWithoutFixed, this.planeFullListContent,
          this._manageTimeMap, this.timelineEvent.eventId, this.isPresenter, this.instantSettings.autopilot.statusControl, this);
      }
    }
  }

  // create new manage time map with new time point
  public createNewMangeTime(point: TimePoint, withoutPoint?: boolean) {
    const round5Min = this.eventInstantSettings.manageTimeRound5Min;
    this.manageTimeService.calcTestMangeTimeMap(this.planeFullListContent, this.getPlaneFullListContentAsSortedArray(),
      this.timelineEvent, this.rootSection.id, round5Min, withoutPoint, point);
  }

  private calculateTimelineFirstLevel() {
    let currentStartDateTime;
    const eventId = this.timelineEvent.eventId;
    this._firstLevelTimePoints = new LevelTimePoints();
    const list = this._planeListContentSortedWithoutFixed.filter(o => o.parentId === this.rootSection.id);
    for (let i = 0; i < list.length; i++) {
      const section = list[i].sectionContent;
      const sectionTP = this.getMangeTimePoint(section.id);
      if (currentStartDateTime && !this.utils.equalDatesByDay(currentStartDateTime, sectionTP.start.value) && sectionTP.start.value) {
        currentStartDateTime = sectionTP.start.value;
        this._firstLevelTimePoints.daysStart[section.id] = sectionTP;
      } else if (!currentStartDateTime && sectionTP.start.value) {
        currentStartDateTime = sectionTP.start.value;
      }
      if (section.sectionEventPhase === SECTION_EVENT_PHASE.PREP && !this._firstLevelTimePoints.prep.sectionId) {
        this._firstLevelTimePoints.prep = sectionTP;
        delete this._firstLevelTimePoints.daysStart[section.id];
        if (!currentStartDateTime) {
          currentStartDateTime = this.mangeTimeMap[eventId + '-' + MANAGE_TIME_ROW_TYPE.PREP].start.value;
        }
      } else if (section.sectionEventPhase === SECTION_EVENT_PHASE.WRAP_UP && !this._firstLevelTimePoints.wrapUp.sectionId) {
        this._firstLevelTimePoints.wrapUp = sectionTP;
        delete this._firstLevelTimePoints.daysStart[section.id];
        const sd = this.getMangeTimePoint(eventId + '-' + MANAGE_TIME_ROW_TYPE.WRAP_UP).start.value;
        if (!currentStartDateTime || (currentStartDateTime && sd && !this.utils.equalDatesByDay(currentStartDateTime, sd))) {
          currentStartDateTime = this.getMangeTimePoint(eventId + '-' + MANAGE_TIME_ROW_TYPE.WRAP_UP).start.value;
        }
      } else if (!section.sectionEventPhase && !this._firstLevelTimePoints.start.sectionId) {
        this._firstLevelTimePoints.start = sectionTP;
        const sd = this.mangeTimeMap[eventId + '-' + MANAGE_TIME_ROW_TYPE.START].start.value;
        if (this.utils.equalDatesByDay(sd, sectionTP.startValue)) {
          delete this._firstLevelTimePoints.daysStart[section.id];
          if (!currentStartDateTime || (currentStartDateTime && sd && !this.utils.equalDatesByDay(currentStartDateTime, sd))) {
            currentStartDateTime = this.getMangeTimePoint(eventId + '-' + MANAGE_TIME_ROW_TYPE.START).start.value;
          }
        } else {
          this._firstLevelTimePoints.daysStart[section.id] = sectionTP;
        }
      }
    }
    this.calculateFirstLevelDaysEnd(true);
  }

  calculateFirstLevelDaysEnd(refreshAllTimeline: boolean) {
    if (this.timelineSlotMode.getValue()) {
      return;
    }
    this._firstLevelTimePoints.daysEnd = [];
    const list = this._planeListContentSortedWithoutFixed.filter(o => o.parentId === this.rootSection.id)
      .filter(it => this.timelineFilterService.timelineFilter$.getValue()?.sectionsIds && !refreshAllTimeline ?
        this.timelineFilterService.timelineFilter$.getValue()?.sectionsIds.includes(it.id) : true);
    let currentStartDateTime;
    for (let i = list.length - 1; i >= 0; i--) {
      const section = list[i].sectionContent;
      const sectionTP = this.getMangeTimePoint(section.id);
      if (currentStartDateTime && !this.utils.equalDatesByDay(currentStartDateTime, sectionTP.start.value)) {
        currentStartDateTime = sectionTP.start.value;
        this._firstLevelTimePoints.daysEnd.push({sectionId: section.id, orderIndex: section.orderIndex,
          childTree: this.childTreeInLineList(section.id).filter(s => this.hierarchicalParentIsRegisteredTimeline(s.id)).map(c => c.id)});
      } else if (!currentStartDateTime && sectionTP.start.value) {
        currentStartDateTime = sectionTP.start.value;
      }
      if (!section.sectionEventPhase && !this._firstLevelTimePoints.end.sectionId) {
        this._firstLevelTimePoints.end = sectionTP;
      }
    }
  }

  buildShiftSectionsDatesTask(shiftEventParams: ShiftEventParams, sectionList: SectionContent[], onlySection = false): RowTask[] {
    const shift = new Date(shiftEventParams.newStartDate).getTime() - new Date(shiftEventParams.oldStartDate).getTime();
    const sectionsUpdateTasks: any[] = [];
    for (const section of (sectionList || [])) {
      if (section.sectionTimeIsNotActive || !!section.fixedSectionType) {
        continue;
      }
      if (section.plannedTime) {
        sectionsUpdateTasks.push({
          sectionId: section.id, fieldName: 'plannedTime',
          value: section.plannedTime + shift, type: 'section'
        });
        sectionsUpdateTasks.push({
          sectionId: section.id, fieldName: 'plannedTimeFixedValue',
          value: section.plannedTime + shift, type: 'section'
        });
      }
      if (section.endTime) {
        sectionsUpdateTasks.push({
          sectionId: section.id, fieldName: 'endTime',
          value: section.endTime + shift, type: 'section'
        });
        sectionsUpdateTasks.push({
          sectionId: section.id, fieldName: 'endTimeFixedValue',
          value: section.endTime + shift, type: 'section'
        });
      }
    }
    if (!onlySection) {
      sectionsUpdateTasks.push({
        sectionId: null, fieldName: 'startDate',
        value: new Date(shiftEventParams.newStartDate).getTime(), type: 'event'
      });
      sectionsUpdateTasks.push({
        sectionId: null, fieldName: 'startDateFixed',
        value: null,
        valueBoolean: true, type: 'event'
      });
      sectionsUpdateTasks.push({
        sectionId: null, fieldName: 'duration',
        value: shiftEventParams.newDuration ? shiftEventParams.newDuration : null, type: 'event'
      });
      sectionsUpdateTasks.push({
        sectionId: null, fieldName: 'durationFixed',
        value: null,
        valueBoolean: !!shiftEventParams.durationFixed, type: 'event'
      });
      sectionsUpdateTasks.push({
        sectionId: null, fieldName: 'endDate',
        value: shiftEventParams.newEndDate ? shiftEventParams.newEndDate : null, type: 'event'
      });
      sectionsUpdateTasks.push({
        sectionId: null, fieldName: 'endDateFixed',
        value: null,
        valueBoolean: !!shiftEventParams.endDateFixed, type: 'event'
      });
      sectionsUpdateTasks.push({
        sectionId: null, fieldName: 'prepPhaseStart',
        value: shiftEventParams.prepPhaseStart ? shiftEventParams.prepPhaseStart + shift : null,
        type: 'event'
      });
      sectionsUpdateTasks.push({
        sectionId: null, fieldName: 'prepPhaseEnd',
        value: shiftEventParams.prepPhaseEnd ? shiftEventParams.prepPhaseEnd + shift : null,
        type: 'event'
      });
      sectionsUpdateTasks.push({
        sectionId: null, fieldName: 'wrapUpPhaseStart',
        value: shiftEventParams.wrapUpPhaseStart ? shiftEventParams.wrapUpPhaseStart + shift : null,
        type: 'event'
      });
      sectionsUpdateTasks.push({
        sectionId: null, fieldName: 'wrapUpPhaseEnd',
        value: shiftEventParams.wrapUpPhaseEnd ? shiftEventParams.wrapUpPhaseEnd + shift : null,
        type: 'event'
      });
    }
    return sectionsUpdateTasks;
  }

  getMangeTimePoint(sectionId) {
    const point = this.mangeTimeMap[sectionId];
    return point ? point : new TimePoint(sectionId, null);
  }

  shiftEventDatesBeforeSave(event: Event, shiftEventParams: ShiftEventParams) {
    if (shiftEventParams.useShift) {
      const shift = shiftEventParams.newStartDate - shiftEventParams.oldStartDate;
      if (event.prepPhaseStart) {
        event.prepPhaseStart = event.prepPhaseStart + shift;
      }
      if (event.prepPhaseEnd) {
        event.prepPhaseEnd = event.prepPhaseEnd + shift;
      }
      if (event.wrapUpPhaseStart) {
        event.wrapUpPhaseStart = event.wrapUpPhaseStart + shift;
      }
      if (event.wrapUpPhaseEnd) {
        event.wrapUpPhaseEnd = event.wrapUpPhaseEnd + shift;
      }
      if (event.endDate) {
        event.endDate = event.endDate + shift;
      }
    }
  }

  isManageTimeDisabled() {
    return !this.event || ((this.featuresService.isSlotModeEnabled() &&
      this.featuresService.isImportSlotsEnabled() && !!this.event?.classCode) || this.event?.disableManageTime);
  }

  updateMangeTimeObjects(userId, isPresenter) {
    if (this._updateMangeTimeObjectsBlocked || this.isManageTimeDisabled()) {
      return;
    }
    if (isPresenter || this.currentUserHasSpeakerRights(userId)) {
      this.manageTimeService.updateMangeTimeObjects(this.timelineEvent.eventId, this.mangeTimeMap, this._planeFullListContent);
    }
  }

  private currentUserHasSpeakerRights(userId) {
    return this.getPlaneListContentAsSectionArray().some(s => {
      const users: SectionUser[] = (s as SectionContent).users;
      return !isEmpty(users) && users.some(u => u.userId === userId && u.speaker);
    });
  }

  getUnionRootSection(): SectionContent {
    const planeListContentSorted = this.getPlaneListContentSortedWithoutFixed();
    let fSection;
    if (planeListContentSorted && planeListContentSorted.length === 0) {
      return null;
    } else if (planeListContentSorted && planeListContentSorted.length >= 2) {
      fSection = planeListContentSorted[1];
    } else {
      fSection = planeListContentSorted[0];
    }
    const unionRoot = cloneDeep(this.rootSection);
    unionRoot.sectionEventPhase = fSection.sectionContent.sectionEventPhase;
    return unionRoot;
  }

  private initLearningSectionsOrder(isPresenter) {
    if (isPresenter) {
      this.selfLearningService.learningSectionsOrder = {};
      return;
    }
    const updateKeys = Object.keys(this.selfLearningService.learningSectionsOrder).reduce(function (rval, cval) {
      rval[cval] = true;
      return rval;
    }, {});

    const list = this._planeListContent;
    const sortedList = this._planeListContentSorted;

    if (sortedList.some(o => o.sectionContent.isSelfLearningSection)) {
      let excludedIds = [];
      for (const obj of sortedList) {
        if (excludedIds.includes(obj.id)) {
          continue;
        }
        if (obj.sectionContent.isSelfLearningSection) {
          excludedIds = this.childTreeInLineList(obj.id).map(o => o.id).filter(id => !!list[id]);
          for (const id of excludedIds) {
            if (!this.selfLearningService.learningSectionsOrder[id]) {
              this.selfLearningService.learningSectionsOrder[id] = {
                id: id,
                items: (list[id].items || []).map(o => ({id: o.id, selfLearningType: null, completePercent: 0}) as ISectionComplete),
                originId: list[id].sectionContent.isAttached() ? list[id].sectionContent.originId : null,
                parentId: id !== obj.id ? list[id].parentId : null,
                completePercent: 0,
                selfLearningType: null,
                itemsLearningType: null,
              };
            } else {
              this.selfLearningService.learningSectionsOrder[id].items =
                (list[id].items || []).map(o => ({id: o.id, selfLearningType: null, completePercent: 0}) as ISectionComplete);
              delete updateKeys[id];
            }
          }
        }
      }
      Object.keys(updateKeys).forEach(key => delete this.selfLearningService.learningSectionsOrder[key]);
    } else {
      this.selfLearningService.learningSectionsOrder = {};
    }
  }

  /**
   * Return user joined sectionId or null
   * @param sectionId
   * @param conferenceUser
   */
  getContainerUserSectionId(sectionId, conferenceUser: ConferenceUser): number {
    const section = this.planeListContent[sectionId].sectionContent;
    let rSection = null;
    for (const id of conferenceUser.userSections) {
      if ((rSection = section['items'].find(item => item.id === id))) {
        return rSection.id;
      }
    }
    return rSection;
  }

  userRoom(section: SectionContent, conferenceUser): SectionContent {
    let joinedSection = null;
    if (section && section.container && conferenceUser) {
      const childSectionList = this.planeListContent[section.id] ?
        this.planeListContent[section.id].items : [];
      const userSectionList = conferenceUser.userSections;
      for (const id of userSectionList) {
        joinedSection = childSectionList.find(item => item.id === id);
        if (joinedSection) {
          break;
        }
      }
    }
    return joinedSection;
  }

  /**
   * get section with
   */
  getEmptyDaysBetweenStartEndSection(): number[] {
    const dateDay: number[] = [];
    for (const section of this._planeListContentSortedWithoutFixedAsSectionArray) {
      if (section.sectionTimeIsNotActive ||
         (!section.plannedTimeFixed && !section.durationFixed && !section.endTimeFixed) ||
         (section.plannedTimeFixed && !section.durationFixed && !section.endTimeFixed)) {
        continue;
      }
      const start = new Date(section.plannedTime);
      const startDay = new Date(start.getFullYear(), start.getMonth(), start.getDate());
      const end = new Date(section.endTime);
      const endDay = new Date(end.getFullYear(), end.getMonth(), end.getDate());
      if (endDay.getTime() - startDay.getTime() >= Constants.ONE_DAY * 2) {
        const dayCount = Math.trunc((endDay.getTime() - startDay.getTime()) / Constants.ONE_DAY) - 1;
        const zeroHHMM = start.getHours() === 0 && start.getMinutes() === 0;
        for (let day = 1; day <= dayCount; day++) {
          if (zeroHHMM) {
            dateDay.push(start.getTime() + ((day - 1) * Constants.ONE_DAY));
          } else {
            dateDay.push(startDay.getTime() + (day * Constants.ONE_DAY));
          }
        }
      }
    }
    return dateDay;
  }

  get showPrintDialog(): Subject<boolean> {
    return this._showPrintDialog;
  }

  get showSocialPanel(): BehaviorSubject<boolean> {
    return this._showSocialPanel;
  }

  get hideMainToolbar(): Subject<boolean> {
    return this._hideMainToolbar;
  }

  sectionLevel(content) {
    if (content && content.isTypeSection) {
      return this.contentLevel(content.id);
    } else {
      return -1;
    }
  }

  isSuperUser(currentUser?: AppUser) {
    currentUser = currentUser ?? this.currentUser;
    return !!(this.timelineEvent && currentUser &&
      (this.timelineEvent.isManager(currentUser.userId) ||
        currentUser.isModerator || currentUser.isAdmin ||
        this.hasTrainerRights(currentUser) || this.hasSimpleRegistrationRights(currentUser)) || this.loginService.educationMode);
  }

  isSuperAdmin(currentUser: AppUser) {
    return !!(this.timelineEvent && currentUser &&
      (currentUser.isAdmin || currentUser.isSuperAdmin || this.loginService.educationMode));
  }

  hasTrainerRights(currentUser: AppUser) {
    return currentUser && this.timelineEvent &&
      currentUser.isTrainer && currentUser.userId === this.timelineEvent.ownerKey;
  }

  hasSimpleRegistrationRights(currentUser: AppUser) {
    return currentUser && this.timelineEvent &&
      currentUser.isSimpleRegistration && currentUser.userId === this.timelineEvent.ownerKey;
  }

  doNotCheckRegistrationForCurrentUser(currentUser: AppUser) {
    return this.conferenceUser?.isViewer || this.isPresenter;
  }

  /**
   * Has access rights to section
   * @param currentUser
   * @param section
   */
  hasSectionAccessRights(currentUser: AppUser, section: SectionTimeline | SectionContent) {
    return section && ((!section.requiredRegistration && this.hierarchicalParentIsRegisteredTimeline(section.id)) ||
      (section.requiredRegistration &&
        (this.doNotCheckRegistrationForCurrentUser(currentUser) ||
         this.isSpeakerContent(currentUser, section) ||
         !!this.completeUserSectionRegistration.find(id => id === section.getId()))
      ));
  }

  /**
   * Has user access rights to section
   * @param section
   */
  hasUserAccessRightsToSection(section: SectionTimeline | SectionContent) {
    return (!section.requiredRegistration && this.hierarchicalParentIsRegisteredTimeline(section.id)) ||
      (section.requiredRegistration &&
        (this.doNotCheckRegistrationForCurrentUser(this.currentUser) ||
         this.isSpeakerContent(this.currentUser, section) ||
         !!this.completeUserSectionRegistration.find(id => id === section.getId()))
      );
  }

  /**
   * Has user access rights to section
   * @param section
   */
  hasUserAccessRightsToSelectedSection(section: SectionTimeline | SectionContent) {
    return (!section?.requiredRegistration && this.hierarchicalParentIsRegisteredTimeline(section.id)) ||
      (section?.requiredRegistration &&
        (this.isPresenter || this.isSpeakerContent(this.currentUser, section) ||
          !!this.completeUserSectionRegistration.find(id => id === section.getId()))
      );
  }

  /**
   * Has access rights to selected section
   */
  hasSelectedSectionPresenterAccessRights() {
    return this.selectedSection && (this.isPresenter ||
      this.isSpeakerContent(this.loginService.getAppUser(), this.selectedSection));
  }

  /**
   * Has access rights to selected section
   */
  hasSpeakerAccessRightsToSection(section: SectionContent | SectionTimeline) {
    return this.isSpeakerContent(this.loginService.getAppUser(), section);
  }

  /**
   * Has speaker or presenter access rights to selected section
   */
  hasPresenterOrSpeakerAccessRightsToSection(section: SectionContent | SectionTimeline) {
    return section && (this.isPresenter || this.isSpeakerContent(this.loginService.getAppUser(), section));
  }

  /**
   * Has user speaker or presenter access rights to selected section
   */
  hasUserSpeakerAccessRightsToSection(section: SectionContent | SectionTimeline, user: AppUser) {
    return section && this.isSpeakerContent(user, section);
  }

  isCurrentUserHasSpeakerAccessRightsToAnySection() {
    return this.event?.isSpeaker(this.currentUser?.userId);
  }

  hasUserPresenterAccess(user: AppUser) {
    return this.event?.isManager(user?.userId);
  }

  /**
   * Check user section registration
   * @param currentUser
   * @param section
   */
  checkRegistration(currentUser: AppUser, section: SectionContent | SectionTimeline) {
    if (currentUser.guest && section.requiredRegistration) {
      return false;
    }
    if (this.conferenceUser && this.conferenceUser.isViewer) {
      return true;
    }
    if (section && section.requiredRegistration && !this.isPresenter &&
      !this.isSpeakerContent(currentUser, section)) {
      return this.completeUserSectionRegistration.includes(section.getId());
    } else {
      return true;
    }
  }

  getChildSections(sectionId: string): SectionTimeline[] {
    return this.getPlaneListContentSortedWithoutFixed().filter(it => it.parentId === sectionId).map(it => it.sectionContent);
  }

  protected educationViewModeFilter(content: AbstractContent, list: AbstractContent[], draftMode) {
    if (this.loginService.educationMode && content.type === Constants.CONTENT_TYPE_CONTENT_CONTAINER) {
      if (!draftMode) {
        return !(content as ContentContainer).dirty;
      } else {
        return (!content['dirty'] && !list.find(cn => cn.id === content.id && cn['dirty'])) || content['dirty'];
      }
    } else {
      return true;
    }
  }

  protected educationViewModeFilterOnlyDirty(content: AbstractContent) {
    return (this.loginService.educationMode && !!(content as ContentContainer).dirty) || false;
  }

  protected educationViewModeFilterOnlyClean(content: AbstractContent) {
    return (this.loginService.educationMode && !(content as ContentContainer).dirty) || false;
  }

  private loadSectionContents(section: SectionContent) {
    if (this._unsubscribeSectionContents) {
      this._unsubscribeSectionContents.next(true);
      this._unsubscribeSectionContents.complete();
      this._unsubscribeSectionContents = null;
    }
    if (!section || section.isRoot) {
      this._changeSectionSubject.next(section);
      this._sectionChildContentsListSubject.next(null);
      return;
    }
    this._sectionContentsLoaded.next(false);
    this._unsubscribeSectionContents = new Subject<boolean>();
    this.dataService.loadSectionContents(this.timelineEvent.eventId, section, this.fixedSections,
      this.eventInstantSettings?.contentFromAudienceMode ?? CONTENT_FROM_AUDIENCE_MODE.NONE, null,
      this.hasSectionAccessRights(this.loginService.getAppUser(), section), null, null)
      .pipe(switchMap(object => {
        return this.educationViewDraftMode$.pipe(switchMap(draftMode => {
          const list = object.list.filter(o => this.educationViewModeFilter(o, object.list, draftMode));
          const dirtyList = object.list.filter(o => this.educationViewModeFilterOnlyDirty(o));
          const cleanList = object.list.filter(o => this.educationViewModeFilterOnlyClean(o));
          return of(new Object({...object, list, dirtyList, cleanList}));
        }));
      }))
      .pipe(takeUntil(this._unsubscribeSectionContents))
      .subscribe((value: any) => {
        if (!this._changeSectionSubject.getValue() || this._changeSectionSubject.getValue().id !== section.id) {
          this._changeSectionSubject.next(section);
          this.sectionChildContentsListSubject.next({sectionId: section.id, ...value});
          this.subscribeDependency(value.dependency);
        } else {
          this.sectionChildContentsListSubject.next({sectionId: section.id, ...value});
        }
        this._sectionContentsLoaded.next(true);
    });
  }

  private subscribeDependency(dependencyList: IDependencyQuestionnaire[]) {
    this.dataService.subscribeUserOnDependencyQuestionnaire(this.timelineEvent.eventId, this.currentUser.userId, dependencyList)
      .pipe(takeUntil(this._unsubscribeSectionContents)).subscribe(value => {
      this._dependencyAnswers$.next(!isEmpty(value) ? value as IDependencyAnswers : {});
    });
  }

  isContentVisibility(content: ContentContainer | ModularContent | any) {
    if (content.type === Constants.CONTENT_TYPE_MODULAR || content.type === Constants.CONTENT_TYPE_CONTENT_CONTAINER) {
      return content.getDependencyVisibility(this._dependencyAnswers$.getValue());
    }
    return true;
  }

  private isSectionVisibilityByDependency(section: SectionContent) {
    return section.getDependencyVisibility(this._sectionsDependencyAnswers$.getValue());
  }

  private setJoinedUserSections(conferenceUser: ConferenceUser) {
    if (!conferenceUser) {
      this._conferenceUserJoinedSections = {};
      return;
    }
    if (isEmpty(this._conferenceUserJoinedSections)) {
      this._conferenceUserJoinedSections = {};
    }
    if (isEmpty(this._conferenceUserContainerSections)) {
      this._conferenceUserContainerSections = {};
    }
    if (isEmpty(conferenceUser.userSections)) {
      this._conferenceUserJoinedSections = {};
      this._conferenceUserContainerSections = {};
    } else {
      this._conferenceUserJoinedSections = {};
      conferenceUser.userSections.forEach(id => {
        const sj = this.planeListContent[id];
        if (sj) {
          const sjp = this.planeListContent[sj.parentId];
          if (sjp && sjp.sectionContent.container) {
            this._conferenceUserJoinedSections[sj.parentId] = sj.sectionContent;
            this._conferenceUserContainerSections[sj.id] = sjp.sectionContent;
          }
        }
      });
    }
    this._isSpeakerMode = !!this.getSectionWithSpeakersSelfOrParent(this._featureLineContentId, conferenceUser);
  }

  assignAttendeeToSection(eventId: string, sectionId: string, userId: string, value: boolean) {
    return this.dataService.setSectionUser(eventId, sectionId, userId, value);
  }

  getParentSection(sectionId: string, result: 'sectionContent' | 'planeContent' = 'sectionContent'): PlaneContent | SectionTimeline {
    const parentId = this.planeListContent[sectionId].parentId;
    const parentSection: PlaneContent = this.planeListContent[parentId];
    if (result === 'planeContent') {
      return parentSection;
    }
    return parentSection.sectionContent;
  }

  joinToSection(eventId: string, sectionId: string) {
    this.dataService.saveUserSection(eventId, sectionId, {joinTime: this.utils.now()}).then(() => {
      this.conferenceUser$
        .pipe(
          filter(user => user.userSections.includes(sectionId)),
          take(1)
        )
        .subscribe(() => {
          const section = this.getSectionContent(sectionId);
          this.joinSectionSubject.next({section: section, join: true});
        });
    });
  }

  hierarchicalParentIsRegisteredTimeline(sectionId: string) {
    const section = this.planeListContent[sectionId];
    if (section && !section.sectionContent.isRoot) {
      if (this.doNotCheckRegistrationForCurrentUser(this.currentUser) ||
          this.isSpeakerContent(this.currentUser, section.sectionContent) ||
            !!this.completeUserSectionRegistration.find(id => id === section.getId())) {
        return true;
      }
      if (section && section.parentId) {
        let curSection = section.sectionContent;
        while (curSection && curSection.parentId && curSection.parentId !== this.rootSection.id) {
          curSection = curSection.parentId && this.planeListContent[curSection.parentId] ?
            this.planeListContent[curSection.parentId].sectionContent : null;
          if (curSection && curSection.requiredRegistration &&
            !this.completeUserSectionRegistration.find(id => id === curSection.getId())) {
            return false;
          }
        }
      }
    }
    return true;
  }


  /**
   * Return available section on max level by hierarchy from up to down
   * @param sectionId
   */
  maxTopHierarchicalParentIsRegisteredTimeline(sectionId: string) {
    const section = this.planeListContent[sectionId];
    if (section && !section.sectionContent.isRoot) {
      if (this.doNotCheckRegistrationForCurrentUser(this.currentUser)) {
        return section.sectionContent;
      }
      let curSection = section.sectionContent;
      const hierarchy = [cloneDeep(curSection)];
      while (curSection && curSection.parentId) {
        curSection = curSection.parentId && this.planeListContent[curSection.parentId] ?
          this.planeListContent[curSection.parentId].sectionContent : null;
        if (curSection && !curSection.isRoot) {
          hierarchy.push(cloneDeep(curSection));
        }
      }
      for (let i = hierarchy.length - 1; i >= 0; i--) {
        const s = hierarchy[i];
        if (this.isSpeakerContent(this.currentUser, s)) {
          return section.sectionContent;
        } else
        if (s.requiredRegistration && !this.completeUserSectionRegistration.find(id => id === s.getId())) {
          return s;
        }
      }
      return section.sectionContent;
    }
    return null;
  }

  isSpeakerForFeatureLine() {
    const section = this.featureLineContentId ?
      this.planeListContent[this.featureLineContentId] : null;
    if (section && section.sectionContent.container) {
      const containerList = this._planeListContentSortedWithoutFixedAsSectionArray
        .filter(o => o.parentId === section.id && !isEmpty(o.users));
      if (!isEmpty(containerList)) {
        return containerList.some(s => s.users.some(u => u.userId === this.currentUser.userId && u.speaker));
      } else {
        return false;
      }
    } else if (section && !section.sectionContent.container) {
      return section && (this.isSpeakerContent(this.currentUser, section));
    } else {
      return false;
    }
  }

  isSpeakerAnySection() {
    return this.currentUser && this.timelineEvent && this.timelineEvent.speakers && this.timelineEvent.speakers[this.currentUser.userId];
  }

  /**
   * Get main speaker section.
   * @param currentUser
   * @param {AbstractContent} content
   * @param {{}} timeLineWithParentList - objects of type PlaneContent
   * @returns {boolean}
   */
  getMainSpeakerSection(content: SectionTimeline | SectionContent): SectionTimeline | SectionContent {
    const contentParent = () => this.planeListContent[content.parentId].sectionContent;

    if (!content || !this.currentUser) {
      return null;
    }
    const parent = this.planeListContent[content.parentId].sectionContent;
    if (!content.container && !parent.container) {
      let localSection = this.planeListContent[!content.isTypeSection ? content.parentId : (content.id ? content.id : content.parentId)];
      if (!localSection) {
        return null;
      }
      let isSectionPresenter = localSection.sectionContent.hasSpeakers ?
        (this.isSpeakerContent(this.currentUser, localSection) ||
          this.isPresenter || (this.conferenceUser && this.conferenceUser.isViewer)) : false;
      let section = localSection.sectionContent;
      let parentId = localSection.trueParentItem ? localSection.trueParentItem.id : null;
      while (!isSectionPresenter && parentId && localSection) {
        localSection = this.planeListContent[parentId];
        if (localSection) {
          isSectionPresenter = localSection.sectionContent.hasSpeakers ?
            (this.isSpeakerContent(this.currentUser, localSection) ||
              this.isPresenter || (this.conferenceUser && this.conferenceUser.isViewer))  : false;
          section = localSection.sectionContent;
          parentId = localSection.sectionContent.parentId;
        }
      }
      return isSectionPresenter ? section : null;
    } else if (content.container) {
      const joined = this.userRoom(content, this.conferenceUser);
      if (joined && joined &&
        ((!isEmpty(joined.users) && joined.users.some(u => u.userId === this.currentUser.userId && u.speaker)) ||
          (!isEmpty(content.users) && content.users.some(u => u.userId === this.currentUser.userId && u.speaker)) ||
          this.isSpeakerAnySection() || this.isPresenter || (this.conferenceUser && this.conferenceUser.isViewer))) {
        return joined;
      } else {
        return null;
      }
    } else if (!content.container && contentParent().container &&
      ((!isEmpty(content.users) && content.users.some(u => u.userId === this.currentUser.userId && u.speaker)) ||
        (!isEmpty(contentParent().users) && contentParent().users.some(u => u.userId === this.currentUser.userId && u.speaker)) ||
        this.isSpeakerAnySection() || this.isPresenter || (this.conferenceUser && this.conferenceUser.isViewer))) {
      return content;
    }
    return null;
  }

  isSectionInChildOfParent(parent: SectionTimeline | SectionContent, child: SectionTimeline | SectionContent) {
    const list = this.childTreeInLineList(parent.id);
    return list.findIndex(ch => ch.id === child.id) > -1;
  }

  /**
   * Return section with speaker by hierarchical if section or paren not container or joined section or current section if container.
   * @param sectionId
   * @param conferenceUser
   */
  getSectionWithSpeakersSelfOrParent(sectionId: string, conferenceUser?: ConferenceUser) {
    if (!sectionId || !this.planeListContent[sectionId]) {return null; }
    let section = this.planeListContent[sectionId].sectionContent;
    const parent = !isEmpty(this.planeListContent[section.parentId]) ?
      this.planeListContent[section.parentId].sectionContent : null;
    if (!parent) { return null; }
    if (!section.container && !parent.container) {
      if (section && section.hasSpeakers) {
        return section;
      }
      section = this.planeListContent[section.parentId].sectionContent;
      while (!section.isRoot && !section.hasSpeakers) {
        section = this.planeListContent[section.parentId].sectionContent;
      }
      if (section && section.hasSpeakers) {
        return section;
      }
    } else if (!section.container && parent.container) {
      return section;
    } else if (section.container && !parent.container) {
      return this.userRoom(section, conferenceUser ?? this.conferenceUser);
    }
    return null;
  }

  getFeatureLineSectionContent() {
    return this._featureLineContentId && this.planeListContent[this._featureLineContentId] ?
      this.planeListContent[this._featureLineContentId].sectionContent : null;
  }

  hasSpeakerForFeatureLineForPresenter() {
    const content = this.getFeatureLineSectionContent();
    if (!content || !this.currentUser) {
      return null;
    }
    const parent = this.planeListContent[content.parentId].sectionContent;
    if (!content.container && !parent.container) {
      let localSection = this.planeListContent[!content.isTypeSection ? content.parentId : (content.id ? content.id : content.parentId)];
      if (!localSection) {
        return null;
      }
      let isSectionPresenter = localSection.sectionContent.hasSpeakers;
      let section = localSection.sectionContent;
      let parentId = localSection.trueParentItem ? localSection.trueParentItem.id : null;
      while (!isSectionPresenter && parentId && localSection) {
        localSection = this.planeListContent[parentId];
        if (localSection) {
          isSectionPresenter = localSection.sectionContent.hasSpeakers;
          section = localSection.sectionContent;
          parentId = localSection.sectionContent.parentId;
        }
      }
      return isSectionPresenter;
    } else if (content.container) {
      const joined = this.userRoom(content, this.conferenceUser);
      if (joined) {
        return true;
      } else {
        return null;
      }
    } else if (!content.container && this.planeListContent[content.parentId].sectionContent.container) {
      return true;
    }
    return null;
  }


  /**
   * Return of the list of speakers, taking into account the hierarchy
   * @param section
   */
  getSectionHierarchicalSpeaker(section: SectionContent): string[] {
    let speakerList = [];
    const planeListContents = this._planeFullListContent;
    let curSection = planeListContents[section.id]?.sectionContent;
    while (curSection && curSection.parentId) {
      speakerList = union(speakerList, curSection.speakerIdList);
      if (planeListContents[curSection.parentId]) {
        curSection = planeListContents[curSection.parentId].sectionContent;
      } else {
        curSection = null;
      }
    }
    return speakerList;
  }

  /**
   * Return of the list of parents, taking into account the hierarchy
   * @param section
   */
  getSectionParentsByHierarchy(section: SectionContent): string[] {
    const parentsList = [];
    if (section && section.parentId) {
      const planeListContents = this._planeFullListContent;
      let curSection = planeListContents[section.id]?.sectionContent;
      while (curSection && curSection.parentId) {
        parentsList.push(curSection.parentId);
        curSection = curSection.parentId && planeListContents[curSection.parentId] ?
          planeListContents[curSection.parentId].sectionContent : null;
      }
    }
    return parentsList;
  }

  isSectionHierarchicalParentIncludeInRegistrationForm(section: SectionContent): boolean {
    if (section && section.parentId) {
      const planeListContents = this._planeFullListContent;
      let curSection = planeListContents[section.id]?.sectionContent;
      while (curSection && curSection.parentId) {
        curSection = curSection.parentId && planeListContents[curSection.parentId] ?
          planeListContents[curSection.parentId].sectionContent : null;
        if (curSection && !curSection.includeSectionInRegistrationForm) {
          return false;
        }
      }
    }
    return true;
  }

  autopilotSpecialPresenterTimelineViewMode(section: SectionContent) {
    return this.isPresenter && this.eventInstantSettings?.autopilot?.autopilotEnable &&
      this.eventInstantSettings?.autopilot?.statusControl &&
      this.eventInstantSettings?.autopilot?.beforeRunning === BEFORE_RUNNING.SET_STATUS_OPEN &&
      this.eventInstantSettings?.autopilot?.afterRunning === AFTER_RUNNING.NO_ACTION &&
      !isEmpty(this._sectionBeforeRunningStatus[section.id]) &&
      this._sectionBeforeRunningStatus[section.id] === Constants.SECTION_STATUS_OPEN;
  }

  initSectionsWithSpecificQAList() {
    const setTitle = (sectionId: string, title: string) => {
      return this.utils.createTextLinks(
        this.utils.createTextLinksM(
          this.utils.createTextSplitLinks(
            this.textMacrosParserService.parse(sectionId, title))));
    };

    if (this.rootSection && this.currentUser) {
      this.sectionsWithSpecificQAList = this.getPlaneListContentSortedWithoutFixed()
        .filter(p => {
          if (p.sectionContent.specificQA) {
            const s = this.maxTopHierarchicalParentIsRegisteredTimeline(p.id);
            return !s ? false :
              (s.id === p.id && ((!s.requiredRegistration) || this.doNotCheckRegistrationForCurrentUser(this.currentUser) ||
                this.hasSpeakerAccessRightsToSection(s) ||
                (s.requiredRegistration && !!this.completeUserSectionRegistration.find(id => id === s.getId()))));
          }
        })
        .map(s => new Object({id: s.id, title: setTitle(s.id, s.title)})) as any;
      this.sectionsWithSpecificQAList.splice(0, 0, {id: this.rootSection.id, title: this.utils.i18n('column.general')});
    }
  }

  isShowSuggestedThumbnail() {
    return this.qaSecondParentId &&
      ((!this.planeListContent[this.qaSecondParentId].sectionContent.isRoot &&
        this.planeListContent[this.qaSecondParentId].sectionContent.specificQA &&
        this.planeListContent[this.qaSecondParentId].sectionContent.qaModerationReview) ||
        (this.planeListContent[this.qaSecondParentId].sectionContent.isRoot &&
          this.eventInstantSettings.qaModerationReview));
  }

  isSuggested(qaSecondParentId) {
    return qaSecondParentId &&
      ((!this.planeListContent[qaSecondParentId].sectionContent.isRoot &&
        this.planeListContent[qaSecondParentId].sectionContent.specificQA &&
        this.planeListContent[qaSecondParentId].sectionContent.qaModerationReview) ||
        (this.planeListContent[qaSecondParentId].sectionContent.isRoot &&
          this.eventInstantSettings.qaModerationReview));
  }

  parentIsContainer(section: SectionContent) {
    return section && this.planeListContent[section.parentId] &&
      this.planeListContent[section.parentId].sectionContent.container;
  }

  fillTimeLineSpeakers() {
    const sl = {};
    const containerListId = this._planeListContentAsSectionArray.filter(s => s.container).map(o => o.id);
    const sList = this._planeListContentAsSectionArray.filter(s => !containerListId.includes(s.parentId));
    sList.filter(s => !isEmpty(s.users))
      .forEach(se =>  se.users.filter(u => u.speaker)
        .forEach(sp => sl[sp.userId] = true));
    this._timelineSpeakers = sl;
  }

  initFeedbackRatingServices() {
    if (this.isPresenter) {
      this.feedbackService.resetServiceValues();
      return;
    }
    if (this.instantSettings && this.instantSettings.feedbackRating &&
      this.instantSettings.feedbackRating.activateFeedbackRating) {
      this.feedbackService.initFeedbackCache(this.event.eventId, this._planeListContentAsSectionArray);
    } else if (this.instantSettings && this.instantSettings.feedbackRating &&
      !this.instantSettings.feedbackRating.activateFeedbackRating) {
      this.feedbackService.resetServiceValues();
    }
  }

  isUserSpeaker(sectionId: string) {
    return this.timelineEvent.managers[this.currentUser.userId] ||
      this.hasSpeakerAccessRightsToSection(this.getSectionContent(sectionId));
  }

  isSectionInSelfLearning(object: SectionContent | SectionTimeline | AbstractContent) {
    if (!object) {
      throw new Error('(isSectionInSelfLearning) Object is null');
    }
    if (!object.type) {
      throw new Error('(isSectionInSelfLearning) Unknown type of object: ' + JSON.stringify(object));
    }
    let obj = object;
    if (!object.isTypeSection) {
      obj = this.getSection(object.parentId);
      if (!obj) {
        if (this.selectedSection.isAttached() && this.selectedSection.originId === object.parentId) {
          obj = this.selectedSection;
        }
      }
    }
    return obj?.parentId && !isEmpty(this.isSectionHierarchicalParentSelfLearning(obj as SectionContent, !object.isTypeSection));
  }

  isSectionHierarchicalParentSelfLearning(section: SectionContent, withSelf = false): ISectionSelfLearningSetting {
    if (!section) {
      return null;
    }
    const planeListContents = this._planeFullListContent;
    const parentId = section.parentId;
    if (section && parentId) {
      let curSection = planeListContents[section.id]?.sectionContent;
      while (curSection && curSection.parentId) {
        if (withSelf && curSection && curSection.isSelfLearningSection) {
          return {isSelfLearningSection: curSection.isSelfLearningSection,
            fixedOrder: curSection.fixedOrder, mainSelfLearningSectionId: curSection.id};
        }
        curSection = curSection.parentId && planeListContents[curSection.parentId] ?
          planeListContents[curSection.parentId].sectionContent : null;
        if (!withSelf && curSection && curSection.isSelfLearningSection) {
          return {isSelfLearningSection: curSection.isSelfLearningSection,
            fixedOrder: curSection.fixedOrder, mainSelfLearningSectionId: curSection.id};
        }
      }
    }
    return null;
  }

  hasSelfLearningSectionInChilds(section: SectionContent) {
    const childsTree = this.childTreeInLineList(section.id);
    return childsTree.filter(o => o.id !== section.id).some(obj => obj.sectionContent.isSelfLearningSection);
  }


  contentLevel(sectionId: string, displayMode = false) {
    let level = 0;
    const section = this.planeFullListContent[sectionId];
    if (section) {
      let parent = this.planeFullListContent[section.parentId];
      while (parent && parent.id !== this.rootSection.id) {
        level++;
        parent = this.planeFullListContent[parent.parentId];
      }
    }
    return level;
  }

  /**
   * Return suffix for level section background class
   */
  levelColorClass(content: SectionContent, displayMode = false) {
    const FREE_SLOT = 'free-slot';
    const LEVEL012 = '012';
    const LEVEL3 = '3';
    const LEVEL4 = '4';
    const LEVEL5 = '5';
    if (content?.freeSlotType) {
      return FREE_SLOT;
    }
    if (content?.parentId) {
      const level = this.contentLevel(content.id, displayMode);
      switch (level >= 5 ? 5 : level) {
        case 0:
          return LEVEL012;
        case 1:
          return LEVEL3;
        case 2:
        case 3:
        case 4:
          return LEVEL4;
        case 5:
          return LEVEL5;
        default:
          return LEVEL012;
      }
    }
  }

  /**
   * Create order index
   */
  getOrderIndex(itemId: string, below?: boolean, excludeId?: string) {
    if (!itemId) {return null; }
    let localItemId = itemId;
    let localItemIdBefore = itemId;
    if (below) {
      // if "below" - real section is last section in child tree of section with id=itemId
      const objList = this.childTreeInLineList(itemId);
      if (objList.length > 0) {
        if (objList.length > 1 && objList[objList.length - 1].id === excludeId) {
          localItemIdBefore = objList[objList.length - 2].id;
          localItemId = objList[objList.length - 1].id;
        } else {
          localItemIdBefore = objList[objList.length - 1].id;
          localItemId = objList[objList.length - 1].id;
        }
      }
    }
    const before = this.getBeforeOrderIndex(localItemIdBefore);
    const after = this.getAfterOrderIndex(localItemId);
    if (!after) {
      return null;
    } else {
      return before + ((after - before) / 2 );
    }
  }

  getBeforeOrderIndex(itemId) {
    if (!itemId) {return null; }
    const item = this._planeFullListContent[itemId];
    if (item) {
      return item[Constants.ORDERINDEX];
    } else {
      return null;
    }
  }

  getAfterOrderIndex(itemId) {
    if (!itemId) {return null; }
    const sortedPlaneList = this.getPlaneFullListContentWithoutFixedAsSortedArray();
    const myIndex = sortedPlaneList.findIndex(elem => itemId === elem['id']);
    if (myIndex === -1 || myIndex === sortedPlaneList.length - 1) {
      return null;
    } else {
      const afterObj = sortedPlaneList[myIndex + 1];
      return afterObj[Constants.ORDERINDEX];
    }
  }

  /**
   * Create increment order index
   */
  getOrderIndexIncrement(itemId, childCount: number) {
    if (!itemId) {return null; }
    const before = this.getBeforeOrderIndex(itemId);
    const after = this.getAfterOrderIndex(itemId);
    if (!after) {
      return null;
    } else {
      return (after - before) / (childCount + 1) ;
    }
  }

  createSectionsSubjectsList() {
    let subjects: ISectionSubject[] = [];
    this._planeListContentSortedWithoutFixedAsSectionArray.filter(s => !isEmpty(s.subjects))
      .forEach(s => subjects = unionWith(subjects, s.subjects,
        (a, b) => a.name === b.name && a.shortName === b.shortName));
    this.timelineFilterService.sectionsSubjectsList$.next(subjects);
  }
}
