import {QUESTION_TYPES_COMPONENTS} from '../quiz-quiestion-types';
import {TCaption} from '../../../../../../../core/constants';
import {isEmpty} from 'lodash';
import {EventQuestion} from '../../../../../../../model/EventQuestion';

export const quizTypesForRegistration = () => {
  return Object.keys(QUESTION_TYPES_COMPONENTS)
    .filter(qt => QUESTION_TYPES_COMPONENTS[qt].canUseInRegistration)
    .map(t => Number.parseInt(t, 0));
};

export const quizAllTypes = () => {
  return Object.keys(QUESTION_TYPES_COMPONENTS)
    .sort((t1, t2) => QUESTION_TYPES_COMPONENTS[t1].order < QUESTION_TYPES_COMPONENTS[t2].order ? -1 : 1)
    .map(t => Number.parseInt(t, 0));
};

export const isEmptyCaption = (value: TCaption) => {
  return isEmpty(value) || (typeof value !== 'string' && Object.values(value).every(o => isEmpty(o)));
};

export const questionTypeUseCorrectAnswersOption = (type: number) => {
  return QUESTION_TYPES_COMPONENTS[type].useCorrectAnswersOption && !questionTypeWithoutCheckingCorrectness(type);
};

export const questionTypeWithoutCheckingCorrectness = (type: number) => {
  return !QUESTION_TYPES_COMPONENTS[type].directFeedbackCheck;
};

export const questionUseCheckingCorrectnessOfAnswer = (question: EventQuestion) => {
  return question && ((questionTypeUseCorrectAnswersOption(question.storypoint) && question.useCorrectAnswers) ||
    (!questionTypeWithoutCheckingCorrectness(question.storypoint) && !questionTypeUseCorrectAnswersOption(question.storypoint)));
};

