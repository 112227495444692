import {Component, ElementRef, Injector, viewChildren} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ResultDialogComponent} from './result-dialog/result-dialog.component';
import {AbstractQuizQuestionResultsComponent} from '../../shared/results/abstract-quiz-question-results-component';
import {Constants} from '../../../../../../../core/constants';
import {QUESTION_VIEW_MODE} from '../../shared/quiz-quiestion-types';
import {IAnswer} from '../../../quiz-model/quiz';
import {isEmpty} from 'lodash';

export interface IAnswerSequenceCounter extends IAnswer {
  sequenceNumber?: number;
  countWrongAnswer?: number;
  countCorrectAnswer?: number;
  totalAnswerResult?: number;
}

type TSummaryAnswers = Record<string, number>[];

@Component({
  selector: 'app-question-sequence-results',
  templateUrl: './question-sequence-results.component.html',
  styleUrl: './question-sequence-results.component.scss'
})
export class QuestionSequenceResultsComponent extends AbstractQuizQuestionResultsComponent {

  readonly QUESTION_VIEW_MODE = QUESTION_VIEW_MODE;

  dataSource: IAnswerSequenceCounter[] = [];
  displayedColumns = ['sequenceNumber', 'answer'];
  viewMode = QUESTION_VIEW_MODE.PERCENTAGE;

  private dialogRef: MatDialogRef<ResultDialogComponent> | null = null;
  private cellListCorrect = viewChildren<ElementRef>('correctCell');
  private cellListIncorrect = viewChildren<ElementRef>('incorrectCell');

  constructor(protected injector: Injector,
              protected elementRef: ElementRef,
              private dialog: MatDialog) {
    super(injector, elementRef);
  }

  ngOnInit() {
    super.ngOnInit();
    this.question$.pipe(this.takeUntilAlive()).subscribe(question => this.viewMode = question.viewMode ?? QUESTION_VIEW_MODE.PERCENTAGE);
  }

  protected initQuestionAnswersDataSource() {
    this.dataSource = this.question.items
      .map(answer => new Object({
        id: answer.id,
        answer: answer.getAnswerByLanguage(this.languageParams),
        orderIndex: answer.orderIndex
      }))
      .sort(this.common.utils.comparator(Constants.ORDERINDEX))
      .map((row, index) => new Object({...row, sequenceNumber: index + 1}) as IAnswerSequenceCounter);
    this.calcDataSourceFields();
  }

  protected onReceiveQuestionAnswers() {
    this.calcDataSourceFields();
  }

  private getSummaryAnswers(): TSummaryAnswers {
    return isEmpty(this.summaryQuestionAnswers) ? [] : this.summaryQuestionAnswers;
  }

  private calcDataSourceFields() {
    const notZeroAnswerRow = this.getSummaryAnswers().find(ar => Object.values(ar).some(v => !!v)) ?? [];
    const totalAnswerResult = Object.values(notZeroAnswerRow).reduce((prev, curr) => prev + curr, 0);
    this.dataSource.forEach((row, idx) => {
      row.totalAnswerResult = totalAnswerResult;
      row.countCorrectAnswer = this.getSummaryAnswers()[idx]?.[row.id] ?? 0;
      row.countWrongAnswer = row.totalAnswerResult - row.countCorrectAnswer;
    });
  }

  openDialog(row: IAnswerSequenceCounter): void {
    // Find the wrong and correct answer elements
    const wrongAnswerElem = this.cellListIncorrect().find(elem => elem.nativeElement.id === `incorrect-${row.id}`)?.nativeElement;
    const correctAnswerElem = this.cellListCorrect().find(elem => elem.nativeElement.id === `correct-${row.id}`)?.nativeElement;
    if (!wrongAnswerElem || !correctAnswerElem) {
      return;
    }
    const rectForColumnRight = correctAnswerElem.getBoundingClientRect();
    // Apply the active border class if elements are found
    wrongAnswerElem.classList.add('border-active');
    correctAnswerElem.classList.add('border-active');

    const DIALOG_WIDTH_PLUS_MARGIN = 312; // dialog + margin
    // Only open dialog if none is open
    if (!this.dialogRef) {
      this.dialogRef = this.dialog.open(ResultDialogComponent, {
        data: { row, viewMode: this.question.viewMode },
        position: {
          top: `${rectForColumnRight.bottom + 5}px`,
          left: `${rectForColumnRight.right - DIALOG_WIDTH_PLUS_MARGIN}px`,
        },
        hasBackdrop: false // Disable backdrop to make it behave like a tooltip
      });
    } else {
      this.dialogRef.updatePosition({
        top: `${rectForColumnRight.bottom + 5}px`,
        left: `${rectForColumnRight.right - DIALOG_WIDTH_PLUS_MARGIN}px`,
      });
      this.dialogRef.componentRef.instance.data = { row, viewMode: this.question.viewMode };
    }
  }

  closeDialog(row: IAnswerSequenceCounter): void {
    if (this.dialogRef) {
      this.dialogRef.close();
      this.dialogRef = null;
      const wrongAnswerElem = this.cellListIncorrect().find(elem => elem.nativeElement.id === `incorrect-${row.id}`)?.nativeElement;
      const correctAnswerElem = this.cellListCorrect().find(elem => elem.nativeElement.id === `correct-${row.id}`)?.nativeElement;
      if (wrongAnswerElem && correctAnswerElem) {
        wrongAnswerElem.classList.remove('border-active');
        correctAnswerElem.classList.remove('border-active');
      }
    }
  }

}
