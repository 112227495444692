import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {QUESTION_VIEW_MODE} from '../../../shared/quiz-quiestion-types';

@Component({
  selector: 'app-result-dialog',
  templateUrl: './result-dialog.component.html',
  styleUrl: './result-dialog.component.scss'
})
export class ResultDialogComponent {

  public QUESTION_VIEW_MODE = QUESTION_VIEW_MODE;
  viewMode: string;
  answers: any;

  constructor(public dialogRef: MatDialogRef<ResultDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: { row, viewMode }) {
    dialogRef.addPanelClass(['timeline', 'questions-participants-detail']);
    this.viewMode = data.viewMode;
    this.answers = data.row;
  }

}
