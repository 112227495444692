import {Component, ElementRef, Injector} from '@angular/core';
import {AbstractQuizQuestionResultsComponent} from '../../shared/results/abstract-quiz-question-results-component';
import {cloneDeep, isEmpty} from 'lodash';
import {BehaviorSubject, debounceTime, filter} from 'rxjs';
import {IAnswer} from '../../../quiz-model/quiz';
import {Constants} from '../../../../../../../core/constants';

interface IPrioritizationAnswers extends IAnswer {
  isTied?: boolean;
  placement?: number;
  weightedTotal?: number;
}

@Component({
  selector: 'app-question-prioritization-results',
  templateUrl: './question-prioritization-results.component.html',
  styleUrl: './question-prioritization-results.component.scss',
})
export class QuestionPrioritizationResultsComponent extends AbstractQuizQuestionResultsComponent {

  dataSource: IPrioritizationAnswers[] = [];
  displayedColumns = ['prioritization', 'answer'];
  dataLoaded = false;
  private dataLoading$ = new BehaviorSubject(null);

  constructor(
    protected injector: Injector,
    protected elementRef: ElementRef) {
    super(injector, elementRef);
    this.dataLoading$.pipe(filter(v => !!v && !this.dataLoaded), debounceTime(50), this.takeUntilAlive())
      .subscribe(() => this.dataLoaded = true);
  }

  protected initQuestionAnswersDataSource() {
    this.dataSource = this.question.items
      .map(answer => new Object({
        id: answer.id,
        answer: answer.getAnswerByLanguage(this.languageParams),
        orderIndex: answer.orderIndex
      }) as IAnswer)
      .sort(this.common.utils.comparator(Constants.ORDERINDEX));
    this.handleRules();
    this.dataLoading$.next(true);
  }

  protected onReceiveQuestionAnswers() {
    this.handleRules();
    this.dataLoading$.next(true);
  }

  handleRules(): void {
    const isTied = (answerId: string) => Object.values(tiedPlaces).some(tiedItems => tiedItems.includes(answerId));

    const getPlacement = (answerId: string, index: number): number => {
      // Check if this answerId is tied
      if (tiedPlaces[index] && tiedPlaces[index].includes(answerId)) {
        return index; // Return the previous position if tied
      }
      return index + 1; // Otherwise return normal placement
    };

    if (isEmpty(this.summaryQuestionAnswers)) {
      this.dataSource.sort(this.common.utils.comparator(Constants.ORDERINDEX));
      this.dataSource.forEach((row, index) => {
        row.isTied = false;
        row.placement = index + 1;
        row.weightedTotal = 0;
      });
      this.dataSource = cloneDeep(this.dataSource.sort((a, b) => a.placement < b.placement ? -1 : 1));
      return;
    }
    // Initialize counting of occurrences for each id by position
    const occurrencesByPosition: { [answerId: string]: number[] } = {};
    const weightedTotalById: { [answerId: string]: number } = {};
    const tiedPlaces: { [position: number]: string[] } = {};
    const answers = this.summaryQuestionAnswers;
    this.dataSource.sort(this.common.utils.comparator(Constants.ORDERINDEX));

    this.dataSource.forEach(item => {
      occurrencesByPosition[item.id] = Array(answers.length).fill(0);
    });

    answers.forEach((position, index) => {
      Object.keys(position).forEach(answerId => {
        if (occurrencesByPosition[answerId]) {
          occurrencesByPosition[answerId][index] += position[answerId];  // Count occurrences per position
        }
      });
    });

    // 'Occurrences by Position:' occurrencesByPosition
    // Calculate the weighted sum for each answerId
    Object.keys(occurrencesByPosition).forEach(answerId => {
      const weightedTotal = occurrencesByPosition[answerId].reduce((acc, val, index) => {
        return acc + (val * (index + 1));
      }, 0);
      weightedTotalById[answerId] = weightedTotal;
    });

    // Sort the answerIds based on the weighted total in ascending order
    this.dataSource.sort((a, b) => weightedTotalById[a.id] < weightedTotalById[b.id] ? -1 : 1);

    // Get Tied Places
    let lastWeightedValue = null;
    let currentPlacement = 1;
    let currentTiedItems: string[] = [];

    this.dataSource.forEach((item, index) => {
      const currentWeightedValue = weightedTotalById[item.id];
      // If this is the same as the last item's weighted value, it's a tie
      if (currentWeightedValue === lastWeightedValue) {
        currentTiedItems.push(item.id);
        tiedPlaces[currentPlacement] = [...currentTiedItems];
      } else {
        // Reset the tied items for the next group
        currentTiedItems = [item.id];
        currentPlacement = index + 1; // Adjust placement based on the index (1-based)
      }
      // Update lastWeightedValue to be the current item's value for the next comparison
      lastWeightedValue = currentWeightedValue;
    });
    this.dataSource.forEach((row, index) => {
      row.isTied = isTied(row.id);
      row.placement = getPlacement(row.id, index);
      row.weightedTotal = weightedTotalById[row.id];
    });
    this.dataSource = cloneDeep(this.dataSource.sort((a, b) => a.placement < b.placement ? -1 : 1));
  }
}
