import {
  ChangeDetectorRef,
  Component,
  ComponentRef,
  ElementRef,
  Inject,
  Injector,
  OnInit,
  signal,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {CommonService} from '../../../../../core/common.service';
import {UploadService} from '../../../../../core/upload.service';
import {TimeLineService} from '../../../../../services/time-line.service';
import {TranslateApiService} from '../../../../../services/translate-api.service';
import {Constants, DEPENDENCY, ILanguageParams, TRIPLE, UNION_BY} from '../../../../../core/constants';
import {cloneDeep} from 'lodash';
import {EventQuestion, ISimpleQuestion} from '../../../../../model/EventQuestion';
import {IDocumentPathParams} from '../../../../../services/event-mode-api.service';
import {EventsDataService} from '../../../../../services/events-data.service';
import {IValidated, QUESTION_TYPE, QUESTION_TYPES_COMPONENTS} from '../quiz-components/shared/quiz-quiestion-types';
import {AbstractQuizQuestionEditorComponent} from '../quiz-components/shared/editor/abstract-quiz-question-editor-component';
import {BehaviorSubject, combineLatest, filter, take} from 'rxjs';
import {DependencyQuestion} from '../../../../../model/content/DependencyQuestion';
import {
  questionUseCheckingCorrectnessOfAnswer,
  quizAllTypes,
  quizTypesForRegistration
} from '../quiz-components/shared/lib/quiz-question-common-lib';
import {StdComponent} from '../../../../../core/std-component';
import {UtilsService} from '../../../../../core/utils.service';

@Component({
  selector: 'app-quiz-question-editor-dialog',
  templateUrl: './quiz-question-editor-dialog.component.html',
  styleUrls: ['./quiz-question-editor-dialog.component.scss']
})
export class QuizQuestionEditorDialogComponent extends StdComponent implements OnInit {

  readonly Constants = Constants;
  readonly UNION_BY = UNION_BY;
  readonly DEPENDENCY = DEPENDENCY;
  readonly QUESTION_TYPES_COMPONENTS = QUESTION_TYPES_COMPONENTS;

  languageParams: ILanguageParams;
  translateHint: string;
  translating = {};
  documentPathParams: IDocumentPathParams;
  question: EventQuestion;
  canChangeQuestionBody = true;
  questionNameList: ISimpleQuestion[] = [];
  questionTypesList: number[];
  editorComponentRef: ComponentRef<AbstractQuizQuestionEditorComponent>;
  loaded$ = new BehaviorSubject<boolean>(false);
  componentContainerLoaded$ = new BehaviorSubject<boolean>(false);
  validated: IValidated = {validated: true};
  isModify = false;
  questionHash;
  enableDirectFeedbackOptions = false;

  private componentContainerObserver = new MutationObserver(() => {
    if (this.componentContainerRef) {
      this.componentContainerObserver.disconnect();
      this.componentContainerLoaded$.next(true);
    }
  });

  private questionDetectChanges$ = new BehaviorSubject<boolean>(true);
  private beforeChangeStorypoint = signal<number>(null);
  private questionDetectChangesHandler = {
    onChange: this.questionDetectChanges$,
    beforeChangeStorypoint: this.beforeChangeStorypoint,
    set(target, key, val, receiver) {
      if (key === 'storypoint') {
        this.beforeChangeStorypoint.set(target.storypoint);
      }
      Reflect.set(target, key, val, receiver);
      this.onChange.next(true);
      return true;
    }
  };

  @ViewChild('componentContainer', { read: ViewContainerRef }) componentContainerRef: ViewContainerRef;

  constructor(protected injector: Injector,
              public dialogRef: MatDialogRef<QuizQuestionEditorDialogComponent>,
              public common: CommonService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public changeDetector: ChangeDetectorRef,
              public uploadService: UploadService,
              public timelineService: TimeLineService,
              private eventsDataService: EventsDataService,
              private translateApiService: TranslateApiService,
              private elementRef: ElementRef) {
    super(injector);
    this.componentContainerObserver.observe(this.elementRef.nativeElement, {childList: true});
    dialogRef.addPanelClass('timeline');
    dialogRef.disableClose = true;
    const obj = data.question != null ? cloneDeep(data.question) : {eventId: data.eventId, liveResults: true};
    this.validated.validated = data.question != null;
    this.questionTypesList = data.registrationQuestion ?
      quizTypesForRegistration() : quizAllTypes()
        .filter(t => !(this.common.getEnv().production && QUESTION_TYPES_COMPONENTS[t].productionDisabled));
    this.documentPathParams = data.documentPathParams;
    this.languageParams = cloneDeep(data.languageParams) ?? this.timelineService.DEFAULT_LANGUAGE_PARAMS;
    this.translateHint = this.translateApiService
      .getTranslateHint(this.languageParams.defaultLanguage, this.languageParams.currentLanguage);
    this.question = UtilsService.wrapObjectToProxy(new EventQuestion(obj), this.questionDetectChangesHandler);
    this.questionHash = UtilsService.md5(UtilsService.jsonSorted(this.question));
    data.questionNameList.forEach(q => {
      if (q.questionId !== this.question.id && QUESTION_TYPES_COMPONENTS[q.storypoint].canUseInDependency) {
        this.questionNameList.push(q);
      }
    });
    if (this.data.registrationQuestion) {
      this.loaded$.next(true);
    } else if (this.documentPathParams && this.question.id && Number.isInteger(this.question.storypoint)) {
      this.eventsDataService.checkQuizQuestionHasAnswers(this.question.id, this.question.storypoint, this.documentPathParams)
        .then(value => {
          this.canChangeQuestionBody = !value;
          this.loaded$.next(true);
        });
    }
    dialogRef.keydownEvents().pipe(this.takeUntilAlive())
      .subscribe(async event => {
        if (event.key === Constants.ESCAPE && this.isModify) {
          const closeType = await this.common.confirmationSaveChanged();
          if (closeType === TRIPLE.YES) {
            this.onOkClick();
          } else if (closeType === TRIPLE.OTHER) {
            return this.onNoClick();
          }
        } else if (event.key === Constants.ESCAPE && !this.isModify) {
          this.onNoClick();
        }
      });
  }

  set questionCaption(value: string) {
    this.question.setCaptionByLanguage(value, this.languageParams);
  }

  get questionCaption() {
    return this.question.getCaptionByLanguage(this.languageParams);
  }

  ngOnInit() {
    combineLatest([
      this.componentContainerLoaded$.pipe(filter(v => !!v)),
      this.loaded$.pipe(filter(v => !!v))
    ])
      .pipe(take(1)).subscribe(() => {
      this.initQuestionEditorComponent(this.question.storypoint);
      this.questionDetectChanges$.pipe(filter(v => !!v), this.takeUntilAlive())
        .subscribe(() => {
          this.isModify = this.questionHash !== UtilsService.md5(UtilsService.jsonSorted(this.question));
        });
    });
  }

  onOkClick(): void {
    this.dialogRef.close(this.question);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  translate(text: string, fieldName: string) {
    this.translating[fieldName] = true;
    this.translateApiService.translateSimpleString(text, this.languageParams.defaultLanguage, this.languageParams.currentLanguage)
      .then(v => this[fieldName] = v)
      .finally(() => delete this.translating[fieldName]);
  }

  validate() {
    return !(!Number.isInteger(this.question.storypoint)) && this.questionCaption;
  }

  initQuestionEditorComponent(questionType: number) {
    this.componentContainerRef.clear();
    this.editorComponentRef = null;
    if (!this.componentContainerRef || !Number.isInteger(questionType)) {
      return;
    }
    const editorComponentClass: any = QUESTION_TYPES_COMPONENTS[questionType].editorComponentClass;
    if (editorComponentClass) {
      this.editorComponentRef = this.componentContainerRef.createComponent(editorComponentClass);
      this.editorComponentRef.instance.onQuestionChange.pipe(this.takeUntilAlive())
        .subscribe(() => {
          this.validated = this.editorComponentRef.instance.validate();
          this.enableDirectFeedbackOptions = questionUseCheckingCorrectnessOfAnswer(this.question);
        });
      this.editorComponentRef.instance.init(this.question, this.languageParams);
      this.validated = this.editorComponentRef.instance.validate();
      this.enableDirectFeedbackOptions = questionUseCheckingCorrectnessOfAnswer(this.question);
    } else {
      this.validated.validated = true;
    }
  }

  private resetQuestion(questionType: number) {
    let obj;
    if ((this.beforeChangeStorypoint() === QUESTION_TYPE.CHECK && questionType === QUESTION_TYPE.CHECK_V2) ||
      (this.beforeChangeStorypoint() === QUESTION_TYPE.CHOICE && questionType === QUESTION_TYPE.CHOICE_V2)) {
      obj = cloneDeep(this.question.toObject());
    } else {
      obj = {
        id: this.question.id,
        storypoint: this.question.storypoint,
        eventId: this.question.eventId,
        timelineId: this.question.timelineId,
        orderIndex: this.question.orderIndex,
        caption: this.question.caption,
        liveResults: this.question.liveResults,
        items: [],
        matchingItems: [],
        correctEquality: [],
        taskText: null,
        answers: {},
        optional: this.question.optional,
        dependency: new DependencyQuestion(),
        directFeedback: null,
        tryAgain: null,
        correctFeedbackMessage: null,
        incorrectFeedbackMessage: null,
        showCorrectAnswers: null,
        useCorrectAnswers: null,
        options: {},
        files: []
      };
    }
    this.question = UtilsService.wrapObjectToProxy(new EventQuestion(obj), this.questionDetectChangesHandler);
    this.isModify = true;
  }

  changeStorypoint(questionType: number) {
    this.resetQuestion(questionType);
    this.initQuestionEditorComponent(questionType);
  }

  removeDependency(event) {
    this.question.dependency = new DependencyQuestion();
    event.cancelBubble = true;
  }

  get dependedQuestionItems() {
    const vm = this;
    const q = this.questionNameList.find(function (item) {
      if (item.questionId === vm.question.dependency.questionId) {
        return true;
      }
    });
    return q && q.items ? q.items : [];
  }

  setUnion(item): string {
    return item === UNION_BY.OR ?
      this.common.utils.i18n('edit_dialog.question_dialog.input.dependency.union.or') :
      this.common.utils.i18n('edit_dialog.question_dialog.input.dependency.union.and');
  }

  onLanguageChange(value) {
    this.languageParams.currentLanguage = value;
    this.translateHint = this.translateApiService
      .getTranslateHint(this.languageParams.defaultLanguage, this.languageParams.currentLanguage);
    if (this.editorComponentRef) {
      this.editorComponentRef.instance.setLanguage(this.languageParams);
    }
  }
}
