import {
  Component,
  ComponentRef,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {cloneDeep, isEmpty, union} from 'lodash';
import {SectionTimeline} from '../../../model/content/SectionTimeline';
import {TimeLineService} from '../../../services/time-line.service';
import {Constants} from '../../../core/constants';
import {SectionContent} from '../../../model/content/SectionContent';
import {BehaviorSubject, debounceTime, filter, Subject} from 'rxjs';
import {StdComponent} from '../../../core/std-component';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {MANUAL_SELECT_MODE, ReferenceItemComponent} from './reference-item/reference-item.component';
import {TimeLineEmulator} from '../../../time-line-emulator/time-line-emulator';

export interface IWarningDuration {
  [sectionId: string]: string;
}

export interface ISelectedMode {
  [sectionId: string]: MANUAL_SELECT_MODE;
}

interface ISectionNode {
  expandable: boolean;
  level: number;
  section: FilteredSectionTimeline;
}

@Component({
  selector: 'app-time-line-section-reference',
  templateUrl: './time-line-section-reference.component.html',
  styleUrls: ['./time-line-section-reference.component.scss']
})
export class TimeLineSectionReferenceComponent extends StdComponent implements OnInit {

  @Input() set mainSection(value: SectionContent) {
    this._mainSection = cloneDeep(value);
    if (this._mainSection.plannedTimeFixed && this._mainSection.plannedTime &&
      this._mainSection.endTimeFixed && this._mainSection.endTime &&
      !this._mainSection.durationFixed && !this._mainSection.duration) {
      this._mainSection.duration = (this._mainSection.endTime - this._mainSection.plannedTime) / Constants.ONE_MINUTE_MS;
    }
  }
  @Input() sectionReference: SectionContent[];
  @Input() selectedSectionList: SectionContent[] | SectionTimeline[];
  @Input() selectAll$: BehaviorSubject<boolean>;
  @Input() autoChooseDuration$: Subject<boolean>;
  @Input() expandAll$: BehaviorSubject<boolean>;
  @Output() onSelect = new EventEmitter<SectionTimeline | SectionContent>();
  @Output() onSelectList = new EventEmitter<SectionContent[] | SectionTimeline[]>();
  @Output() onWarning = new EventEmitter<boolean>();

  private _mainSection: SectionContent;
  sectionList: FilteredSectionTimeline[] = [];
  allSectionList: FilteredSectionTimeline[] = [];
  selectList: {[sectionId: string]: FilteredSectionTimeline} = {};
  selectList$ = new BehaviorSubject<{[sectionId: string]: FilteredSectionTimeline}>({});
  durationParentWarning$ = new BehaviorSubject<IWarningDuration>({});
  durationSelfWarning$ = new BehaviorSubject<IWarningDuration>({});
  manualSelected$ = new Subject<ISelectedMode>();
  usedTimeTitle: string[] = [];
  usedTimeOverflow = false;
  slotUsedDuration: number;
  checkTimeOverflow = false;
  timeLineEmulator: TimeLineEmulator;
  private _searchString: string;
  searchFilter$ = new BehaviorSubject<string>(null);

  treeControl = new FlatTreeControl<ISectionNode>(
    node => node.level,
    node => node.expandable,
  );

  treeFlattener = new MatTreeFlattener(
    (sectionNode: FilteredSectionTimeline, level: number) => {
      const existsectionNode = this.existNodeMap.get(sectionNode.id);
      let sn = existsectionNode ?? {} as ISectionNode;
      const expandable = !isEmpty(sectionNode.items);
      if (sn.expandable !== expandable) {
        sn = {} as ISectionNode;
      }
      sn.expandable = expandable;
      sn.level = level;
      sn.section = sectionNode;
      this.existNodeMap.set((sn as ISectionNode).section.id, sn as ISectionNode);
      return sn;
    },
    node => node.level,
    node => node.expandable,
    node => node.children,
  );

  existNodeMap = new Map<string, ISectionNode>();

  sectionTreeDataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);

  sectionComponentList: {[id: string]: ComponentRef<ReferenceItemComponent>} = {};

  loading = true;

  private elementObserver = new MutationObserver(() => {
    if (this.sectionItemComponentContainerRef) {
      this.init();
      this.elementObserver.disconnect();
      this.loading = false;
    }
  });


  @ViewChild('sectionItemComponentContainer', { read: ViewContainerRef }) sectionItemComponentContainerRef: ViewContainerRef;

  constructor(protected injector: Injector,
              public timeLineService: TimeLineService,
              private elementRef: ElementRef) {
    super(injector);
    this.elementObserver.observe(this.elementRef.nativeElement, {childList: true});
  }

  get mainSection(): SectionContent {
    return this._mainSection;
  }

  ngOnInit(): void {
    this.searchFilter$.pipe(filter(v => v !== null), debounceTime(100), this.takeUntilAlive())
      .subscribe(() => {
        this.searchFilter();
      });
    this.expandAll$.pipe(this.takeUntilAlive())
      .subscribe(value => {
        if (value) {
          this.treeControl.expandAll();
        } else {
          this.treeControl.collapseAll();
        }
      });
  }

  get searchString(): string {
    return this._searchString;
  }

  set searchString(value: string) {
    this._searchString = value;
    this.searchFilter$.next(value);
  }

  init() {
    this.createList(this.sectionReference);
    this.calcSlotUsedFixedDuration();
    this.changeCountSummary();
    if (this.selectAll$) {
      this.selectAll$.pipe(filter(v => v !== null), this.takeUntilAlive()).subscribe(value => {
        this.selectList = {};
        if (value) {
          this.sectionList.forEach(section => {
            this.selectList[section.id] = section;
          });
        } else {
          this.durationParentWarning$.next({});
          this.durationSelfWarning$.next({});
        }
        this.checkAll();
        this.onSelectList.emit(Object.values(this.selectList));
        this.selectList$.next(this.selectList);
      });
    }
    if (this.autoChooseDuration$) {
      this.autoChooseDuration$.pipe(this.takeUntilAlive()).subscribe(value => {
        if (value) {
          this.autoChooseDuration();
        }
      });
    }
  }

  hasChild = (_: number, node: ISectionNode) => node.expandable;

  mainFilter = (s: SectionTimeline) => !s.isRoot && s.parentId === this.timeLineService.rootSection.id;

  createList(list: SectionContent[]) {
    this.timeLineEmulator = new TimeLineEmulator(this.timeLineService, null,
      null, this.timeLineService.utils, this.timeLineService.currentUser, true, null, false);
    this.timeLineEmulator.refreshEmulatorTimeline(this.timeLineService.event,
      this.timeLineService.currentUser, true, list.map(s => new SectionContent(cloneDeep(s))));

    this.allSectionList = this.timeLineEmulator.planeListContentSortedWithoutFixed()
      .map(p => new FilteredSectionTimeline(new Object({...p.sectionContent, items: []}) as SectionTimeline));

    for (const fs of this.allSectionList) {
      if (!fs.isRoot) {
        const parent = this.allSectionList.find(s => s.id === fs.parentId);
        parent?.pushItem(fs);
      }
    }
    this.sectionList = this.allSectionList.filter(s => !s.isRoot);
    for (const section of this.sectionList) {
      const cRef = this.sectionItemComponentContainerRef.createComponent(ReferenceItemComponent);
      cRef.instance.section = section;
      cRef.instance.mainSection = this.mainSection;
      cRef.instance.selectList$ = this.selectList$;
      cRef.instance.durationParentWarning$ = this.durationParentWarning$;
      cRef.instance.durationSelfWarning$ = this.durationSelfWarning$;
      cRef.instance.manualSelected$ = this.manualSelected$;
      cRef.instance.allSectionList = this.allSectionList;
      cRef.instance.onSelect.pipe(this.takeUntilAlive()).subscribe(value => this.onSelectSection(value.section, value.selectMode));
      cRef.instance.onChangeDuration.pipe(this.takeUntilAlive()).subscribe(() => this.checkAll());
      cRef.instance.init();
      this.sectionComponentList[section.id] = cRef;
    }
    this.sectionTreeDataSource.data = this.allSectionList.filter(s => this.mainFilter(s));
  }

  resetSectionWarning(section: SectionTimeline) {
    const warnp = this.durationParentWarning$.getValue();
    delete warnp[section.id];
    this.durationParentWarning$.next(warnp);
    const warns = this.durationSelfWarning$.getValue();
    delete warns[section.id];
    this.durationSelfWarning$.next(warns);
  }

  checkAll() {
    if (this.mainSection.freeSlotType) {
      for (const section of this.sectionList) {
        this.checkParentDuration(section);
        this.checkSelfDuration(section);
      }
      this.changeCountSummary();
    }
  }

  onSelectSection(section: SectionTimeline, selectMode: MANUAL_SELECT_MODE) {
    this.onSelect.emit(section);
    switch (selectMode) {
      case MANUAL_SELECT_MODE.UNSELECT:
        delete this.selectList[section.id];
        this.resetSectionWarning(section);
        this.selectAllChild(section, true);
        break;
      case MANUAL_SELECT_MODE.SQUARE:
        if (!this.selectList[section.id]) {
          this.manualSelected$.next({[section.id]: MANUAL_SELECT_MODE.SQUARE});
          this.selectList[section.id] = this.sectionList.find(o => o.id === section.id);
        }
        break;
      case MANUAL_SELECT_MODE.CHECK:
        if (!this.selectList[section.id]) {
          this.selectList[section.id] = this.sectionList.find(o => o.id === section.id);
        }
        this.selectAllChild(section, false);
        break;
    }
    this.checkAll();
    this.onSelectList.emit(Object.values(this.selectList));
    this.selectList$.next(this.selectList);
  }

  selectAllChild(parent: SectionTimeline, unselect: boolean, selectedOnly?: boolean) {
    const checkChild = (p) => {
      const chIds = p.items.filter(it => selectedOnly ? this.selectList[it.id] : true).map(o => o.id);
      const childList = this.sectionList.filter(s => chIds.includes(s.id)) ;
      for (const child of childList) {
        if (!unselect) {
          this.selectList[child.id] = child;
        } else {
          delete this.selectList[child.id];
          this.resetSectionWarning(child);
        }
        checkChild(child);
      }
    };

    const ids = parent.items.filter(it => selectedOnly ? this.selectList[it.id] : true).map(o => o.id);
    const childItems = this.sectionList.filter(s => ids.includes(s.id)) ;
    if (!isEmpty(childItems)) {
      for (const child of childItems) {
        if (!unselect) {
          this.selectList[child.id] = child;
        } else {
          delete this.selectList[child.id];
          this.resetSectionWarning(child);
        }
        checkChild(child);
      }
    }
  }

  private sumDurationBySelectedBelowHierarchical(section: FilteredSectionTimeline) {
    const sumDuration = (items: FilteredSectionTimeline[]) => {
      let sd = 0;
      for (const it of items) {
        if (it.duration && this.selectList[it.id]) {
          sd += it.durationTime();
        } else {
          sd += sumDuration(it.items as FilteredSectionTimeline[]);
        }
      }
      return sd;
    };
    return sumDuration(section.items as FilteredSectionTimeline[]);
  }

  checkParentDuration(section: FilteredSectionTimeline) {
    let parent = this.allSectionList.find(s => s.id === section.parentId);
    while (!this.selectList[parent.id] && !!parent.parentId) {
      parent = this.allSectionList.find(s => s.id === parent.parentId);
    }
    const summaryDuration = this.sumDurationBySelectedBelowHierarchical(parent);
    const warn = this.durationParentWarning$.getValue();
    if (!parent.isRoot && this.selectList[parent.id] && summaryDuration > parent.durationTime()) {
      warn[parent.id] = parent.id;
      this.durationParentWarning$.next(warn);
    } else {
      delete warn[parent.id];
      this.durationParentWarning$.next(warn);
    }
  }

  checkSelfDuration(section: FilteredSectionTimeline) {
    const summaryDuration = this.sumDurationBySelectedBelowHierarchical(section);
    const warn = this.durationSelfWarning$.getValue();
    if (this.selectList[section.id] && summaryDuration > section.durationTime()) {
      warn[section.id] = section.id;
      this.durationSelfWarning$.next(warn);
    } else {
      delete warn[section.id];
      this.durationSelfWarning$.next(warn);
    }
  }

  private sumSelectedChildrenByHierarchical(section: SectionTimeline, excludeIds: {}) {
    const exludeAllChildren = (list: SectionTimeline[], excluded: {}) => {
      for (const it of list) {
        excluded[it.id] = true;
        exludeAllChildren(it.items, excluded);
      }
    };
    const sumDuration = (items: SectionTimeline[]) => {
      let sd = 0;
      const warnp = this.durationParentWarning$.getValue();
      const warns = this.durationSelfWarning$.getValue();
      for (const it of items) {
        excludeIds[it.id] = true;
        if (warnp[it.id] || warns[it.id]) {
          sd += sumDuration(it.items);
        } else
        if (it.duration && this.selectList[it.id]) {
          sd += it.durationTime();
          exludeAllChildren(it.items, excludeIds);
        } else {
          sd += sumDuration(it.items);
        }
      }
      return sd;
    };
    return sumDuration(section.items);
  }

  private calcSelectDuration() {
    const excludeIds = {};
    let sum = 0;
    for (const section of this.sectionList) {
      if (excludeIds[section.id] || !this.selectList[section.id]) {
        continue;
      }
      const itemsDuration = this.sumSelectedChildrenByHierarchical(section, excludeIds);
      if (section.durationTime() >= itemsDuration) {
        sum += section.durationTime();
      } else if (section.durationTime() < itemsDuration) {
        sum += itemsDuration;
      }
    }
    this.usedTimeOverflow = sum > (!this.mainSection.durationTime() ? 0 : (this.mainSection.durationTime() - this.slotUsedDuration));
    this.checkTimeOverflow = this.usedTimeOverflow || !isEmpty(this.durationParentWarning$.getValue()) ||
      !isEmpty(this.durationSelfWarning$.getValue());
    this.onWarning.emit(this.checkTimeOverflow);
    return sum / Constants.ONE_MINUTE_MS;
  }

  private autoChooseDuration() {
    while (this.usedTimeOverflow || !isEmpty(this.durationParentWarning$.getValue()) || !isEmpty(this.durationSelfWarning$.getValue())) {
      const minSection = Object.values(this.selectList)
        .sort(this.timeLineService.utils.comparator('duration'))
        .filter(s => !!s.duration)
        .reduce((acc, item) => {
          acc = !acc || acc.duration > item.duration ? item : acc;
          return acc;
        }, null);
      if (minSection) {
        minSection.duration = 0;
      }
      this.checkAll();
    }
  }

  changeCountSummary() {
    this.usedTimeTitle = [];
    this.usedTimeTitle.push(this.timeLineService.utils.i18n('common.used') + ': ');
    this.usedTimeTitle.push(this.calcSelectDuration() + '   ');
    this.usedTimeTitle.push(this.timeLineService.utils.i18n('common.available') + ': ' +
      ((this.mainSection.durationTime() - (this.slotUsedDuration ?? 0)) / Constants.ONE_MINUTE_MS)  + '   ');
    this.usedTimeTitle.push(this.timeLineService.utils.i18n('common.total') + ': '  + (this.mainSection.duration ?? 0));
  }

  calcSlotUsedFixedDuration() {
    const sumDuration = (section: SectionTimeline) => {
      let sum = 0;
      for (const s of section.items) {
        if (s.durationFixed) {
          sum += s.durationTime();
        } else {
          sum += sumDuration(s);
        }
      }
      return sum;
    };

    this.slotUsedDuration = sumDuration(this.timeLineService.planeListContent[this.mainSection.id].sectionContent);
  }

  private searchFilter() {
    const updateComponents = (ids: string[]) => Object.keys(this.sectionComponentList)
      .forEach(id => this.sectionComponentList[id].instance.inSearchResult$.next(ids.includes(id)));

    if (!this._searchString) {
      this.allSectionList.forEach(s => s.filter = []);
      this.sectionTreeDataSource.data = this.allSectionList.filter(s => this.mainFilter(s));
      updateComponents([]);
    } else {
      const listIncludesSearchString = this.allSectionList.filter(s => (s.title || '')
        .toLowerCase().includes(this._searchString.toLowerCase()));
      let uniqueIds = listIncludesSearchString.map(s => s.id);
      updateComponents(uniqueIds);
      for (const s of listIncludesSearchString) {
        const parentsIds = this.timeLineEmulator.getSectionHierarchicalParents(s);
        const childrenIds = this.timeLineEmulator.childTreeInLineList(s.id).map(p => p.id);
        uniqueIds = union(uniqueIds, parentsIds, childrenIds);
      }
      this.allSectionList.forEach(s => s.filter = uniqueIds);
      this.sectionTreeDataSource.data = this.allSectionList
        .filter(s => uniqueIds.includes(s.id))
        .filter(s => this.mainFilter(s));
    }
  }

}

export class FilteredSectionTimeline extends SectionTimeline {
  private _filter: string[] = [];
  private _children: FilteredSectionTimeline[] = [];
  constructor(obj: SectionTimeline) {
    super(obj);
  }

  get children() {
    return this._children.filter(s => isEmpty(this._filter) ? true : this._filter.includes(s.id));
  }

  pushItem(item: FilteredSectionTimeline) {
    this.items.push(item);
    this.items = this.items.sort((a, b) => a.orderIndex > b.orderIndex ? 1 : -1);
    this._children = this.items as FilteredSectionTimeline[];
  }

  get filter(): string[] {
    return this._filter;
  }

  set filter(value: string[]) {
    this._filter = value;
    (this.children || []).forEach(ch => ch.filter = value);
  }
}
