import {Component, Injector} from '@angular/core';
import {AbstractQuizQuestionAnswersEditorComponent} from '../../shared/editor/abstract-quiz-question-answers-editor-component';
import {isEmpty} from 'lodash';
import {isEmptyCaption} from '../../shared/lib/quiz-question-common-lib';
import {IValidated} from '../../shared/quiz-quiestion-types';

@Component({
  selector: 'app-question-prioritization-editor',
  templateUrl: './question-prioritization-editor.component.html',
  styleUrl: './question-prioritization-editor.component.scss'
})
export class QuestionPrioritizationEditorComponent extends AbstractQuizQuestionAnswersEditorComponent {

  displayedColumns: string[] = ['prioritization', 'answer'];

  constructor(protected injector: Injector) {
    super(injector);
  }

  validate(): IValidated {
    if (isEmpty(this.dataSource.data)) {
      return { validated: false, warning: this.common.i18n('question.editor.warning.at.least.one.answer.is.required') };
    }
    if (this.dataSource.data.some(o => isEmptyCaption(o.answer))) {
      return { validated: false, warning: this.common.i18n('question.editor.warning.the.answer.cannot.be.empty') };
    }
    return { validated: true };
  }

}
