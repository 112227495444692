import {AfterViewInit, Component, ElementRef, Inject, Injector, OnInit, ViewChild} from '@angular/core';
import {BehaviorSubject, filter, firstValueFrom, take} from 'rxjs';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {CommonService} from '../../../../../core/common.service';
import {UtilsService} from '../../../../../core/utils.service';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../../../reducers';
import {TimeLineService} from '../../../../../services/time-line.service';
import * as ui from '../../../../../actions/ui';
import {ANONYMOUS_ANSWERS_MODE, IQuizQuestionMap, Quiz, QUIZ_FONT_SIZE, QUIZ_FONT_SIZE_VALUES, TOOLBAR_BEHAVIOR} from '../quiz-model/quiz';
import {Constants, ILanguageParams, TEMPLATE_TYPE, TRIPLE} from '../../../../../core/constants';
import {cloneDeep, isEmpty, max, merge} from 'lodash';
import {EventsDataService} from '../../../../../services/events-data.service';
import {IDocumentPathParams} from '../../../../../services/event-mode-api.service';
import {ISimpleQuestion} from '../../../../../model/EventQuestion';
import {MatSelect} from '@angular/material/select';
import {FocusMonitor} from '@angular/cdk/a11y';
import {QuizQuestionEditorDialogComponent} from '../quiz-question-editor-dialog/quiz-question-editor-dialog.component';
import {StdComponent} from '../../../../../core/std-component';
import {QuizService} from '../quiz.service';
import {EditDialogComponent} from '../../../../../dialog-components/edit-dialog/edit-dialog.component';
import {IQuizTemplate} from '../quiz-components/shared/quiz-quiestion-types';

@Component({
  selector: 'app-quiz-question-sheet-editor-dialog',
  templateUrl: './quiz-question-sheet-editor-dialog.component.html',
  styleUrls: ['./quiz-question-sheet-editor-dialog.component.scss']
})
export class QuizQuestionSheetEditorDialogComponent extends StdComponent implements OnInit, AfterViewInit {

  readonly ANONYMOUS_ANSWERS_MODE = ANONYMOUS_ANSWERS_MODE;
  readonly QUIZ_FONT_SIZE_VALUES = QUIZ_FONT_SIZE_VALUES;
  readonly QUIZ_FONT_SIZE = QUIZ_FONT_SIZE;
  readonly TEMPLATE_TYPE = TEMPLATE_TYPE;
  readonly TOOLBAR_BEHAVIOR = TOOLBAR_BEHAVIOR;

  questionList: IQuizQuestionMap = {};
  qContent: Quiz;
  questionKeyList: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  documentPathParams: IDocumentPathParams;
  dirty: boolean;
  languageParams: ILanguageParams;
  isSuperUser = false;
  canChangeAnonymousType = true;
  isRegistrationQuestion = false;
  dataHash: string;
  isModify = false;

  isCreateFromTemplate = false;
  editTemplate: IQuizTemplate;
  editTemplateData: any;
  templateHash: string = null;
  templateValidated = true;
  templateTypes = Object.keys(TEMPLATE_TYPE).map(it => ({
    name: this.common.i18n(`quiz.template.type.${TEMPLATE_TYPE[it]}`),
    value: TEMPLATE_TYPE[it]
  }));

  loading = false;
  dataDetectChanges = new BehaviorSubject<boolean>(false);
  dataChangesHandler = {
    detectChanges: this.dataDetectChanges,
    set(target, key, val, receiver) {
      Reflect.set(target, key, val, receiver);
      this.detectChanges.next(true);
      return true;
    }
  };

  @ViewChild('anonymousAnswersModeSelect') anonymousAnswersModeMatSelect: MatSelect;

  constructor(protected injector: Injector,
              public dialogRef: MatDialogRef<QuizQuestionSheetEditorDialogComponent>,
              private common: CommonService,
              private utils: UtilsService,
              public dialog: MatDialog,
              public dataService: EventsDataService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public store: Store<fromRoot.State>,
              public timeLineService: TimeLineService,
              private eventsDataService: EventsDataService,
              private focusMonitor: FocusMonitor,
              private elementRef: ElementRef,
              private quizService: QuizService) {
    super(injector);
    this.store.dispatch(new ui.SetModalDialogOpen(true));
    dialogRef.addPanelClass('timeline');
    dialogRef.disableClose = true;
    this.isSuperUser = this.timeLineService.isSuperAdmin(this.timeLineService.currentUser);
    this.documentPathParams = this.data.documentPathParams;
    this.dirty = this.data.dirty;
    this.isCreateFromTemplate = this.data.isCreateFromTemplate;
    this.isModify = this.isCreateFromTemplate;
    this.isRegistrationQuestion = this.data.registrationQuestion;
    this.data.languageParams$
      .pipe(filter(p => !isEmpty(p)), take(1))
      .subscribe(params => this.languageParams = params);

    this.init(cloneDeep(this.data.quiz), cloneDeep(this.data.editTemplate));

    dialogRef.keydownEvents().pipe(this.takeUntilAlive())
      .subscribe(async event => {
        if (event.key === Constants.ESCAPE && this.isModify) {
          const closeType = await this.common.confirmationSaveChanged();
          if (closeType === TRIPLE.YES) {
            this.onOkClick();
          } else if (closeType === TRIPLE.OTHER) {
            return this.onNoClick();
          }
        } else if (event.key === Constants.ESCAPE && !this.isModify) {
          this.onNoClick();
        }
      });
  }

  anonymousAnswersModeChange(value) {
    if (value !== ANONYMOUS_ANSWERS_MODE.NON_ANONYMOUS) {
      this.qContent.anonymousAnswersMode = ANONYMOUS_ANSWERS_MODE.NON_ANONYMOUS;
      this.anonymousAnswersModeMatSelect.value = ANONYMOUS_ANSWERS_MODE.NON_ANONYMOUS;
      this.common.confirm(this.common.i18n('edit_dialog.confirm_dialogon.change.anonymousanswers.header')
        , this.common.i18n('edit_dialog.confirm_dialogon.change.anonymousanswers.body')).pipe(
        take(1)
      ).subscribe(result => {
        if (result) {
          this.qContent.anonymousAnswersMode = value;
        }
      });
    }
  }

  ngOnInit() {
    this.dataDetectChanges.pipe(filter(() => !!this.dataHash), this.takeUntilAlive())
      .subscribe(() => {
        if (!this.editTemplate) {
          this.isModify = !this.isCreateFromTemplate ? this.dataHash !== UtilsService.md5(UtilsService.jsonSorted(this.qContent)) : true;
        } else {
          this.isModify = this.dataHash !== UtilsService.md5(UtilsService.jsonSorted(this.qContent)) ||
            this.templateHash !== UtilsService.md5(UtilsService.jsonSorted(this.editTemplate));
        }
      });
  }

  ngAfterViewInit() {
    const panels: HTMLCollection = this.elementRef.nativeElement.getElementsByTagName('mat-expansion-panel-header');
    for (let i = 0; i < panels.length; i++) {
      const htmlPanel = panels.item(i) as HTMLElement;
      htmlPanel.tabIndex = -1;
      this.focusMonitor.stopMonitoring(htmlPanel);
    }
    const buttons: HTMLCollection = this.elementRef.nativeElement.getElementsByTagName('button');
    for (let i = 0; i < buttons.length; i++) {
      const htmlButton = buttons.item(i) as HTMLElement;
      htmlButton.tabIndex = -1;
      this.focusMonitor.stopMonitoring(htmlButton);
    }
  }

  init(quiz: Quiz, template: IQuizTemplate) {
    this.qContent = UtilsService.wrapObjectToProxy(quiz.questions ? merge(quiz, {questions: {}}) : quiz, this.dataChangesHandler);
    if (template) {
      this.editTemplate = UtilsService.wrapObjectToProxy(template, this.dataChangesHandler);
      this.editTemplateData = this.getEditTemplateData(this.data.editTemplate);
      this.templateHash = UtilsService.md5(UtilsService.jsonSorted(this.editTemplate));
    }
    this.dataHash = UtilsService.md5(UtilsService.jsonSorted(this.qContent));
    this.questionList = this.qContent.questions;
    this.refreshQuestionList();
    if (this.documentPathParams && !isEmpty(this.qContent.questions) &&
      (this.qContent.isAnonymousAnswers() || this.qContent.isHideResultNames())) {
      this.eventsDataService.checkQuizHasAnswers(this.documentPathParams)
        .then(r => this.canChangeAnonymousType = !r);
    }
  }

  onDestroy() {
    this.store.dispatch(new ui.SetModalDialogOpen(false));
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onOkClick(): void {
    if (!this.editTemplate) {
      this.qContent.questions = this.questionList;
      this.dialogRef.close({content: this.qContent});
    } else {
      this.saveTemplate(this.editTemplate);
    }
  }

  addQuestionDialog() {
    const dialogRef = this.dialog.open(QuizQuestionEditorDialogComponent, {
      width: '550px'
      , minWidth: '370px'
      , data: {
        questionNameList: this.questionNameList(),
        languageParams: this.languageParams,
        registrationQuestion: this.isRegistrationQuestion
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.questionList[result.id] = result;
        this.questionList[result.id].orderIndex = this.genAnswerOrderIndex();
        this.refreshQuestionList();
        this.qContent.questions = this.questionList;
      }
    });
  }

  onEditQuestion(qKey: string) {
    const question = this.questionList[qKey];
    if (!question) {
      return;
    }
    const dialogRef = this.dialog.open(QuizQuestionEditorDialogComponent, {
      width: '550px'
      , minWidth: '370px'
      , data: {
        question: question,
        questionNameList: this.questionNameList(),
        languageParams: this.languageParams,
        documentPathParams: this.documentPathParams,
        registrationQuestion: this.isRegistrationQuestion
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.questionList[result.id] = result;
        this.questionList[qKey] = result;
        this.qContent.questions = this.questionList;
      }
    });
  }

  private checkDeletedConstraint(qKey: string) {
    return this.dataService.getQuizConstraint(this.documentPathParams)
      .then(constraint => {
        if (!isEmpty(constraint)) {
          if (!isEmpty((constraint[this.documentPathParams.containerId] || {})[qKey]?.contents)) {
            this.common.showPopupError(this.common.i18n('edit_dialog.can_not_remove_questionnaire_dialog.body'));
            return false;
          }
        }
        return true;
      });
  }

  async onDeleteQuestion(qKey: string) {
    if (this.isRegistrationQuestion || (this.documentPathParams && await this.checkDeletedConstraint(qKey))) {
      if (this.questionList[qKey]) {
        delete this.questionList[qKey];
        this.refreshQuestionList();
        this.qContent.questions = this.questionList;
      }
    }
  }

  refreshQuestionList() {
    this.questionKeyList.next(Object.values(this.questionList).sort(this.utils.comparator(Constants.ORDERINDEX)).map(o => o.id));
  }

  onMoveUpDown(move: number, qKey: string) {
    const keyList = this.questionKeyList.getValue();
    const currentIndex = keyList.indexOf(qKey);
    const tmpKey = keyList[currentIndex + move];
    keyList[currentIndex + move] = qKey;
    keyList[currentIndex] = tmpKey;
    keyList.forEach((id, index) => this.questionList[id].orderIndex = (index + 1) * 100);
    this.refreshQuestionList();
    this.qContent.questions = this.questionList;
  }

  genAnswerOrderIndex(): number {
    return max(Object.values(this.questionList).map(o => o.orderIndex)) + 100;
  }

  questionNameList(): ISimpleQuestion[] {
    const result = [];
    this.utils.objectValues(this.questionList).forEach(item =>
      result.push({questionId: item['id'], caption: item['caption'], optional: item['optional'],
        storypoint: item['storypoint'], items: item['items']}) );
    return result;
  }

  getEditTemplateData(template: IQuizTemplate, isNew?: boolean) {
    const accessAllTemplates = this.timeLineService.currentUser.isSuperAdmin ||
      this.timeLineService.currentUser.isModerator || this.timeLineService.currentUser.isAdmin;
    const templateTypes = accessAllTemplates ? this.templateTypes : this.templateTypes.filter(t => t.value === TEMPLATE_TYPE.USER_TEMPLATE);
    return {
      fields: [
        {
          id: 'type', type: 'select', placeholder: this.common.i18n('quiz.template.type'),
          hidden: false,
          options: templateTypes,
          margin_top: '10px',
          disabled: !isNew
        },
        {
          id: 'title', type: 'text', required: true, placeholder: this.common.i18n('common.title'),
          isIconSVG: true,
          iconFieldName: 'icon',
          iconFieldTitle: this.common.utils.i18n('quiz.template.icon'),
          iconFieldValuesList: [null, ...(Array(13).fill('icon_').map((v, i) => `${v}${i < 9 ? '0' + (i + 1) : (i + 1).toString()}`))]
        },
        {id: 'description', type: 'text', placeholder: this.common.i18n('common.description')},
      ]
      , result: {
        title: template?.title ?? null,
        description: template?.description ?? null,
        type: template?.type ?? TEMPLATE_TYPE.USER_TEMPLATE,
        icon: template?.icon ?? null
      }
    };
  }

  async saveAsTemplate() {
    const dialogTitle = this.common.i18n('quiz.add.template');
    const dialogData = {title: dialogTitle, ...this.getEditTemplateData(this.editTemplate, true)};
    return await firstValueFrom(this.common.dialog.open(EditDialogComponent, {
      width: '450px',
      data: dialogData
    }).afterClosed())
      .then(result => {
        if (result && result.title) {
          const template: IQuizTemplate = {
            title: result.title,
            description: result.description,
            icon: result.icon,
            type: result.type
          };
          this.loading = true;
          return this.quizService.saveTemplate(template, this.qContent, this.documentPathParams, this.dirty)
            .then(() => {
              this.common.showPopupSuccess(this.common.i18n('common.dialog.success'));
              if (this.editTemplate) {
                this.dialogRef.close();
              }
            })
            .finally(() => this.loading = false);
        }
      });
  }

  async saveTemplate(template: IQuizTemplate) {
    this.loading = true;
    return this.quizService.saveTemplate(template, this.qContent, this.documentPathParams, this.dirty)
      .then(() => {
        this.common.showPopupSuccess(this.common.i18n('common.dialog.success'));
        this.dialogRef.close();
      })
      .finally(() => this.loading = false);
  }

  onChangeTemplateSettings(values) {
    Object.keys(values).forEach(field => this.editTemplate[field] = values[field]);
  }

  validateTemplateSetings(value: boolean) {
    this.templateValidated = value;
  }
}
