import {Component, Injector} from '@angular/core';
import {AbstractQuizQuestionAnswersEditorComponent} from '../../shared/editor/abstract-quiz-question-answers-editor-component';
import {isEmpty} from 'lodash';
import {ILanguageParams} from '../../../../../../../core/constants';
import {EventQuestion} from '../../../../../../../model/EventQuestion';
import {isEmptyCaption} from '../../shared/lib/quiz-question-common-lib';
import {IValidated} from '../../shared/quiz-quiestion-types';

@Component({
  selector: 'app-question-sequence-editor',
  templateUrl: './question-sequence-editor.component.html',
  styleUrl: './question-sequence-editor.component.scss'
})
export class QuestionSequenceEditorComponent extends AbstractQuizQuestionAnswersEditorComponent {

  displayedColumns: string[] = ['sequenceNumber', 'answer'];

  constructor(protected injector: Injector) {
    super(injector);
  }

  init(question: EventQuestion, languageParams: ILanguageParams) {
    super.init(question, languageParams);
    this.question.useCorrectAnswers = true;
  }

  validate(): IValidated {
    if (isEmpty(this.dataSource.data)) {
      return { validated: false, warning: this.common.i18n('question.editor.warning.at.least.one.answer.is.required') };
    }
    if (this.dataSource.data.some(o => isEmptyCaption(o.answer))) {
      return { validated: false, warning: this.common.i18n('question.editor.warning.the.answer.cannot.be.empty') };
    }
    return { validated: true };
  }
}
