import {EventQuestion} from '../../../../../../../model/EventQuestion';
import {ILanguageParams, SMILES_RATING_SETS} from '../../../../../../../core/constants';
import {cloneDeep, isEmpty, uniq} from 'lodash';

export const tooltipQuestionTypeText = (question: EventQuestion, answers: any): string => {
  return answers;
};

export const tooltipQuestionTypeTextBalloons = (question: EventQuestion, answers: any): string => {
  return answers?.a;
};

export const tooltipQuestionTypeFileUpload = (question: EventQuestion, answers: any, languageParams, utils): string => {
  return answers?.f.length ? utils.i18n('questionnaire.upload.file.result.tooltip', {count: answers?.f.length}) : null;
};

export const tooltipQuestionTypeMatching = (question: EventQuestion, answers: any, languageParams: ILanguageParams): string => {
  let answersText = '';
  for (const answer of (answers || [])) {
    const arr: string[] = answer.split('-');
    const groupId = arr[0];
    const groupCaption = question.items.find(it => it.id === groupId)?.getAnswerByLanguage(languageParams);
    const matchingList = arr.filter(id => id !== groupId)
      .map(id => question.matchingItems.find(m => m.id === id)?.getAnswerByLanguage(languageParams)).join(',');
    if (!isEmpty(groupCaption) && !isEmpty(matchingList)) {
      answersText = answersText + '[' + `<b>${groupCaption}</b>` + ' - ' + matchingList + ']';
    }
  }
  return answersText;
};

export const tooltipQuestionTypeMatchingMap = (question: EventQuestion, answers: any, languageParams: ILanguageParams): string => {
  let answersText = '';
  const correctList = uniq((question.correctEquality || []).map(o => o.id));
  for (const crId of correctList) {
    const a = answers.find(o => o.includes(crId));
    if (a) {
      const aId = a.split('=')[1];
      const at = question.items.find(o => o.id === aId);
      if (at) {
        answersText = answersText + (!answersText ? '' : ', ') + at.getAnswerByLanguage(languageParams);
      }
    } else {
      answersText = answersText + (!answersText ? '' : ', ') + '_ ';
    }
  }
  const hasAnswer = !!answersText.split(',').join('').split('_').join('').trim().length;
  return hasAnswer ? answersText : null;
};

export const tooltipQuestionTypeSelectGapFilling = (question: EventQuestion, answers: any, languageParams: ILanguageParams): string => {
  // get gap order
  const span = document.createElement('span');
  span.innerHTML = question.getTaskTextByLanguage(languageParams);
  const sList = span.getElementsByTagName('select');
  const gapIds = [];
  let answersText = '';
  for (let i = 0; i < sList.length; i++) {
    gapIds.push((sList.item(i) as HTMLElement).getAttribute('id'));
  }
  // get gap answers
  for (const gapId of gapIds) {
    const a = (answers || []).find(o => o.startsWith(gapId));
    if (a) {
      answersText = answersText + (!answersText ? '' : ', ') + a.split('=').filter(id => id !== gapId)
        .map(aId => question.items.find(o => o.id === aId)?.getAnswerByLanguage(languageParams)).join(', ');
    } else {
      answersText = answersText + (!answersText ? '' : ', ') + '_ ';
    }
  }
  const hasAnswer = !!answersText.split(',').join('').split('_').join('').trim().length;
  return hasAnswer ? answersText : null;
};

export const tooltipQuestionTypeTextGapFilling = (question: EventQuestion, answers: any, languageParams: ILanguageParams): string => {
  // get gap order
  const span = document.createElement('span');
  span.innerHTML = question.getTaskTextByLanguage(languageParams);
  const sList = span.getElementsByTagName('input');
  const gapIds = [];
  let answersText = '';
  for (let i = 0; i < sList.length; i++) {
    gapIds.push((sList.item(i) as HTMLElement).getAttribute('id'));
  }
  // get gap answers
  for (const gapId of gapIds) {
    const a = answers.find(o => o.includes(gapId));
    if (a) {
      const an = a.split('=')[1];
      answersText = answersText + (!answersText ? '' : ', ') + an;
    } else {
      answersText = answersText + (!answersText ? '' : ', ') + '_ ';
    }
  }
  const hasAnswer = !!answersText.split(',').join('').split('_').join('').trim().length;
  return hasAnswer ? answersText : null;
};

export const tooltipQuestionTypeCheckboxGapFilling = (question: EventQuestion, answers: any, languageParams: ILanguageParams): string => {
  // get gap order
  const span = document.createElement('span');
  span.innerHTML = question.getTaskTextByLanguage(languageParams);
  const sList = span.getElementsByTagName('input');
  const gapIds = [];
  let answersText = '';
  for (let i = 0; i < sList.length; i++) {
    gapIds.push((sList.item(i) as HTMLElement).getAttribute('id'));
  }
  // get gap answers
  for (const gapId of gapIds) {
    if (answers.includes(gapId)) {
      const aeq = question.correctEquality.find(o => o.id === gapId);
      if (aeq) {
        answersText = answersText + (!answersText ? '' : ', ') +
          question.items.find(a => a.id === aeq.answerId).getAnswerByLanguage(languageParams);
      }
    } else {
      answersText = answersText + (!answersText ? '' : ', ') + '_ ';
    }
  }
  const hasAnswer = !!answersText.split(',').join('').split('_').join('').trim().length;
  return hasAnswer ? answersText : null;
};

export const tooltipQuestionTypeScale = (question: EventQuestion, answers: any): string => {
  const smileRatings = SMILES_RATING_SETS[question.options?.smilesRatingSet];
  const answersItems = question.items
    .sort((a, b) => a.orderIndex >= b.orderIndex ? 1 : -1);
  let s = '';
  for (const answer of answersItems) {
    const smile = answers[0][answer.id];
    const rating = smileRatings.findIndex(sm => sm === smile);
    s += smile ? `<img style="height: 15px; width: 15px; margin-left: 2px;" src="assets/images/smiles/${smile}.svg"
                         alt="${rating > -1 ? rating : ''}">` :
      `<img style="height: 15px; width: 15px; margin-left: 2px;" src="assets/images/icons/tabler-circle-x.svg" alt="-">`;
  }
  return `<div style="height: 15px; display: flex; align-items: center; flex-wrap: wrap;">${s}</div>`;
};

export const tooltipQuestionTypeCheckChoice = (question: EventQuestion, answers: any, languageParams: ILanguageParams): string => {
  const awr = Object.values(answers);
  let answersText = '';
  const answerList = question.items;
  for (let i = 0; i < answerList.length; i++) {
    if (awr.indexOf(answerList[i].id) > -1) {
      answersText = answersText + (answersText.length === 0 ? '' : ', ') +
        answerList[i].getAnswerByLanguage(languageParams);
    }
  }
  return answersText;
};

export const tooltipQuestionTypeSequence = (question: EventQuestion, answers: any, languageParams: ILanguageParams): string => {
  const awr = Object.values(answers);
  let answersText = '';
  const answerList = cloneDeep(question.items).sort((a, b) => {
    const indexA = awr.indexOf(a.id);
    const indexB = awr.indexOf(b.id);
    if (indexA < 0 || indexB < 0) {
      return 0;
    }
    return indexA < indexB ? -1 : 1;
  });
  for (let i = 0; i < answerList.length; i++) {
    if (awr.indexOf(answerList[i].id) > -1) {
      answersText = answersText + (answersText.length === 0 ? '' : ', ') +
        answerList[i].getAnswerByLanguage(languageParams);
    }
  }
  return answersText;
};

export const tooltipQuestionTypePrioritization = (question: EventQuestion, answers: any, languageParams: ILanguageParams): string => {
  const awr = Object.values(answers);
  let answersText = '';
  const answerList = cloneDeep(question.items).sort((a, b) => {
    const indexA = awr.indexOf(a.id);
    const indexB = awr.indexOf(b.id);
    if (indexA < 0 || indexB < 0) {
      return 0;
    }
    return indexA < indexB ? -1 : 1;
  });
  for (let i = 0; i < answerList.length; i++) {
    if (awr.indexOf(answerList[i].id) > -1) {
      answersText = answersText + (answersText.length === 0 ? '' : ', ') +
        answerList[i].getAnswerByLanguage(languageParams);
    }
  }
  return answersText;
};
