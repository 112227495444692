import {Component, ElementRef, Injector} from '@angular/core';
import {AbstractQuizQuestionParticipantComponent} from '../../shared/participant/abstract-quiz-question-participant-component';
import {Store} from '@ngrx/store';
import * as fromRoot from '../../../../../../../reducers';
import * as ui from '../../../../../../../actions/ui';
import {AnswersQuestion} from '../../../../../../questionnaire/questionnaire-tab/questionnaire-tab.component';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
import {cloneDeep} from 'lodash';
import {TimeLineService} from '../../../../../../../services/time-line.service';
import {EventModeApiService} from '../../../../../../../services/event-mode-api.service';
import {auditTime, Subject} from 'rxjs';

@Component({
  selector: 'app-question-text-balloons-participant',
  templateUrl: './question-text-balloons-participant.component.html',
  styleUrl: './question-text-balloons-participant.component.scss'
})
export class QuestionTextBalloonsParticipantComponent extends AbstractQuizQuestionParticipantComponent {

  answerIsCorrect = false;
  userTyping$ = new Subject();

  answerForm: FormGroup;
  defaultColor = '#1677FF';

  constructor(protected injector: Injector,
    protected elementRef: ElementRef,
    private store: Store<fromRoot.State>,
    protected formBuilder: FormBuilder,
    private eventModeApi: EventModeApiService,
    private timelineService: TimeLineService
  ) {
    super(injector, elementRef);

    this.answerForm = this.formBuilder.group({
      answer: ['', Validators.compose([Validators.required, Validators.maxLength(40)])],
      color: [this.defaultColor, Validators.required]
    });

    this.userTyping$.pipe(this.takeUntilAlive(), auditTime(750)).subscribe(() => this.setTyping());
  }

  get answerControl(): AbstractControl {
    return this.answerForm.controls['answer'];
  }

  get emptyAnswer(): boolean {
    return !(this.answerForm.controls['answer'].value as string).trim().length
  }

  protected initQuestionAnswersDataSource() {
    this.answerIsCorrect = this.question.showCorrectAnswers &&
      this.question.items
        .some(it => it.getAnswerByLanguage(this.languageParams$.getValue()).toLowerCase() === this.answers?.a.toLowerCase());
  }

  protected onReceiveQuestionAnswers() {
    if (this.answers) {
      this.answerForm.patchValue({
        answer: this.answers?.a || '',
        color: this.answers?.c || this.defaultColor
      });
    }
  }

  getTextAnswerLength() {
    return this.answerControl.value.length;
  }

  editAnswer(edit) {
    // need for blocked global key events
    this.store.dispatch(new ui.SetUserInputAnswerOnTextQuestion(edit));
  }

  async setTyping() {
    await this.eventModeApi.setTypingQuestion(this.timelineService.event.eventId,
      this.question.id, this.timelineService.currentUser.userId, {
        name: this.timelineService.currentUser.fullName,
        picture: this.timelineService.currentUser.picture,
        userId: this.timelineService.currentUser.userId
      });
  }

  sendAnswer() {
    if (!this.acceptAnswers) {
      return;
    }

    const formModel = cloneDeep(this.answerForm.value);
    this.answers = { a: formModel.answer, c: formModel.color };
    this.answerChange$.next(new AnswersQuestion(this.qKey, this.answers, this.question.timelineId));
  }

  onDestroy() {
    this.store.dispatch(new ui.SetUserInputAnswerOnTextQuestion(false));
  }

}
