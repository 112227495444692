import {CdkDragDrop, CdkDragMove, CdkDragStart, DragRef, moveItemInArray} from '@angular/cdk/drag-drop';
import {Component, effect, ElementRef, HostListener, Injector, signal} from '@angular/core';
import {AbstractQuizQuestionParticipantComponent} from '../../shared/participant/abstract-quiz-question-participant-component';
import {IAnswer} from '../../../quiz-model/quiz';
import {isEmpty} from 'lodash';
import {AnswersQuestion} from '../../../../../../questionnaire/questionnaire-tab/questionnaire-tab.component';
import {Constants} from '../../../../../../../core/constants';
import {BehaviorSubject, debounceTime, filter} from 'rxjs';

interface IOrderMap {
  [id: string]: number;
}

@Component({
  selector: 'app-question-sequence-participant',
  templateUrl: './question-sequence-participant.component.html',
  styleUrl: './question-sequence-participant.component.scss'
})
export class QuestionSequenceParticipantComponent extends AbstractQuizQuestionParticipantComponent {

  dataSource: IAnswer[] = [];
  displayedColumns = ['sequenceNumber', 'answer'];
  correctOrder: IOrderMap = {};
  dataLoaded = false;
  private dragRef: DragRef;
  private dataLoading$ = new BehaviorSubject(null);
  private scale: number;
  private placeholderId: string;
  private placeholderPosition = signal(0);
  private placeholderPrevPosition = 0;
  private placeholderOffset = 0;

  @HostListener('window:keyup', ['$event'])
  keyUpEvent(event: KeyboardEvent) {
    if (event.code === Constants.ESCAPE) {
      if (this.dragRef) {
        this.dragRef.reset();
        this.dragRef = null;
        document.dispatchEvent(new Event('mouseup'));
        this.dragReset();
        const answers: string[] = this.dataSource.map(item => item.id);
        this.dataSource = this.sequenceSort(this.dataSource, answers);
      }
    }
  }

  constructor(protected injector: Injector,
              protected elementRef: ElementRef) {
    super(injector, elementRef);
    this.dataLoading$.pipe(filter(v => !!v && !this.dataLoaded), debounceTime(50), this.takeUntilAlive())
      .subscribe(() => this.dataLoaded = true);
    effect(() => {
      // calculate placeholder row number on drag&drop
      if (this.placeholderPosition() > this.placeholderPrevPosition) {
        this.placeholderOffset++;
      } else if (this.placeholderPosition() < this.placeholderPrevPosition) {
        this.placeholderOffset--;
      } else {
        this.placeholderOffset = 0;
      }
      this.placeholderPrevPosition = this.placeholderPosition();
    });
  }

  private compare(a: IAnswer, b: IAnswer, newOrder?: string[]) {
    const order = !isEmpty(newOrder) ? newOrder : (!isEmpty(this.answers) ? this.answers : []);
    const indexA = order.indexOf(a.id);
    const indexB = order.indexOf(b.id);
    if (indexA < 0 || indexB < 0) {
      return 0;
    }
    return indexA < indexB ? -1 : 1;
  }

  private sequenceSort(list: IAnswer[], newOrder?: string[]): IAnswer[] {
    return list
      .sort(() => Math.random() - 0.5)
      .sort((a: IAnswer, b: IAnswer) => this.compare(a, b, newOrder))
      .map(row => new Object(row) as IAnswer);
  }

  protected initQuestionAnswersDataSource() {
    this.correctOrder = this.question.items
      .map(answer => new Object({id: answer.id, orderIndex: answer.orderIndex}))
      .sort(this.common.utils.comparator(Constants.ORDERINDEX))
      .reduce((acc, it: any, index) => {
        acc[it.id] = index + 1;
        return acc;
      }, {}) as IOrderMap;
    this.dataSource = this.sequenceSort(
      this.question.items.map(answer => new Object({
        id: answer.id,
        answer: answer.getAnswerByLanguage(this.languageParams),
        orderIndex: answer.orderIndex
      }) as IAnswer));
    this.dataLoading$.next(true);
  }

  protected onReceiveQuestionAnswers() {
    this.dataSource = this.sequenceSort(this.dataSource);
    this.dataLoading$.next(true);
  }

  private dragReset() {
    this.placeholderPrevPosition = 0;
    this.placeholderPosition.set(0);
    const cellCollection: HTMLCollection = this.elementRef.nativeElement.getElementsByTagName('mat-cell');
    Array.from(cellCollection).forEach((el: HTMLElement) => el.style.transform = '');
  }

  drop(event: CdkDragDrop<string[]>) {
    if (!this.dragRef) {
      return;
    }
    this.dragRef = null;
    this.dragReset();
    if (event.previousIndex !== event.currentIndex) {
      moveItemInArray(this.dataSource, event.previousIndex, event.currentIndex);
      const answers: string[] = this.dataSource.map(item => item.id);
      this.dataSource = this.sequenceSort(this.dataSource, answers);
      this.answerChange$.next(new AnswersQuestion(this.qKey, answers, this.question.timelineId));
    }
  }

  onDragMove(event: CdkDragMove) {
    const placeholderElement = event.source.getPlaceholderElement() as HTMLElement;
    // ~~~~~~~ drag list correct gap between elements by scale ~~~~~~~
    /**
     * The lines may have different heights. Therefore, when dragging, random positions of the lines are possible.
     * To prevent this from happening, we adjust the positions of the lines manually.
     */
    let rowOrder = 0;
    for (const dragElem of event.source.dropContainer.getSortedItems()) {
      rowOrder++;
      if (dragElem.element.nativeElement.id === this.placeholderId) {
        // correct placeholder number cell position
        const transform = placeholderElement.style.transform.match(/\s-?[\d]+px,/);
        let transformValue = null;
        try {
          transformValue = Number.parseFloat(transform[0]);
        } catch (e) {
          transformValue = null;
        }
        if (transformValue !== null) {
          const tfv = transformValue / this.scale;
          this.placeholderPosition.set(tfv);
          const cellNumberElement = placeholderElement.parentElement.children.namedItem('cellNumber') as HTMLElement;
          cellNumberElement.style.transform = `translate3d(0, ${tfv}px, 0)`;
          cellNumberElement.innerHTML = (rowOrder + this.placeholderOffset).toString();
        }
      } else {
        // correct text cell and number cell positions
        const dragHTMLElem = dragElem.element.nativeElement as HTMLElement;
        const transform = dragHTMLElem.style.transform.match(/\s-?[\d]+px,/);
        let transformValue = null;
        try {
          transformValue = Number.parseFloat(transform[0]);
        } catch (e) {
          transformValue = null;
        }
        if (transformValue !== null) {
          const tfv = transformValue / this.scale;
          dragHTMLElem.style.transform = `translate3d(0, ${tfv}px, 0)`;
          const cellNumberElement = dragHTMLElem.parentElement.children.namedItem('cellNumber') as HTMLElement;
          cellNumberElement.style.transform = `translate3d(0, ${tfv}px, 0)`;
          cellNumberElement.innerHTML = (tfv === 0 ? rowOrder : (tfv > 0 ? rowOrder + 1 : rowOrder - 1)).toString();
        }
      }
    }
  }

  startDrag(event: CdkDragStart, row) {
    this.placeholderId = row.id;
    const main = document.getElementsByTagName('app-time-line-content-container-detail');
    this.scale = Number.parseFloat((main.item(0) as HTMLElement).style.getPropertyValue('--container-scale'));
    this.dragRef = event.source._dragRef;

    // ~~~~~~~ apply grid row styles and correct sizes by scale on drag-preview element ~~~~~~~
    const fontSize = this.elementRef.nativeElement.style.getPropertyValue('--answer-caption__font-size');
    const dpElement = document.getElementsByClassName('cdk-drag-preview').item(0) as HTMLElement;
    dpElement.style.fontSize = `calc(${fontSize} * ${this.scale})`;
    dpElement.style.borderRadius = `calc(4px * ${this.scale}) calc(16px * ${this.scale}) calc(16px * ${this.scale}) calc(4px * ${this.scale})`;
    dpElement.style.gridTemplateColumns = `1fr calc(75px * ${this.scale}) calc(75px * ${this.scale})`;
    const cellTextElement = this.elementRef.nativeElement.getElementsByClassName('cell-text').item(0);
    const cellTextStyle = getComputedStyle(cellTextElement);
    const dpCellTextElement = dpElement.getElementsByClassName('cell-text');
    Array.from(dpCellTextElement).forEach((elem: HTMLElement) => {
      elem.style.fontFamily = `${cellTextStyle.fontFamily}`;
    });
    const dpTiGripHorizontal = dpElement.getElementsByClassName('ti-grip-horizontal');
    Array.from(dpTiGripHorizontal).forEach((elem: HTMLElement) => {
      const style = getComputedStyle(elem);
      elem.style.fontSize = `calc(${style.fontSize} * ${this.scale})`;
    });
    const dpAnswerTextElement = dpElement.getElementsByClassName('answer-text');
    Array.from(dpAnswerTextElement).forEach((elem: HTMLElement) => {
      elem.style.paddingLeft = `calc(16px * ${this.scale})`;
      elem.style.paddingRight = `calc(16px * ${this.scale})`;
      elem.style.paddingTop = '0';
      elem.style.paddingBottom = '0';
    });
  }

}
